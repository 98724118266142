// @ts-nocheck

import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const CardPage = () => {
    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>AHL Card ile Finansın Özgür Haliyle Tanış!</h1><br />
                        <span> AHL Card ile harcamalarını etkili bir şekilde takip edebilir ve hesabına yüklediğin tutar kadar harcama gerçekleştirebilirsin. AHL Card’ı yurt içindeki alişverişlerinin yanı sıra internet alişverişlerinde de kullanabilirsin. Bu sayede bütçeni daha iyi yönetebilir ve harcamalarını her zaman kontrol altında tutabilirsin.
                        </span>
                        <div>
                            <grid grid="individual-products">
                                <div
                                    grid-item=""
                                    solution-open="demo1"
                                >
                                    <div>
                                        <h3>Her şey kontrolün altında</h3>
                                        <blockquote>AHL Card ile her şey elinizde ve kontrol sizde finans harcamalarınızı güvenle şekillendirebilirsin</blockquote>
                                    </div>
                                </div>
                                <div
                                    grid-item=""
                                    solution-open="demo1"
                                >
                                    <div>
                                        <h3>Dilediğin gibi harca bütçeni aşma</h3>
                                        <blockquote>Yurt içi ve online harcamalarını dile gibi harcayabilir bütçeni hiçbir zaman aşmazsın</blockquote>
                                    </div>
                                </div>
                                <div
                                    grid-item=""
                                    solution-open="demo1"
                                >
                                    <div>
                                        <h3>Oluştur ve Harcadıkça Cashback kazan</h3>
                                        <blockquote>AHL Card oluşturarak 200₺ nakit kazan, her yaptığın harcamada Cashback kazanma fırsatı yakala</blockquote>
                                    </div>
                                </div>
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src="../assets/uploads/products/icons/card.svg" alt="Bireysel Çözümlerimiz" />
                    </div>
                </div>
            </section>

            <section element="individual-poster">
                <div>
                    <article>
                        <h2>Senin AHL Card'ın <span>hangisi?</span></h2>
                        <blockquote>
                            TROY logolu AHL Card'lar tüm avantajlarıyla seni bekliyor. Uygulamaya gir, kullanıcı oluştur, AHL Card tercihini yap, hemen adresine gelsin.
                        </blockquote>
                    </article>
                    <picture>
                        <img src="assets/images/ahlcard-dark.png" alt="" />
                        <img src="assets/images/ahlcard-moonlight.png" alt="" />
                    </picture>

                    <b>Kampanya Detayı:</b><br></br>
                    <big><i>AHL Card’ınızı oluşturarak 200₺ nakit hediye fırsatını yakalayın! Uygulama üzerinden ilk defa AHL Card'ı aktif ettiğinizde, hesabına yatacak olan 200₺ nakit hediyeni anında kullanabilirsin.</i></big>
                    <small>(Dark ya da Moonlight farketmeksizin kampanya sadece ilk kart siparişinde geçerlidir.)</small>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>

        </>
        
    );
}

const App = () => {
  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the CardPage component below */}
      <CardPage />
      <Footer />
    </div>
  );
};

export default App;
