// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        id: 2,
        pageTitle: 'FizikiAltinGumus',
        headerTitle: 'Fiziki Altın/Gümüş',
        title: "Fiziki Altın ve Fiziki Gümüş Satışı İlk Kez AHL Pay'de !",
        shortDescription: "Bir ilke imza attık, adrese teslim fiziki altın ve gümüş satışlarına başladık AHL Pay kullanıcılarının mobil uygulama üzerinden satın aldıkları altın ve gümüş ürünler, siparişte belirtilen adrese sigortalı olarak teslim ediliyor. Fiziki altın ve gümüş ürünleri çok avantajlı fiyatlarla AHL Pay'de. Hemen AHL Pay uygulaması üzerinden siparişinizi oluşturun, teslimatınız sigortalı ve hızlı bir şekilde kapınıza gelsin.",
        description: "AHL Pay uygulaması üzerinden fiziki altın ve gümüş alımı yapabilirsin. AHL Pay ile anlık fiyatlarla altın ve gümüş alabilirsin. Aldığın fiziki altın ve gümüş adresine teslimatı ve güvenliği konusunda da gönül rahatlığıyla bilgi alabilir, ödemelerini kredi kartı veya hesap bakiyesinden işlemlerini gerçekleştirebilirsin. Bu avantaj ile, alışveriş deneyimini kolaylaştırarak  tercihine göre en uygun ödeme yöntemini seçebilirsin.",
        imagePath: 'assets/uploads/products/phones/fiziki_phone.svg',
        languageCode: 'tr',
        title2: <><span>Fiziki Altın ve Gümüş</span> Satışı</>,
        childrens: [
            {
                title: 'Adrese Teslim',
                desc: 'Fiziki Altın ve Gümüşlerin adresine teslim edilsin'
            },
            {
                title: 'Avantajlı Fiyat',
                desc: 'Avantajlı fiyatlar ile yatırım yap'
            },
            {
                title: 'Yatırımda Güvenli Liman',
                desc: 'Siparişin sana ulaşana kadar Ahlatcı Kuyumculuk sigortası altında'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
