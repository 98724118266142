// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'Yatirim',
        headerTitle: 'Yatırım',
        title: "Yatırımlarınız AHL Pay ile Çok Daha Hızlı",
        shortDescription: 'AHL Pay ile yatırım yapmak artık çok kolay! Yatırımlarınla finansal geleceğini güvence altına al.',
        description: " AHL Pay hesabın üzerinden yatırım yapabilir, finansal işlemleri daha pratik hale getirebilirsin. Fon alım-satım veya hisse senetlerine kolayca erişim ile, hem yeni başlayanlar hem de deneyimli yatırımcılar için kullanıcı dostu bir arayüz sunan AHL Pay ile yatırımların artık daha hızlı.",
        imagePath: 'assets/uploads/products/icons/yatirim.svg',
        languageCode: 'tr',
        title2: <> <span>Yatırımın</span> AHL Pay ile  Güvende </>,
        childrens: [
            {
                title: 'Akıllı Yatırım güvenli getiri',
                desc: 'Yapacağınız akıllı yatırım ile getirilerin güvenle tek tuşla yanında'
            },
            {
                title: 'Çeşitli ve Esnek Yatırım Araçları',
                desc: 'Geniş yatırım ürünleri portföyü'
            },
            {
                title: 'Kullanıcı Dostu Arayüz, İşlem Kolaylığı',
                desc: 'Hızlı ve verimli bir yatırım deneyimi'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
