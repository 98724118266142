// @ts-nocheck
import { useLanguage } from '../contexts/LanguageContext';
const PageJson = {
    Footer1: [
        {
            title: <div aria-label="info">
                AHL Pay, 6493 sayılı kanun çerçevesinde TCMB tarafından yetkilendirilmiş bir Elektronik Para ve Ödeme Hizmetleri Kuruluşudur.<br /><br />
                <a href="https://bkm.com.tr/" title="Bankalararası Kart Merkezi (BKM)">Bankalararası Kart Merkezi (BKM)</a>,<a href="https://www.deik.org.tr/" title="DEİK <"> DEİK </a> ve <a href="https://www.todeb.org.tr/" title="TÖDEB <">TÖDEB </a> üye kuruluşudur.
            </div>,
            languageCode: 'tr'
        },
        {
            title: <div aria-label="info">
                AHL Pay is an Electronic Money and Payment Services Institution authorized by the Central Bank of the Republic of Turkey (CBRT) under Law No. 6493.<br /><br />
                It is a member institution of the <a href="https://bkm.com.tr/" title="Interbank Card Center (BKM)">Interbank Card Center (BKM)</a>,<a href="https://www.deik.org.tr/" title="DEİK <"> DEİK </a> and <a href="https://www.todeb.org.tr/" title="TÖDEB">TÖDEB</a>.
            </div>
            ,
            languageCode: 'en'
        }
    ],
    Footer2: [
        {
            title: <ul>
                <li grouphead=""><a href="/" title="AHL Pay">AHL Pay</a></li>
                <li><a href="/basvuru" title="Başvuru">Başvuru</a></li>
                <li><a href="/hakkimizda" title="Hakkımızda">Hakkımızda</a></li>
                <li><a href="/logo" title="Logolarımız">Logolarımız</a></li>
                <li><a href="/ucret-ve-limitler" title="Ücret ve Limitler">Ücret ve Limitler</a></li>
                <li><a href="/kariyer" title="Kariyer">Kariyer</a></li>
                <li><a href="/kampanya-detay" title="Kampanyalar">Kampanyalar</a></li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li grouphead=""><a href="/" title="AHL Pay">AHL Pay</a></li>
                <li><a href="/basvuru" title="Apply">Apply</a></li>
                <li><a href="/about-us" title="About Us">About Us</a></li>
                <li><a href="/logo" title="Our Logos">Our Logos</a></li>
                <li><a href="/fees-and-limits" title="Fees and Limits">Fees and Limits</a></li>
                <li><a href="/career" title="Career">Career</a></li>
                <li><a href="/kampanya-detay" title="Campaigns">Campaigns</a></li>
            </ul>
            ,
            languageCode: 'en'
        }
    ],
    Footer3: [
        {
            title: <ul>
                <li grouphead=""><a href="" title="Yasal Metinler">Yasal Metinler</a></li>
                <li><a href="/yasal-bilgiler" title="Yasal-Bilgiler">Yasal Bilgiler</a></li>
                <li><a href="/sozlesmeler" title="Sözleşmeler">Sözleşmeler</a></li>
                <li><a href="/formlar" title="Formlar">Formlar</a></li>
                <li><a href="/iso" title="Sertifikalar">Sertifikalar</a></li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li grouphead=""><a href="" title="Legal Texts">Legal Texts</a></li>
                <li><a href="/legal-information" title="legal-information">Legal Information</a></li>
                <li><a href="/Agreements" title="Agreements">Agreements</a></li>
                <li><a href="/forms" title="Forms">Forms</a></li>
                <li><a href="/iso" title="Certificates">Certificates</a></li>
            </ul>,
            languageCode: 'en'
        }
    ],
    Footer4: [
        {
            title: <ul>
                <li grouphead=""><a href="#" title="Destek">Destek</a></li>
                <li><a href="/sss" title="Sıkça Sorulan Sorular">Sıkça Sorulan Sorular</a></li>
                <li><a href="/bize-ulasin" title="İletişim">İletişim</a></li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li grouphead=""><a href="#" title="Support">Support</a></li>
                <li><a href="/faq" title="Frequently Asked Questions">Frequently Asked Questions</a></li>
                <li><a href="/contact" title="Contact">Contact</a></li>
            </ul>
            ,
            languageCode: 'en'
        }
    ],
    Footer5: [
        {
            title: <div finner="copyright">
                © 2022 - 2024 <a href="#" title="AHL Pay">AHL Pay</a>, tüm hakları saklıdır.
            </div>,
            languageCode: 'tr'
        },
        {
            title: <div finner="copyright">
                © 2022 - 2024 <a href="#" title="AHL Pay">AHL Pay</a>, all rights reserved.
            </div>
            ,
            languageCode: 'en'
        }
    ],
}
const AppFooter = () => {
    const { language } = useLanguage();
    return <footer footer="">
        <div frole="inner">
            <div finner="info">
                <picture><img src="./assets/images/logo.png" alt="AHL Pay" /></picture>
                {PageJson.Footer1.find(x => x.languageCode == language)?.title}
                <div aria-label="apps">
                    <ul>
                        <li><a href="https://apps.apple.com/tr/app/ahl-pay/id6443716011" target='_blank' title="AHL Pay: IOS Uygulaması"><img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" /></a></li>
                        <li><a href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr" target='_blank' title="AHL Pay: Android Uygulaması"><img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" /></a></li>
                    </ul>
                </div>
            </div>
            <div finner="links">
                {PageJson.Footer2.find(x => x.languageCode == language)?.title}
                {PageJson.Footer3.find(x => x.languageCode == language)?.title}
                {PageJson.Footer4.find(x => x.languageCode == language)?.title}
            </div>
        </div>
        <div frole="copyright">
            {PageJson.Footer5.find(x => x.languageCode == language)?.title}
            <div finner="logos">
                <ul>
                    <li><a href="https://www.ahlatci.com.tr/" title="Ahlatcı Holding"><img src="assets/uploads/footer-logos/ahlatci.png" alt="Ahlatcı Holding" /></a></li>
                    <li><a href="https://www.tcmb.gov.tr/" title="TCMB"><img src="assets/uploads/footer-logos/tcmb.png" alt="TCMB" /></a></li>
                    <li><a href="https://todeb.org.tr/" title="TÖDEB"><img src="assets/uploads/footer-logos/todeb.png" alt="TÖDEB" /></a></li>
                    <li style={{
                        alignSelf: 'center'
                    }}><a href="https://www.deik.org.tr//" title="Deik"><img style={{ width: '48.75px' }} src="assets/uploads/footer-logos/deik.png" alt="Deik" /></a></li>
                    <li><a href="https://www.pcisecuritystandards.org/" title="PCI DSS"><img src="assets/uploads/footer-logos/pcidss.png" alt="PCI DSS" /></a></li>
                    <li><a href="https://www.iso.org/" title="ISO"><img src="assets/uploads/footer-logos/iso.png" alt="ISO" /></a></li>
                    <li><a href="https://bkm.com.tr/" title="BKM"><img src="assets/uploads/footer-logos/bkm.png" alt="BKM" /></a></li>
                    <li><a href="https://www.mastercard.com.tr/" title="MasterCard"><img src="assets/uploads/footer-logos/mastercard.png" alt="MasterCard" /></a></li>
                    <li><a href="https://www.visa.com.tr/" title="Visa"><img src="assets/uploads/footer-logos/visa.png" alt="Visa" /></a></li>
                    <li><a href="https://troyodeme.com/" title="Troy"><img src="assets/uploads/footer-logos/troy.png" alt="Troy" /></a></li>

                </ul>

            </div>
        </div>
    </footer>
};

export default AppFooter;