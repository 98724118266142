// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';
import './index.css';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = [
    {
        id: 1,
        title: 'Kullanıcı Çerçeve Sözleşmesi',
        data: <><h1>KULLANICI ÇERÇEVE SÖZLEŞMESİ</h1>
            <h2>1. TARAFLAR</h2>
            <article>
                <p>İşbu sözleşme İçerenköy Mahallesi Yeşilvadi Sokak No: 3 Kat: 8 Ataşehir/İstanbul adresinde yerleşik Ahlatcı Ödeme
                    ve Elektronik Para Hizmetleri Anonim Şirketi (bundan sonra “AHL Pay” olarak anılacaktır.) İşbu sözleşmeyi
                    uygulama üzerinden onaylayan kişi “Kullanıcı” olarak anılacaktır. AHL Pay ve kullanıcı arasında karşılıklı
                    olarak imzalanarak yürürlüğe girmiştir. AHL Pay ve kullanıcıdan her biri ayrı ayrı "Taraf" birlikte "Taraflar”
                    olarak anılacaktır</p>
                <h3>TARAF BİLGİLERİ:</h3>
                <p>AHL Pay bilgileri aşağıdaki gibidir;</p>
                <ul>
                    <li><strong>Ticaret Unvanı:</strong> Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi</li>
                    <li><strong>Adres:</strong> İçerenköy Mahallesi Yeşilvadi Sokak No: 3/8 Ataşehir İstanbul</li>
                    <li><strong>Telefon:</strong> <a href="tel:444-90-66">444 90 66</a></li>
                    <li><strong>MERSİS No:</strong> 0010139216100001</li>
                    <li><strong>E-Posta:</strong> bilgi@ahlpay.com.tr</li>
                    <li><strong>İnternet sitesi:</strong> <a href="http://www.ahlpay.com.tr">www.ahlpay.com.tr</a></li>
                </ul>
                <p>Kullanıcı bilgileri aşağıdaki gibidir;</p>
                <ul>
                    <li><strong>Ad-Soyad:</strong></li>
                    <li><strong>Adres:</strong></li>
                    <li><strong>Telefon:</strong></li>
                    <li><strong>E-Posta No:</strong></li>
                </ul>

                <h2>2. TANIMLAR</h2>
                <p><strong>AHL Pay:</strong> Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ’nin markasını</p>
                <p><strong>Alıcı:</strong> Ödeme işlemine konu fonun ulaşması istenen gerçek veya tüzel kişiyi</p>
                <p><strong>Doğrudan Borçlandırma Sistemi:</strong> Gönderenin kendi ödeme hizmeti sağlayıcısına alıcıya veya
                    alıcının ödeme hizmeti sağlayıcısına verdiği onaya dayanılarak ödeme işleminin alıcı tarafından başlatıldığı ve
                    gönderenin ödeme hesabının borçlandırıldığı ödeme hizmetini</p>
                <p><strong>Elektronik Para:</strong> Elektronik para ihraç eden kuruluş tarafından kabul edilen fon karşılığı ihraç
                    edilen elektronik olarak saklanan kanunda tanımlanan ödeme işlemlerini gerçekleştirmek için kullanılan ve
                    elektronik para ihraç eden kuruluş dışındaki gerçek ve tüzel kişiler tarafından da ödeme aracı olarak kabul
                    edilen parasal değeri</p>
                <p><strong>Anonim Müşteri:</strong> Uygulamaya kaydolmadan ve kimlik tespiti veya doğrulaması yapılmamış anonim kart
                    kullanan kullanıcıyı</p>
                <p><strong>Anonim Kart:</strong> Herhangi bir şekilde ödeme hesabına bağlı olmayan ve kimlik tespiti veya
                    doğrulaması yapılmamış önceden ödeme ya da yükleme yapılması suretiyle kullanılabilir hale gelen tekrar yükleme
                    yapılma imkânı bulunan veya bulunmayan şekilde ihraç edilebilen ve yüklenen bakiye kadar kullanıma izin verilen
                    ön ödemeli aracı</p>
                <p><strong>Kart:</strong> Önceden ödeme ya da yükleme yapılması suretiyle kullanılabilir hale gelen tekrar yükleme
                    yapılma imkânı bulunan veya bulunmayan şekilde ihraç edilebilen ve yüklenen bakiye kadar kullanıma izin verilen
                    ön ödemeli aracı</p>
                <p><strong>Hassas Ödeme Verisi:</strong> Ödeme emrinin verilmesinde veya müşterinin kimliğinin doğrulamasında
                    kullanılan ve üçüncü kişilerce ele geçirilmesi veya değiştirilmesi halinde dolandırıcılık ya da KULLANICI adına
                    sahte işlem yapılmasına imkân verebilecek kişisel veriler ile şifre güvenlik sorusu sertifika şifreleme anahtarı
                    ile PIN kart numarası son kullanma tarihi CVV2 CVC2 kodu gibi Ödeme Aracı’nı</p>
                <p><strong>Çağrı Merkezi:</strong> Kullanıcının destek/şikâyet talebini karşılayan 444 01 86 numaralı çağrı
                    merkezini</p>
                <p><strong>Onaylı Müşteri:</strong> Uygulamaya kayıtlı olup kimlik bilgileri merkezi nüfus idaresi sistemi (MERNİS)
                    ile doğrulanmış kullanıcıyı</p>
                <p><strong>Standart Müşteri:</strong> Uygulamaya kayıtlı olup kimlik bilgilerinin tamamı doğrulanmamış olan
                    kullanıcıyı</p>
                <p><strong>Ödeme Aracı:</strong> Ödeme hizmeti sağlayıcısı ile kullanıcısı arasında belirlenen ve ödeme hizmeti
                    kullanıcısı tarafından ödeme emrini vermek için kullanılan kart cep telefonu şifre ve benzeri kişiye özel aracı
                </p>
                <p><strong>Fon:</strong> Banknot madeni para kâğıt para veya elektronik parayı</p>
                <p><strong>Sözleşme:</strong> Ödeme Hizmeti Kullanıcısı ile Elektronik Para Kuruluşu arasında akdedilen Süreklilik
                    Arz Eden Periyodik Ödeme İlişkilerini Düzenleyen Çerçeve Sözleşmeyi</p>
                <p><strong>Gönderen:</strong> Kendi ödeme hesabından veya ödeme hesabı bulunmaksızın ödeme emri veren gerçek veya
                    tüzel kişiyi</p>
                <p><strong>Uzaktan İletişim Aracı:</strong> Mektup katalog telefon faks radyo televizyon elektronik posta mesajı
                    internet kısa mesaj hizmetleri gibi fiziksel olarak karşı karşıya gelinmeksizin sözleşme kurulmasına imkân veren
                    her türlü araç veya ortamı</p>
                <p><strong>İşlem Dekontu:</strong> Kullanıcılar tarafından gerçekleştirilen ödeme işlemlerine ilişkin olarak Ödeme
                    Sistemi üzerinden üretilen ve Kullanıcılara verilen dekontu</p>
                <p><strong>Kalıcı Veri Saklayıcısı:</strong> Ödeme hizmeti kullanıcısının kendisine gönderilen bilgiyi bu bilginin
                    amacına uygun olarak makul bir süre incelemesine elverecek şekilde kaydedilmesini ve değiştirilmeden
                    kopyalanmasını sağlayan ve bu bilgiye aynen ulaşılmasına imkân veren kısa mesaj elektronik posta internet CD DVD
                    hafıza kartı ve benzeri her türlü araç veya ortamı</p>
                <p><strong>Fatura Ödemesi:</strong> Elektrik telefon su doğalgaz gibi ihtiyaçların karşılanmasına yönelik sunulan
                    hizmetlerin karşılığı olarak yapılan ödemeler ile Kurulca uygun görülen diğer ödemeleri</p>
                <p><strong>Kanun:</strong> 20/6/2013 tarihli ve 6493 sayılı Ödeme ve Menkul Kıymet Mutabakat Sistemleri Ödeme
                    Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanunu</p>
                <p><strong>Yönetmelik:</strong> Ödeme Hizmetleri ve Elektronik Para İhracı ile ödeme Kuruluşları ve Elektronik Para
                    Kuruluşları Hakkında Yönetmeliğini</p>
                <p><strong>Tebliğ:</strong> Ödeme Kuruluşları ve Elektronik Para Kuruluşlarının Bilgi Sistemlerinin Yönetimine ve
                    Denetimine İlişkin Tebliğ’i</p>
                <p><strong>Kurum:</strong> Bankacılık Düzenleme ve Denetleme Kurumu’nu</p>
                <p><strong>MASAK Yönetmeliği:</strong> Mali suçları araştırma kurulunun çıkarmış olduğu yönetmelikleri</p>
                <p><strong>TCKN:</strong> Türkiye Cumhuriyeti Kimlik Numarasını</p>
                <p><strong>Kullanıcı:</strong> Ödeme hizmeti kullanıcısı ile elektronik para kullanıcısı olmak isteyen kişiyi</p>
                <p><strong>Ödeme Emri:</strong> Ödeme hizmeti kullanıcısı tarafından ödeme işleminin gerçekleştirilmesi amacıyla
                    ödeme hizmeti sağlayıcısına verilen talimatı</p>
                <p><strong>Ödeme Hesabı:</strong> Ödeme hizmeti kullanıcısı adına açılan ve ödeme işleminin yürütülmesinde
                    kullanılan hesabı</p>
                <p><strong>Ödeme Hizmeti:</strong> Sözleşmede belirlenen hüküm ve koşullar çerçevesinde kullanıcıya sunulan ve
                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ’nin bankadan faaliyet izni aldığı hizmetleri</p>
                <p><strong>Ödeme İşlemi:</strong> Gönderen veya alıcının talimatı üzerine gerçekleştirilen fon yatırma aktarma veya
                    çekme faaliyetini</p>
                <p><strong>Banka:</strong> Türkiye Cumhuriyeti Merkez Bankası’nı</p>
                <p><strong>Ödeme Sistemi:</strong> AHL Pay sunucularında barındırılan ve AHL Pay tarafından işletilen ödeme
                    hizmetleri sunmaya yarayan sistemi</p>
                <p><strong>AHL Pay Ürünleri:</strong> Cüzdan Hesap Ön Ödemeli Kart</p>
                <p><strong>Kimlik Tanımlayıcısı:</strong> Ödeme hizmeti sağlayıcısı tarafından kimliğinin belirlenmesi ve diğer
                    kullanıcılardan ayırt edilmesi amacıyla ödeme hizmeti kullanıcısına özgülenen sayı harf veya sembollerden oluşan
                    kombinasyonu</p>
                <p><strong>Ödeme Arayüzü:</strong> Kullanıcıların ödeme sistemine erişim yapabilmesini ödeme hizmetlerinin sunulması
                    ile alakalı talimatların verilmesini ödeme sistemine ilişkin ayarları yapabilmesini iletişim bilgilerini
                    değiştirebilmesini ve işbu sözleşme’ de belirlenen kapsamdaki diğer operasyonları gerçekleştirebilmesini
                    sağlayan ödeme sistemi ara yüzünü ifade etmektedir.</p>

                <h2>3. SÖZLEŞME ÖNCESİ BİLGİLENDİRME:</h2>
                <p>1) Kullanıcı AHL Pay ’in işbu sözleşme ilişkisi kurulmadan önce yönetmelik kapsamında bu sözleşmedeki şartlara
                    uygun olarak bilgilendirdiğini kabul eder.</p>
                <p>2) Kullanıcı AHL Pay ‘in Sözleşme’nin taraflar arasında yazılı olarak akdedildiği durumlarda sözleşmenin
                    kullanıcıya verilmesi ile; Sözleşme’nin uzaktan iletişim aracı vasıtasıyla akdedilmesi halinde ise AHL Pay ‘in
                    internet sitesinde yayınlanması ile beraber bilgilendirdiğini kabul eder.</p>
                <p>3) Kullanıcı işbu sözleşmenin bir örneğini sözleşmenin yapılmasını takiben ve talep etmesi halinde sözleşme
                    süresince kâğıt üzerinde veya ödeme sistemi ile AHL Pay tarafından verilecek ve erişimine hazır bulunduracaktır.
                </p>

                <h2>4. SÖZLEŞMENİN KONUSU:</h2>
                <p>Bu sözleşme ile Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. (“AHL Pay”) ve Kullanıcı arasında 6493 sayılı
                    Ödeme ve Menkul Kıymet Mutabakat Sistemleri Ödeme Hizmetleri ve Elektronik Para Kuruluşları Hakkında Kanun ve
                    sair ilgili yasal mevzuata uygun olarak süreklilik arz eden periyodik ödeme hizmetlerinin sunulmasına ilişkin
                    tarafların hak ve yükümlülüklerinin belirlenmesidir.</p>

                <h2>5. SÖZLEŞMENİN KAPSAMI:</h2>
                <p>Şirket gerek nihai kullanıcılara gerekse üye işyerlerine elektronik para yüklemiş oldukları hesaplarının (AHL Pay
                    hesapları) mal ve hizmet alımlarında kullanılabilmesi bu hesaplardan ödeme yapılabilmesi veya para çekilebilmesi
                    aynı zamanda kendi aralarında fon gönderme işleminin sağlanması ile ilgili 6493 sayılı Kanun kapsamında faaliyet
                    izni alınan konularda ödeme hizmetlerinin yerine getirilmesi hizmetlerini sunmaktadır.</p>

                <h2>6. TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ</h2>
                <p><strong>ÜCRET LİMİT</strong></p>
                <p>6.1. Kullanıcı uygulamayı kullanmaya bu şekilde ödeme hizmetlerinden faydalanmaya ve dolayısıyla sözleşmeyi
                    onaylamaya ehil olduğunu ve işbu sözleşmenin onay tarihinde 18 (on sekiz) yaşını doldurmuş olduğunu doldurmuş
                    olduğunu ve sözleşmeyi akdetmek adına doğru ve güncel bilgileri paylaştığını beyan eder.</p>
                <p>Müşterinin ergin olmadığı hallerde işbu sözleşme uyarınca AHL Pay ödeme aracının ilk ihracı ile elektronik para
                    hesaplarının açılması öncesinde 8 (sekiz) yaşını doldurması koşulu ile müşterinin yasal temsilcisinden onay
                    alınacaktır.</p>
                <p>Kullanıcının yasal temsilcisinden onay alınmaması halinde işbu sözleşme kesin hükümsüz olarak sonuçlanacaktır.
                    Taraflar sözleşmenin geçersizliği sebebiyle doğan/doğacak zararlardan AHL Pay’ e herhangi bir sorumluluk
                    yöneltilmeyeceği hususunda mutabıktırlar.</p>
                <p>6.2. Kullanıcı uygulamayı ilgili mevzuat uyarınca ve bunların sınırları dahilinde kullanacağını kendisinden talep
                    edilen bilgi ve belgeleri derhal sağlayacağını ve aksi takdirde ödeme hizmetlerinden faydalanamayacağını kabul
                    ve taahhüt eder.</p>
                <p>6.3. Kullanıcı Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne sağladığı tüm bilgi ve belgelerin
                    doğru güncel ve tam olduğunu taahhüt eder. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi KVKK’ ya
                    uygun olarak kullanıcının sağladığı bilgilerin doğruluğunu ve güncelliğini temin etmek üzere çeşitli doğrulama
                    tedbirleri alabilir. Kullanıcı verdiği bilgilerin yanlışlığından veya açık anlaşılır olmamasından süreli
                    işlemlerde işlemin yapılması gereken süreyi bildirmemesinden veya faturanın hatalı düzenlenmesinden kaynaklanan
                    her türlü zararın ve sorumluluğunun kendisine ait olacağını kabul ve beyan eder.</p>
                <p>6.4. Kullanıcı kendi adına ve kendi hesabına hareket etmekte olduğunu başkası hesabına hareket etmesi ve/veya AHL
                    Pay hesabını üçüncü kişiye devretmesi ve/veya kimlik tespitine konu bilgilerinin değişmesi halinde bu durumu
                    5549 Sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun dahil ilgili mevzuata uygun olarak Ahlatcı
                    Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne bildireceğini ve bu kapsamda Ahlatcı Ödeme ve Elektronik
                    Para Hizmetleri Anonim Şirketi tarafından talep edilen işlemleri gerçekleştireceğini kabul ve beyan eder.</p>
                <p>İşbu maddeye aykırılık halinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi herhangi bir hatalı
                    yetkisiz ve hukuka aykırı işlemden sorumlu olmayacak ve işbu sözleşmeyi tazminat ödemeksizin feshedebilecek veya
                    askıya alabilecektir.</p>
                <p>6.5. Ödeme talimatı dahil olmak üzere kullanıcı talimatları kullanıcının uygulamaya kullanıcı adı ve şifresi ile
                    girerek veya kart ile şifre bilgilerini kullanarak işlem talimatı vermesi halinde Ahlatcı Ödeme ve Elektronik
                    Para Hizmetleri Anonim Şirketi nezdinde geçerli olacaktır. Kullanıcı ödeme işleminin başlatılması veya
                    gerçekleştirilebilmesi için sunulması gereken bilgilere AHL Pay hesabına erişimi esnasında ve erişim sonrasında
                    işlemin gerçekleştirilmesi anında ulaşabilmekte olup Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi’nin talep ettiği bu bilgiler kullanıcının kendisi tarafından girilecektir. Ahlatcı Ödeme ve Elektronik
                    Para Hizmetleri Anonim Şirketi aracılığıyla gerçekleştirilen ödeme hizmetleriyle ilgili işlemlerin asgari ve
                    azami limit ve işlem ücretlerini belirleme hakkı Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ ne
                    aittir. Kullanıcı ödeme işlem limitlerinin üzerinde işlem gerçekleştirilmemesi gerektiğini kabul ve beyan eder.
                    Anonim standart veya onaylı müşterinin ödeme işlemi limitinin yükseltilmesi için Ahlatcı Ödeme ve Elektronik
                    Para Hizmetleri Anonim Şirketi tarafından talep edilecek bilgileri ve mevzuatın gerektirdiği hallerde ilgili
                    belgeleri (ıslak imzalı olarak) Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ‘ne temin etmesi
                    gerekir. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi burada talep edilen bilgiler ile belgeler
                    temin edilse dahi müşterinin statüsünü herhangi bir sebeple yükseltmeme hakkını saklı tutar. Kullanıcı
                    kendisinden talep edilen bilgi ve belgeleri ibraz etse dahi Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi kullanıcının Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi tarafından sunulan ödeme
                    hizmetlerini kullanma talebini reddedebilecek ve/veya Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi
                    tarafından sunulmakta olan hizmeti sonlandırabilecektir. İşlem ücret ve işlem limitlerine ilişkin detaylı bilgi
                    <a href="https://ahlpay.com.tr">https://ahlpay.com.tr</a> ve mobil uygulamada mevcuttur. Ahlatcı Ödeme ve
                    Elektronik Para Hizmetleri A.Ş. tarafından belirlenen ücret ve limitlerin takibi hususunda sorumluluk
                    kullanıcıya aittir.
                </p>
                <p>6.6. Kullanıcı gerek uygulama nezdinde oluşturulacak kullanıcı hesabına gerekse hesabın ilintili olduğu ödeme
                    aracına ve hassas müşteri verisine üçüncü kişilerin erişmesine izin ve imkân vermeyecek bunların güvenliğini
                    sağlamaktan bizzat sorumlu olacaktır. Kullanıcı ayrıca uygulamada yer alan kullanıcı adı ve şifresi gibi
                    bilgiler dahil ancak bunlarla sınırlı olmaksızın ödeme aracına ve hassas müşteri verisine ilişkin bilgileri
                    hiçbir şekilde üçüncü kişilere açıklamayacağını kullandırmayacağını ve bu bilgileri tahsis amacı dışında
                    kullanmayacağını kabul eder.</p>
                <p>6.7. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi tarafından AHL Pay Ürünleri kapsamında sunulan
                    tüm ödeme araçlarının ve hesaplarının ve/veya hassas müşteri verisi dahil olmak üzere kullanıcı bilgileri ile
                    ödeme aracının kaybolması çalınması veya bunlara Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’nin
                    kusuru olmaksızın yetkisiz erişilmesi halinde kullanıcı Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi’ne derhal bilgilendirmekle yükümlü olduğunu ve Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi’nin bu doğrultuda uygulamanın veya ilgili kartların kullanıma kapatılması dahil gerekli tedbirleri
                    alabileceğini kabul ve beyan eder.</p>
                <p>6.8. Kullanıcı tarafından belirtilen kimlik tanımlayıcının ve/veya hassas ödeme verisinin hatalı olması halinde
                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ödeme işleminin gerçekleştirilememesinden sorumlu
                    tutulamaz.</p>
                <p>Hatalı ödeme işlemine konu olan fonların geri alınması için Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi‘nin katlandığı maliyetlerle orantılı makul bir ücreti kullanıcıdan talep etme hakkı saklıdır.</p>
                <p>6.9. Ödeme aracının kaybolması çalınması veya iradesi dışında gerçekleşmiş herhangi bir işlemi öğrenmesi halinde
                    kullanıcı durumu derhal Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne bildirir. Kullanıcı ödeme
                    aracını teslim aldığı veya ödeme aracı üzerinde kullanım ve tasarruf imkanına sahip olduğu andan itibaren
                    gerekli güvenlik tedbirlerini almakla yükümlüdür. İşbu madde kapsamındaki bir bildirim neticesinde gerekmesi
                    durumunda Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ödeme aracı ile doğrudan
                    ilişkilendirilebilen yenileme ücreti talep edebilecektir.</p>
                <p>6.10. Hatalı veya yetkisiz işleme dair bildirim yükümlülüğü kullanıcıya ait olup bu çerçevede kullanıcı
                    tarafından Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ‘ne düzeltme talebinin iletilmesi
                    gerekmektedir. Düzeltme talebi her halükârda ödeme işleminin gerçekleştirilmesinden itibaren 13 (on üç) ayı
                    aşamaz. Talebe konu ödeme işleminin yetkilendirilmeden veya hatalı gerçekleştirildiğinin Ahlatcı Ödeme ve
                    Elektronik Para Hizmetleri Anonim Şirketi tarafından tespit edilmesi halinde işleme konu tutarın kullanılmamış
                    olması şartıyla söz konusu işleme ilişkin düzeltme talebi Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim
                    Şirketi tarafından mümkün olan en kısa süre içerisinde hatalı tutar kullanıcıya iade edilmek veya cüzdan eski
                    haline getirilmek suretiyle gerçekleştirilir. Bildirimin yapıldığı tarih itibariyle ilgili işlem bedeli alıcı
                    veya alıcının ödeme hizmeti sağlayıcısına aktarılmış ise ilgili tutarın iadesi talep edilir ve iadesi talep
                    edilen işlem bedeli eğer alıcı tarafından kullanılmamışsa cüzdana bu tutar (ya da kalan bakiye) aktarılır.
                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’nin iade sürecinde karşılaşabileceği zararlara
                    ilişkin tutarlar doğrudan geri alınan tutardan mahsup edilebilecektir. AHL Pay ürünleri ile yapılan işlemlere
                    ilişkin itirazlar uluslararası kartlı sistem kuruluşlarının kuralları ve süreleri doğrultusunda işleme alınır ve
                    sonuçlandırılır. AHL Pay ürünleri kullanılarak yapılan işlemlere işlemin gerçekleştiği günden itibaren 20
                    (yirmi) iş günü içinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne başvurmak suretiyle itiraz
                    edilebilir. Kullanıcı; yapacağı başvuruda işlem ile ilgili itirazının detayını gerekçesiyle birlikte belirtmek
                    zorundadır. Belirtilen süre içinde itiraz edilmeyen işlemler kabul edilmiş sayılır.</p>
                <p>6.11. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi hatalı veya yetkisiz işlem ile ilgili olarak
                    ödeme aracının hileli kullanılması veya ödeme aracı ile ilgili yükümlülüklerin kasten veya ağır ihmalle yerine
                    getirilmemesi hallerine dair kuvvetli şüphe bulunması durumunda işleme ilişkin düzeltme yapmadan önce makul bir
                    süreyle sınırlı olmak üzere araştırma yapma hakkına sahiptir.</p>
                <p>6.12. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi hatalı veya yetkisiz işlem ile ilgili olarak
                    ödeme aracının hileli kullanılması veya ödeme aracı ile ilgili yükümlülüklerin kasten veya ağır ihmalle yerine
                    getirilmemesi hallerine dair kuvvetli şüphe bulunması durumunda işleme ilişkin düzeltme yapmadan önce makul bir
                    süreyle sınırlı olmak üzere araştırma yapma hakkına sahiptir.</p>
                <p>6.13. Kullanıcının ödeme aracını hileli kullanması veya Yönetmelik’in 53’üncü maddesine paralel olarak ödeme
                    aracına ilişkin yükümlülüklerini kasten ya da ağır ihmalle yerine getirmemesi hallerinde kullanıcı herhangi bir
                    süre ve ücret ile sınırlı olmaksızın yetkilendirilmemiş ödeme işleminden doğan zararların tamamından sorumludur.
                </p>
                <p>6.14. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ilgili ödeme işlemi nezdinde yetkisiz işlemin
                    varlığına dair haklı bir kanaati oluşması halinde kullanıcının yetkilendirmelerini inceleyerek hileli işlem
                    şüphesi halinde ödemeyi kabul etmeyebilecektir. Kullanıcının bu durumda herhangi bir talep hakkı söz konusu
                    olmayacaktır.</p>
                <p>6.15. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi uygulamanın ve ödeme hizmetlerinin mevzuata
                    aykırı usulsüz hileli veya yetkisiz kullanıldığına dair bir şüphe bulunması halinde ilgili kullanıcının hesabı
                    geçici ya da süresiz olarak kapatabilecektir. İşbu madde kapsamında geçici veya süresiz bir kapatmanın söz
                    konusu olması halinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ilgili mevzuatta bilgi
                    verilmesini önleyen bir hükmün bulunması veya güvenliği tehdit edici nedenlerin varlığı halinde kullanıcıyı
                    bilgilendirmeyecek ve ödeme hizmetleri ile uygulamayı yeniden kullanıma açmayacaktır.</p>
                <p>6.16. Kullanıcılar uygulama internet şubesi çağrı merkezi e-mail destek hattı chat ve sair kanallar üzerinden
                    gerçekleştireceği işlem ve iletişimler dahil her türlü faaliyetlerinde hassas müşteri verisi başta olmak üzere
                    AHL Pay ürünlerinin ve ödeme araçlarının güvenliğini tehlikeye atabilecek hiçbir bilginin paylaşılmamasından
                    sorumludur. Kullanıcılar Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ile olan iletişimlerinde ve
                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne ait ürünlerin kullanımında işbu sözleşme
                    hükümlerine ilgili kanunlara ahlaka adaba ve dürüstlük ilkelerine uygun kullanacağını bu kapsamda hukuka aykırı
                    küfür ve hakaret dahil genel ahlaka aykırı politik görüş yansıtan müstehcen ırkçı ayrımcı kamu düzenini
                    bozabilecek ya da tehditkâr unsurlar içeren görüşmeler gerçekleştirmemeyi taahhüt eder. Söz konusu taahhüdün
                    yerine getirilmemesi halinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi AHL Pay hesabını süresiz
                    olarak kapatabilecektir.</p>
                <p>6.17. Chat yalnızca kullanıcılar arasında iletişime imkân vermekte olup talep ve işlemler için uygulama
                    içerisindeki ilgili fonksiyonların kullanılması gerekmektedir.</p>
                <p>6.18. Kullanıcı uygulama ve/veya Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’nin
                    belirlediği/belirleyeceği kanallar aracılığı ile elektronik para ihracını talep eder ve Ahlatcı Ödeme ve
                    Elektronik Para Hizmetleri Anonim Şirketi tarafından talebe konu fon miktarında elektronik para ihraç edilir
                    ilgili fon tutarına ilişkin dekont kullanıcıya iletilir ve kullanıcı tarafından cüzdan üzerinden kullanılabilir
                    hale getirilir.</p>
                <p>6.19. Elektronik para ihracı için alınan fonlar alındıkları para cinsinden açılan koruma hesaplarında tutulmakla
                    beraber Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ilgili yabancı para cinsinden hesap
                    açılmasının mümkün olmaması veya makul olmayan bir maliyet gerektirmesi durumunda elektronik para ihracı için
                    alınan işbu fonları başka bir para cinsine çevrilerek koruma hesabında tutulabilir.</p>
                <p>6.20. Kullanıcı elektronik para üzerindeki yasal hak sahipliğine ilişkin herhangi bir değişiklik olması halinde
                    kimlik tespiti ve sair süreçlerin işletileceğini bu çerçevede gerekli bilgi ve belgeleri derhal sağlaması
                    gerekeceğini kabul eder.</p>
                <p>6.21. Kullanıcının cüzdanına gönderim yapılan elektronik paranın fona çevrilmesi talebinin uygulama üzerinden
                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’ne ulaşma anından itibaren 1 (bir) iş günü içerisinde
                    ilgili tutar Türk Lirası cinsinden fona çevrilir. Fon’a çevirme işlemi için kullanıcıdan iadenin
                    gerçekleştirileceği hesap/kart hakkında bilgi talep edilebilir.</p>
                <p>İlgili fonun kredi kartı ile ödendiği durumlarda Yönetmelik’in 6’ncı maddesi uyarınca geri ödemeler yalnızca aynı
                    kredi kartı hesabına olacak şekilde yapılacaktır.</p>
                <p>6.22. Kullanıcıların ödeme hizmetlerini gerçekleştirmesinde kullandıkları para birimlerine ilişkin Bakanlar
                    Kurulu'nun 07.08.1989 tarih ve 89/14392 sayılı kararı ile yürürlüğe konulan Türk Parası Kıymetini Koruma
                    Hakkında 32 Sayılı Karar’da belirlenen düzenlemeler esastır. Kartın yurt dışında kullanımı ile doğacak olan borç
                    ve alacak kayıtları ilgili uluslararası kartlı sistem kuruluşu aracılığı ile Türk Lirası’na çevrilerek AHL Pay’
                    e bildirilir. AHL Pay uluslararası kartlı sistem kuruluşu tarafından bildirilen bu tutarı müşteri hesabına
                    yansıtır. AHL Pay bu tutara masraf ve komisyon ekleyebilir. Kartın yurt dışında kullanımı ile doğacak olan borç
                    ve alacak kayıtlarının ilgili uluslararası kartlı sistem kuruluşu aracılığı ile AHL Pay ‘e döviz cinsinden
                    bildirildiği durumlarda ise AHL Pay’ in tabi olduğu kanun ve Kanun’un alt düzenlemelerinde bulunan hükümlere
                    uygun olarak TCMB döviz kuruna ilave komisyon ve masrafların eklenmesiyle hesaplandıktan sonra kullanıcı
                    hesabına yansıtılır veya bu tür harcamalar için serbest piyasada geçerli olan aylık ortalama kur üzerinden Türk
                    Lirası’na çevrilmesinin ardından ilgili komisyon ve masraflarıyla birlikte hesaba yansıtılır. Yukarıda yer
                    verilen referans döviz kurlarındaki değişiklikler derhal geçerli olacaktır.</p>
                <p>6.23. Kullanıcı ödeme işlemine ilişkin ödeme emrini ve buna ilişkin onayını uygulama ve/veya Ahlatcı Ödeme ve
                    Elektronik Para Hizmetleri Anonim Şirketi’nin belirlediği/belirleyeceği kanallar üzerinden verebilir. Söz konusu
                    ödeme emri ve ödeme emrine ilişkin onay ödeme işleminin gerçekleşmesinden önce veya sonra verilebilir. Ödeme
                    işlemi kullanıcı tarafından ödeme işleminin gerçekleştirilmesi için kart şifresinin girilmesi veya kullanıcıya
                    gönderilen doğrulama kodunun ilgili alana girişinin yapılması ya da uygulama üzerinden onay verilmesi veyahut
                    benzeri yöntemlerle onay verilmesi ile yetkilendirilmiş kabul edilecektir.</p>
                <p>6.24. Ödeme emrinin alındığı an ödeme emrinin Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ‘ne
                    ulaştığı an olarak kabul edilmektedir. Ödeme işleminin belirli bir günde belirli bir dönemin sonunda veya
                    gönderenin ödemeye ilişkin fonları ödeme hizmeti sağlayıcısının tasarrufuna bıraktığı günde gerçekleştirilmesine
                    karar verilmesi halinde ise ödeme işlemi için kararlaştırılan gün talimatın alınma tarihi kabul edilir.
                    Kullanıcı ödeme emrinin saat 23:59’a kadar iletilebileceğini daha geç vakitte gelen ödeme emrinin ertesi iş günü
                    alınmış sayılacağını kabul ve beyan eder.</p>
                <p>Söz konusu işlemlerde ödeme emri alıcısının farklı bir saat sınırlaması olması durumunda ödeme emri alıcısı
                    tarafından belirlenen saat esas alınacak olup böyle bir durumda kullanıcı işlem esnasında bilgilendirilecektir.
                    Ödeme emrinin gönderenin ödeme hizmeti sağlayıcısına iş günü dışında ulaşması durumunda ödeme emri izleyen ilk
                    iş günü alınmış sayılır. Kullanıcının ödeme işleminin gerçekleştirilmesine kadar işlemi geri alması mümkündür.
                </p>
                <p>Ödeme işleminin alıcı tarafından alıcı aracılığıyla veya ödeme emri başlatma hizmeti sağlayıcısı tarafından
                    başlatıldığı durumlarda ve kullanıcının gönderen olması halinde kullanıcı ödeme emrini Ahlatcı Ödeme ve
                    Elektronik Para Hizmetleri Anonim Şirketi’ne ilettikten veya ödeme işleminin gerçekleştirilmesi için Ahlatcı
                    Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi’nin belirlenmiş yönteme uygun olarak onay verdikten sonra
                    ödeme emrini geri alamaz. Kullanıcının işbu maddede belirtilen süre geçtikten sonra işlemi geri alabilmesi ancak
                    alıcının onayı bulunması şartıyla mümkündür.</p>
                <p>6.25. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi kullanıcıya ait bir ödeme emrini yerine
                    getirmeyi reddedebilir. Bu durumda ret sebebi belirtilir ve söz konusu olması halinde ilgili hataların ne
                    şekilde düzeltilebileceği her halükârda en geç ödeme emrinin alınmasını izleyen iş günü sonuna kadar kullanıcıya
                    bildirilir. Reddin haklı bir sebebe dayanması halinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi
                    kullanıcıya yapacağı bildirim için ücret talep edebilecektir.</p>
                <p>6.26. Kullanıcının ek bilgi daha sık bilgilendirmesini talep etmesi halinde Ahlatcı Ödeme ve Elektronik Para
                    Hizmetleri Anonim Şirketi’nin kullanıcıdan bu işlemin maliyeti kadar ek ücret masraf veya komisyon talep etme
                    hakkı saklıdır. İlgili diğer mevzuat gereği belirli bir şekilde yapılması zorunlu olan bildirim ve işlemlerden
                    katlanılan maliyetler kadar ücret alınabilir.</p>
                <p>6.27. İlgili ödeme işleminin gerçekleştirildiği tarihten itibaren 2 (iki) ay içinde yapılmak şartıyla kullanıcı
                    alıcı tarafından yetkilendirilmiş veya alıcı aracılığıyla başlatılan ödeme işleminde yetkilendirme sırasında
                    ödeme işlemine ilişkin tutarın tam olarak belirtilmemesi ve gerçekleşen ödeme işlemi tutarının harcama geçmişi
                    sözleşme şartları ve ilgili diğer hususlar dikkate alınarak öngörülen tutarı aşması halinde ilgili mal veya
                    hizmetin tüketilmemiş olması kaydı ile ödeme işlemi tutarı kadar geri ödeme yapılmasını talep edebilir. Ahlatcı
                    Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi bu durumda kullanıcıdan talebine ilişkin gerçeğe dayalı
                    kanıtlar sunmasını talep edebilir. Ancak ödeme işlemine ilişkin onayın doğrudan Ahlatcı Ödeme ve Elektronik Para
                    Hizmetleri Anonim Şirketi ‘ne verilmesi veya ödemenin gerçekleştirilmesi gereken tarihten en az 1 (bir) ay önce
                    kullanıcıya bildirimde bulunulması hallerinde kullanıcı ilgili ödeme işlemi için herhangi bir geri ödeme talep
                    edemez. İşbu madde uyarınca geri ödeme talebinde bulunulabilen hallerde Ahlatcı Ödeme ve Elektronik Para
                    Hizmetleri Anonim Şirketi 10 (on) iş günü içerisinde ödemeyi yapacak veya ret kararını gerekçeleri ile birlikte
                    kullanıcının başvurabileceği hukuki yolları da göstererek kullanıcıya bildirecektir.</p>
                <p>6.28. Kullanıcının gönderen olarak hareket ettiği ve işbu sözleşme kapsamında tekil ödeme işlemi başlatması
                    durumunda Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi ödeme işlemine ilişkin olarak azami
                    tamamlanma süresi ile ödenmesi gereken toplam ücret ve ücretlerin dökümüne ilişkin bilgileri kullanıcıya sağlar.
                    Tekil ödeme işlemlerinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi kullanıcının gönderen olarak
                    hareket ettiği durumda ödeme emri alındıktan hemen sonra;</p>
                <ul>
                    <li>1) Kullanıcının ödeme hesabının borçlandırıldığı para birimi veya ödeme emrinde belirtilen para birimi
                        cinsinden ödeme işlemi tutarı</li>
                    <li>2) Ödeme işlemi için kullanıcı tarafından ödenecek toplam ücret ve ücretlerin dökümü</li>
                    <li>3) Varsa ödeme işleminde uygulanan döviz kuru bilgisi ve bu döviz kuru kullanılarak hesaplanan ödeme
                        işleminin tutarı</li>
                    <li>4) Ödeme işlemine özgü referans bilgisini ve alıcıya ilişkin bilgiyi</li>
                    <li>5) Ödeme emrinin alındığı veya kullanıcının hesabının borçlandırıldığı tarihe ilişkin bilgiyi;</li>
                </ul>
                <p>Kullanıcının alıcı olarak hareket ettiği durumda ise ödeme işleminin gerçekleştirilmesinden sonra;</p>
                <ul>
                    <li>Ödeme işleminin gerçekleştirilebilmesi için kullanıcı tarafından sunulması gereken bilgiyi veya bu bilgilere
                        ulaşılmasını sağlayan şifreyi</li>
                    <li>1) Ödeme işleminin kullanıcının ödeme hesabının alacaklandırıldığı para birimi cinsinden tutarını</li>
                    <li>2) Ödenmesi gereken toplam ücret ve komisyonların dökümünü</li>
                    <li>3) Ödeme işlemine uygulanan döviz kuru veya referans döviz kurunu</li>
                    <li>4) Ödeme tutarlarının kullanıcının kullanımına sunulduğu tarihe ilişkin bilgiyi</li>
                </ul>
                <p>Kullanıcıya gecikmeksizin sağlayacaktır. Kullanıcının talep etmesi halinde bu bilgilendirmeler bilgilerin
                    değiştirilmeden saklanmasına ve kullanılmasına imkân verecek şekilde herhangi bir ücrete tabi olmaksızın Ahlatcı
                    Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi tarafından belirlenecek yöntemler ile ve ayda en az bir defa
                    olacak şekilde kararlaştırılacak düzenli aralıklarla yapılabilecektir.</p>
                <p>6.29. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi işbu sözleşmeyi tek taraflı olarak
                    değiştirebilir. İlgili değişiklik veya mevzuat ile belirlenen/belirlenecek yükümlülükler kapsamında uygulamada
                    ve ödeme hizmetlerinde yapılacak değişiklikler yürürlüğe girmeden 30 (otuz) gün önce değişikliğin kapsamı
                    yürürlük tarihi ve kullanıcının sözleşmeyi fesih hakkına ilişkin bilgilerin yer aldığı bir bildirimde
                    bulunacaktır. İşbu 30 (otuz) günlük süre içinde fesih bildiriminde bulunulmaması halinde kullanıcının ilgili
                    değişikliği kabul ettiği varsayılacaktır. Bu durumda kullanıcı Ahlatcı Ödeme ve Elektronik Para Hizmetleri
                    Anonim şirketinden herhangi bir talep hakkı bulunmayacağını kabul eder. Mevzuat kapsamında gereken herhangi bir
                    değişiklik için ilgili mevzuatta 30 (otuz) günden daha kısa süre verilmesi halinde bu husus da kullanıcıya
                    iletilecek bildirimde belirtilecek ve işbu maddede belirtilen süre yerine mevzuatın öngördüğü süre
                    uygulanacaktır.</p>
                <p>6.30. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi alıcının bankası veya ödeme hizmeti
                    sağlayıcısının işlemlerinden hiçbir şekilde sorumlu değildir.</p>

                <h2>7. PARA BİRİMİ:</h2>
                <p>Kullanıcılar için açılacak AHL Pay Hesaplarında TRY döviz cinsleri ve kıymetli maden cinsleri ile işlem
                    yapılabilmesi mümkündür.</p>

                <h2>8. SUNULMASI GEREKEN BİLGİLER:</h2>
                <p>Kullanıcılar tarafından oluşturulan AHL Pay ödeme hesabı aracılığı ile alışverişlerde kurumsal internet sitesini
                    ziyaret ederler telefon numarası ve şifre ile birlikte SMS doğrulama yaparak hesaplarına giriş yaptıktan sonra
                    buradaki kendilerine ait gerekli bilgilerini girmek suretiyle ödeme yapabilirler. Ödeme işlemleri Kullanıcı adı
                    şifre bilgileri ve diğer doğrulama yöntemleri ile gerçekleştirecektir.</p>
                <p>Kullanıcılar tarafından belirtilen kimlik tanımlayıcıya uygun şekilde yapılan ödeme işlemleri doğru
                    gerçekleştirilmiş sayılır. Kullanıcı tarafından kimlik tanımlayıcıya ilave bilgiler verilmiş olsa dahi Bireysel
                    Ödeme Hesapları ödeme işleminin sadece kimlik tanımlayıcı ile gerçekleştirilmesinden sorumludur.</p>
                <p>AHL Pay hesap açılışı sırasında müşteriyi tanıma ilkesi çerçevesinde ve kullanıcının gerçek veya tüzel kişi
                    olmasına bağlı olarak T.C. Kimlik No Ad Soyad Adres Bilgisi Cep Telefonu Numarası Vergi No İmza Sirküleri vb.
                    bilgi ve belgeleri talep etme hakkına sahiptir. AHL Pay sunulan hizmetin niteliğine ve ilgili Mevzuat
                    hükümlerine göre kullanıcıdan farklı bilgi ve belge talep etme ve bunların iletim kanallarını belirleme hakkını
                    saklı tutar.</p>

                <h2>9. ÖDEMEYE ONAY VERİLMESİ:</h2>
                <p>1) Kullanıcı Ödeme işleminin başlaması ve tamamlanmasına ilişkin olarak AHL Pay’ e AHL Pay Sistemı̇ üzerinden
                    onay verir. Ödeme işlemi kullanıcı tarafından Ödeme işleminin gerçekleştirilmesi için AHL Pay tarafından
                    sağlanacak doğrulama kodunun ilgili alana girilmesi gibi yöntemlerle onay verilmesi ile etkinleştirilmiş
                    olacaktır. Ödeme işlemine ilişkin onay kararlaştırılan yönteme uygun olarak Ödeme işleminden önce veya sonra
                    verilebilir.</p>

                <h2>10. HARCAMA LİMİTİ:</h2>
                <p>AHL Pay kendi hizmetleri üzerinden gerçekleştirilecek işlemlere ilişkin olarak limit belirleme hakkını haizdir.
                    Kullanıcı AHL Pay tarafından belirlenen işlem limitleri ile bağlı olduğunu bu limitlerin üzerinde işlem
                    gerçekleştiremeyeceğini kabul eder. AHL Pay herhangi bir zamanda Sözleşme’ de belirlenen yöntem ve sürelere
                    uygun şekilde bildirim yaparak işlem limitlerini güncelleyebilecektir.</p>

                <h2>11. ÜCRETLER:</h2>
                <p>1) Kullanıcının kimlik tanımlamasındaki bilgilerin hatalı olması ve benzeri durumlardan kaynaklı olarak ödeme
                    emrinin geri alınması veya AHL Pay’ in fonları geri almasının gerektiği hallerde mobil uygulama ve internet
                    sitesinde ilan edilen ücret tablosundaki ücretler esastır.</p>
                <p>2) Gönderen ve alıcı sıfatına haiz kullanıcılar her bir ödeme işlemi için AHL Pay’ e Ücret Tablosu’nda belirlenen
                    ücreti öder. Ödeme işleminin bir para biriminin diğer bir para birimine çevrilmesini gerektirmesi durumunda
                    karşı tarafın ödeme hizmeti sağlayıcısına da Ücret Tablosunda belirlenen tutar kadar ödeme yapılır.</p>
                <p>3) Alıcı sıfatına haiz kullanıcının bir ödeme aracının kullanımı için ilave ücret talebini veya indirim teklifini
                    ödeme işleminden önce gönderene bildirir.</p>
                <p>4) AHL Pay veya bir üçüncü kişi ödeme aracının kullanımı ile ilgili Ücret Tablosundaki ücretleri Kullanıcılardan
                    tahsil etme yetkisine sahip olmakla beraber ödeme işleminden önce ödeme hizmeti kullanıcısına bildirecektir.</p>
                <p>5) AHL Pay Yönetmelik ve işbu sözleşme çerçevesinde sağlaması gereken bilgiler için ödeme hizmeti kullanıcısından
                    herhangi bir ücret talep etmeyecektir.</p>
                <p>6) Ancak kullanıcının ek bilgi daha sık bilgilendirme veya bilgilerin işbu sözleşmede öngörülenden farklı
                    yöntemlerle iletilmesini talep etmesi halinde AHL Pay kullanıcıdan bu Ücret Tablosunda belirtilen ücreti tahsil
                    etme yetkisine sahiptir.</p>
                <p>7) AHL Pay işbu maddede belirtilen ve Ücret Tablosu ile belirlenen ücretlerin maliyetiyle orantılı bir şekilde
                    arttırılması azaltılması ve/veya hiç ücret alınmaması haklarını saklı tutar.</p>

                <h2>12. ÖDEME HİZMETİ İLE İLGİLİ UYGULANACAK DÖVİZ KURU:</h2>
                <p>1) AHL Pay ödeme hizmetleriyle ilgili olarak uygulanacak döviz kuru veya referans döviz kuru ya da referans döviz
                    kurunu hesaplama yöntemine ilişkin olarak Kullanıcılara Yönetmelik ve işbu sözleşme hükümleri çerçevesinde bilgi
                    verilecektir.</p>
                <p>2) Referans döviz kurundaki değişiklikler derhal geçerli olacaktır. Ödeme İşleminde kullanılan döviz kurundaki
                    değişiklikler her kullanıcı için aynı şekilde hesaplanır ve uygulanır. Kullanıcılar lehine olan referans döviz
                    kuru değişiklikleri bildirim yapılmaksızın derhal uygulanır.</p>

                <h2>13. KULLANICININ SÖZLEŞMEYE ERİŞİMİ:</h2>
                <p>Kullanıcılara işbu sözleşmenin bir nüshası Kullanıcının talep etmesi halinde kâğıt üzerinde veya kalıcı veri
                    saklayıcısı ile verilir ya da kullanıcı <a href="http://www.ahlpay.com.tr">www.ahlpay.com.tr</a> internet
                    sitesinden erişim sağlayabilir.</p>

                <h2>14. ÖDEME ARACINA DAİR HÜKÜMLER:</h2>
                <p>1) Ödeme Aracı’nın mülkiyeti aksi belirtilmedikçe ve kullanıcı bu sözleşmeye göre bilgilendirilmedikçe AHL Pay’ e
                    aittir.</p>
                <p>2) AHL Pay ödeme aracının hileli veya yetkisiz kullanımı şüphesini doğuran durumlarda ödeme aracını kullanıma
                    kapatır ve diğer kanunlarda yer alan bilgi verilmesini engelleyici hükümler ile güvenliği tehdit edici objektif
                    nedenlerin bulunması hali dışında Gönderen sıfatına haiz kullanıcıyı kullanıma kapatma gerekçesi konusunda
                    Yönetmelik ve iş bu sözleşmeye uygun olarak bilgilendirir.</p>
                <p>3) Ödeme aracının kullanıma kapatılma sebebi ortadan kalktığında AHL Pay ödeme aracını kullanıma açar veya
                    Gönderen sıfatına haiz kullanıcıya yeni bir ödeme aracı temin eder.</p>
                <p>4) Kullanıcı ödeme aracı ile ilgili kişisel güvenlik bilgilerinin korunmasına yönelik gerekli önlemleri almak ve
                    ödeme aracını ihraç ve kullanım koşullarına uygun olarak kullanmakla yükümlüdür. Ödeme aracının kaybolması
                    çalınması veya iradesi dışında gerçekleşmiş herhangi bir işlemi öğrenmesi halinde ödeme hizmeti kullanıcısı
                    durumu derhal AHL Pay’ e veya AHL Pay tarafından belirlenmiş kuruluşa bildirir.</p>

                <h2>15. YETKİLENDİRİLMEMİŞ ÖDEME İŞLEMLERİ VE HATALI ÖDEME:</h2>
                <p>1) Kullanıcı yetkilendirmediği veya hatalı gerçekleştirilmiş ödeme işlemini öğrendiği andan itibaren AHL Pay’ e
                    gecikmeksizin bildirmek suretiyle işlemin düzeltilmesini isteyebilir. Düzeltme talebi her halükârda ödeme
                    işleminin gerçekleştirilmesinden itibaren on üç ay içinde yapılır.</p>
                <p>AHL Pay tarafından ödeme işlemine ilişkin Yönetmelikte veya işbu sözleşmede belirtilen tüm bilgilerin AHL Pay’ e
                    sağlanmamış olması halinde kullanıcı bu süreyle bağlı olmaksızın her zaman düzeltme talep edebilir. Bu fıkra
                    kapsamındaki bildirimlerin yazılı olarak veya uzaktan iletişim aracı ile yapılması ve bildirimlere ilişkin
                    kayıtların AHL Pay tarafından saklanması esastır.</p>
                <p>2) AHL Pay kullanıcının Ödeme emrinin doğru bir şekilde gerçekleştirilmesinden kullanıcıya karşı sorumludur. AHL
                    Pay Alıcı’nın ödeme hizmeti sağlayıcısının ödeme tutarını aldığını ispatlaması halinde ödeme işleminin doğru
                    gerçekleştirilmemesinden sorumlu tutulamaz.</p>
                <p>2.1. AHL Pay Ödeme İşleminin gerçekleşmemiş veya hatalı gerçekleşmiş kısmını gecikmeden kullanıcıya iade eder ve
                    tutarın Ödeme Hesabı’ndan düşülmüş olması halinde Ödeme Hesabı’nı eski durumuna getirir.</p>
                <p>2.2. Kullanıcı adına gelen ödemelerde Ödeme İşlemi tutarını derhal kullanıcının hesabına aktararak kullanımına
                    hazır hale getirir.</p>
                <p>2.3. Ödeme emrinin kullanıcı konumunda olan Alıcı tarafından veya Alıcı aracılığıyla verildiği hallerde AHL Pay
                    ödeme emrini gönderenin ödeme hizmeti sağlayıcısına doğru olarak gönderilmesinden Alıcı’ya karşı sorumludur. AHL
                    Pay Ödeme işlemi tutarının Alıcı’nın ödeme hesabına geçtiği iş gününün sonuna kadar bu tutarı Alıcı’nın ödeme
                    hesabına aktararak kullanımına hazır hale getirmekle yükümlüdür.</p>
                <p>2.4. Alıcı’nın ödeme hizmeti sağlayıcısının AHL Pay olduğu hallerde AHL Pay kendi hesaplarına aktarılmasını
                    müteakip ödeme işlemi tutarını derhal Alıcı’nın hesabına aktarır ve kullanımına hazır hale getirir. AHL Pay
                    Ödeme emrini gönderenin ödeme hizmeti sağlayıcısına doğru olarak gönderdiğini ispatlaması halinde ödeme
                    işleminin doğru gerçekleştirilmemesinden sorumlu tutulamaz AHL Pay talep edilmesi halinde gerçekleştirilmeyen
                    veya hatalı gerçekleştirilen ödeme işleminde tespit eder ve sonucunu Alıcı’ya bildirir.</p>
                <p>2.5. AHL Pay’ den kaynaklanan nedenler ile ödeme işleminin gerçekleştirilmemesi veya hatalı gerçekleştirilmesi
                    sonucunda ilgililerin ödemek zorunda kaldığı faiz ve ücretlerin tazmininden AHL Pay sorumludur.</p>
                <p>3) Kullanıcının gerçekleşmiş bir ödeme işlemini yetkilendirmediğini veya işlemin doğru bir şekilde
                    gerçekleşmediğini iddia etmesi durumunda bu işlemin kullanıcı tarafından onaylandığını doğru bir şekilde
                    kaydedildiğini ve hesaplara işlendiğini ispat yükümlülüğü AHL Pay’ e aittir.</p>
                <p>4) Ödeme işleminin kullanıcı tarafından yetkilendirilmeksizin ya da hatalı olarak gerçekleştiğinin ispatı
                    durumunda gönderen sıfatına haiz Kullanıcıya AHL Pay bu ödeme işlemine ilişkin tutarı derhal Kullanıcıya iade
                    etmek veya borçlandırılan ödeme hesabını eski durumuna getirmekle yükümlüdür.</p>
                <p>5) Kayıp veya çalıntı bir ödeme aracının kullanılması ya da kişisel güvenlik bilgilerinin gereği gibi muhafaza
                    edilmemesi nedeniyle ödeme aracının başkaları tarafından kullanılması durumunda gönderen sıfatına haiz
                    Kullanıcının yetkilendirmediği ödeme işlemlerinden doğan zararın yüz elli Türk Lirasına kadar olan bölümünden
                    sorumludur.</p>
                <p>6) Gönderen sıfatına haiz kullanıcının ödeme aracını hileli kullanması durumunda yetkilendirilmemiş ödeme
                    işleminden doğan zararın tamamından sorumludur.</p>
                <p>7) Gönderen sıfatına haiz Kullanıcı ödeme hesabını donduramaması ya da ödeme aracını kullanıma kapatamaması
                    hallerinde ödeme aracının kullanılmasından doğan zarardan gönderen sorumlu tutulamaz.</p>

                <h2>16. ALICI TARAFINDAN VEYA ALICI ARACILIĞIYLA BAŞLATILAN ÖDEME İŞLEMLERİNDE GERİ ÖDEME:</h2>
                <p>Alıcı tarafından yetkilendirilmiş veya alıcı aracılığıyla başlatılan ödeme işleminde yetkilendirme sırasında
                    ödeme işlemine ilişkin tutarın tam olarak belirtilmemesi ve gerçekleşen ödeme işlemi tutarının harcama geçmişi
                    sözleşme şartları ve ilgili diğer hususlar dikkate alınarak öngörülen tutarı aşması halinde Kullanıcı
                    gerçekleşen ödeme işlemi tutarı kadar geri ödeme yapılmasını talep edebilir. Alınan malın tüketilmesinden veya
                    hizmetin alınmasından sonra geri ödeme talep edilemez. AHL Pay kullanıcıdan talebine ilişkin gerçeğe dayalı
                    kanıtlar sunmasını isteyebilir. Geri ödeme talebi ödeme işleminin gerçekleştirildiği tarihten itibaren iki ay
                    içinde yapılmalıdır. AHL Pay kullanıcının geri ödeme talebini aldığı tarihten itibaren 20 (yirmi) iş günü
                    içerisinde ödemeyi yapar ya da gerekçeleri ile reddederek hukuki yolları başvurabileceğini bildirir.</p>

                <h2>17. SÖZLEŞME DEĞİŞİKLİKLERİ:</h2>
                <p>1) AHL Pay işbu sözleşmedeki her türlü değişikliği değişikliğin yürürlüğe girme tarihinden en az 30 (otuz gün)
                    önce kullanıcılara bildirir.</p>
                <p>2) AHL Pay tarafından birinci fıkra uyarınca yapılacak bildirimde değişikliğin kapsamı ve yürürlük tarihi
                    kullanıcının bu tarihe kadar işbu sözleşmeyi herhangi bir ücret ödemeksizin feshetme hakkının bulunduğu birinci
                    fıkrada öngörülen süre içinde itiraz edilmemesi halinde değişikliğini kabul edilmiş sayılacağı hususlarına yer
                    verilir.</p>
                <p>3) Taraflar referans döviz kurunun uygulandığı durumlarda referans döviz kurunda meydana gelecek değişikliklerin
                    bildirim yapılmaksızın derhal uygulanacağını kabul ederler. Kullanıcı lehine olan referans döviz kuru
                    değişiklikleri bildirim yapılmaksızın derhal uygulanır.</p>
                <p>4) Ödeme işleminde kullanılan döviz kurundaki değişiklikler her kullanıcı için aynı şekilde hesaplanır ve
                    uygulanır.</p>

                <h2>18. SÖZLEŞME SÜRESİ:</h2>
                <p>1) İşbu sözleşme süresiz olarak akdedilmiştir. Taraflar bu süre içerisinde işbu sözleşmede yazılı bulunan tüm
                    edimlerini yerine getirmeyi kabul ve taahhüt ederler. Aksi halde taraflardan biri Sözleşmeye göre
                    yükümlülüklerini yerine getirmezse yükümlülüğünü yerine getiren Taraf Sözleşmeyi feshedebilecektir. Tarafların
                    tebligat adresleri işbu sözleşmede belirtilen adresler olup değişiklik karşı Tarafa yazılı olarak bildirilmediği
                    takdirde bu adreslere yapılacak her türlü bildirim geçerli sayılacaktır.</p>
                <p>2) Tarafların ortak talebi ile işbu Sözleşmede düzenlenmeyen hususlar ayrıca tanzim edilecek ek protokol ile
                    hüküm altına alınabilecektir. Bu durumda Taraflar ek protokol ile hükme bağlanan hususlara ilişkin hiçbir itiraz
                    ve hak talebinde bulunamazlar.</p>

                <h2>19. SÖZLEŞMENİN SONA ERMESİ VE FESİH:</h2>
                <p>1) Kullanıcılar sözleşmedeki edim ve/veya taahhütlerinden herhangi birini hiç ya da gereği gibi ifa etmez ise
                    ve/veya bu edim ve/veya taahhütlerinden herhangi birini yerine getirmekten kaçınırsa ve/veya Sözleşme
                    hükümlerini kısmen veya tamamen ihlal eder ise AHL Pay (Kanundan ve işbu Sözleşmeden doğan diğer hakları saklı
                    kalmak kaydıyla) Ödeme Aracını kullanıma kapatabilir Sözleşmeyi feshederek borcun fer’ ileri ile birlikte
                    tamamen ödenmesini ve Ödeme Aracının iadesini talep edebilir.</p>
                <p>Kullanıcılar AHL Pay’ in yapacağı bildirim sonrasında ilgili Ödeme aracını AHL Pay’ e iade etmekle yükümlüdürler.
                    Kullanıcılar AHL Pay’ e bir ay önceden yazılı olarak bildirmek ve aynı zamanda ödeme aracını iade etmek ve tüm
                    borcu fer’ ileri ile birlikte ödemek suretiyle AHL Pay ise dilediği zaman herhangi bir hak ve nam altında bedel
                    ödemeksizin işbu Sözleşmeyi istediği zaman sona erdirebilir.</p>
                <p>2) İşbu Sözleşmenin bir yıldan uzun süreli Tarafı olan Kullanıcıları hariç olmak üzere diğer Kullanıcılar
                    tarafından feshi halinde bu Kullanıcılardan ücret Tablosunda belirtilen miktarda fesih ücreti talep edilir.</p>

                <h2>20. UYGULANACAK HUKUK VE YETKİLİ MAHKEME:</h2>
                <p>Taraflar işbu sözleşme ile bağlantılı olarak ortaya çıkacak anlaşmazlıklarda kanunen yetkili mahkeme ve icra
                    dairelerinin yetkileri saklı kalmak kaydıyla İstanbul Anadolu Mahkemelerinin ve İcra Daireleri’nin yetkili
                    olacağını kabul ederler. İşbu sözleşme Türkiye Cumhuriyeti yasalarına tabidir.</p>

                <h2>21. MÜCBİR SEBEP</h2>
                <p>1) İşbu Sözleşme kapsamında tarafların sözleşmeden kaynaklanan yükümlülüklerinin ifasını kısmen veya tamamen
                    engelleyen tarafların iradeleri dışında oluşan kaçınılması ve/veya önceden kestirilmesi mümkün olmayan genel
                    grev lokavt savaş terörist hareketler deprem sel gibi doğal afetler yangın halleri vb. gibi haller Mücbir sebep
                    sayılacaktır. Taraflar mücbir sebebin ortaya çıktığını karşı tarafa bildirecek ve belgeler ile
                    kanıtlayacaklardır. Mücbir sebep halinde yükümlülüklerini yerine getirmede gecikecek veya getiremeyecek taraf
                    durumu durumun ortaya çıkmasından itibaren mümkün olan en kısa zamanda ve en kısa yoldan diğer tarafa
                    bildirecektir. Bu halin 1 (bir) aydan fazla sürmesi halinde Taraflar işbu Sözleşmeyi Mücbir sebep hali ortadan
                    kalkana kadar askıya alabilecekler ya da karşılıklı olarak mutabık kalmak suretiyle sözleşmeyi
                    feshedebileceklerdir.</p>
                <p>2) AHL Pay’ in işbu sözleşme kapsamındaki yükümlülüklerini yerine getirmesinde AHL Pay’ in kontrolü dışındaki
                    olaylar (sistemsel ve teknik arıza ve aksaklıklar yasal adli ve idari merci karar ve uygulamaları hükümet ve
                    kamu kuruluşları ve yetkili merci/kurum kararları vb.) nedeniyle gecikme veya yerine getirememezlik olması
                    halinde ödeme yapılamaması durumunda Kullanıcılar AHL Pay’ den her ne nam altında olursa olsun herhangi bir
                    ödeme ceza veya tazminat talep etmeyeceğini ve AHL Pay’ e hiçbir suretle herhangi bir sorumluluk yüklemeyeceğini
                    kabul ve taahhüt eder.</p>

                <h2>22. GİZLİLİK VE BİLGİ GÜVENLİĞİ:</h2>
                <p>Taraflar işbu Sözleşme çerçevesinde herhangi bir şekilde diğer Taraf’tan temin edeceği tüm bilgileri devamlı
                    olarak gizli tutacağını saklayacağını sözleşmede belirtilenden başka bir amaç için kullanmayacağını ve bu
                    bilgileri gizli bilgiler olarak değerlendirerek ilgili Taraf’ın yazılı rızası olmaksızın üçüncü şahıslara (yasal
                    zorunluluklar dışında) ifşa etmeyeceğini beyan ve taahhüt etmektedir. İfşa edilmesi halinde ilgili Taraf’ın tüm
                    maddi ve manevi tazminat hakları saklıdır. Gizlilik hükmü sözleşmeden bağımsız bir taahhüt olup işbu sözleşmenin
                    herhangi bir nedenle hitamından sonra da yürürlükte kalacaktır.</p>

                <h2>23. ERGİN OLMAYAN KULLANICILAR:</h2>
                <p>1) Kullanıcının ergin olmadığı hallerde işbu sözleşme uyarınca AHL Pay ödeme aracının ilk ihracı ile elektronik
                    para hesaplarının açılması öncesinde kullanıcının yasal temsilcisinden onay alınacaktır. Onay alma yöntemi ve
                    süreci Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi tarafından belirlenmekte ve
                    değiştirilebilmektedir. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi dilerse kullanıcıdan kendisi
                    ya da yasal temsilcisi hakkında ek bilgi ve belge talep edebilir.</p>
                <p>2) Kullanıcının ergin olmadığı hallerde ayrıca bu madde uyarınca onayı alınan yasal temsilciye talep etmesi
                    halinde işbu sözleşmenin konusu olan AHL Pay hesabı ile yapılan harcamaları takip edebilme imkânı sunan
                    uygulamalara erişim sağlanacaktır. Ahlatcı Ödeme ve Elektronik Para Hizmetleri Anonim Şirketi bu süreci
                    yürürlükteki mevzuata uygun olarak değiştirebilir. Yasal temsilciye sunulan bu imkân kullanıcının erginliğe
                    ulaşmasıyla birlikte sona erecektir.</p>

                <h2>24. DEVİR VE TEMLİK:</h2>
                <p>Kullanıcı AHL Pay’ in yazılı onayı olmadan işbu sözleşme ile sözleşmeden doğan hak ve yükümlülüklerini kısmen
                    veya tamamen üçüncü kişilere devir ve temlik edemez.</p>

                <h2>25. DELİL:</h2>
                <p>İşbu Sözleşme kapsamındaki işlemler ve Hizmete ilişkin her türlü ihtilafta AHL Pay’ in kendi veri tabanında
                    sunucularında tuttuğu elektronik ve sistem kayıtlarının ticari kayıtlarının defter kayıtlarının mikrofilm
                    mikrofiş ve bilgisayar kayıtları delil teşkil edecek olup Taraflar bu maddenin H.M.K. Madde 193 anlamında bir
                    delil sözleşmesi teşkil ettiğini kabul ederler.</p>

                <h2>26. TADİL:</h2>
                <p>1) İşbu Sözleşmeyi değiştirecek ve/veya tamamlayacak her türlü anlaşmanın/tadil işleminin yazılı olarak yapılması
                    zorunlu olup sözlü anlaşmalar geçersizdir.</p>
                <p>2) İşbu sözleşmede yer alan madde başlıkları referans kolaylığı sağlamak amacıyla konulmuş olup Protokol’ün
                    yorumlanmasını veya uygulanmasını etkilemeyecektir.</p>

                <h2>27. SÖZLEŞMENİN BÜTÜNLÜĞÜ:</h2>
                <p>İşbu Sözleşme’nin esaslı olmayan hükümlerinden biri yahut birkaçının kısmen veya tamamen geçersiz addedilmesi
                    Sözleşme’nin kalan hükümlerinin geçerliliğine etki etmeyecektir.</p>

                <h2>28. FİKRİ MÜLKİYET:</h2>
                <p>İş bu sözleşme ile AHL Pay kullanıcıya herhangi bir fikri mülkiyet hakkını devir veya lisans gibi kullandırma
                    hakkı sağlamamaktadır. AHL Pay’ e ait ve/veya kullanıcının kullanımında olan her nevi fikri mülkiyet hakkı
                    konusu unsuru kullanımı ancak bu kapsamda yazılı ve açık bir mutabakat bulunması halinde mümkün olacak ve
                    bununla sınırlı kalacaktır.</p>

                <h2>29. YÜRÜRLÜLÜK:</h2>
                <p>İşbu Sözleşme 29 maddeden ibaret olup Taraflar arasında imzalanması ile birlikte yürürlüğe girer.</p>

                <p>AHLATCI Ödeme ve Elektronik Para Hizmetleri A.Ş.</p>

                <p>Kaşe/İmza</p>

                <p>Ad Soyad: …………………………………</p>
                <p>Tarih: …………………………………</p>

                <p>İmza</p>
            </article></>,
        languageCode: 'tr'
    }
]

const Agreements = () => {

    const [activeAgr, setActiveAgr] = useState(1);

    const childrens = <main page="inside">

        <Section element="agreements">

            <h2>Sözleşmeler</h2>

            <div>
                <aside>

                    <ul>
                        {pageJSON.map((item) => {
                            return <li active={activeAgr == item.id ? 'true' : undefined}><a href="#" onClick={() => setActiveAgr(item.id)} title={item.title}>{item.title}</a></li>
                        })}
                    </ul>

                </aside>
                <content>

                    {pageJSON.find(x => x.id == activeAgr)?.data}

                </content>
            </div>
        </Section>

    </main>




    return <MainLayout children={childrens} />
};


export default Agreements;