import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { checkAndSetDefaultLanguageCookie } from './app/common/Middlewares';
import { LanguageProvider } from './app/contexts/LanguageContext';
import LiveChat from "./app/components/LiveChat";

checkAndSetDefaultLanguageCookie();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <LanguageProvider>
    <App/>
    </LanguageProvider>
  </React.StrictMode>
);

reportWebVitals();