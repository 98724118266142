// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'AHL Pay AndroidPOS',
        headerTitle: 'AndroidPOS',
        title: "AHL Pay Android POS",
        shortDescription: 'Dijital çözümler sunarak her yerde hızlı ve güvenli ödeme al. Android POS ile işlemlerini kolaylaştır, hızlandır ve dijitalleştir. Satış, ödeme ve fatura işlemlerini birleştirerek daha etkin ve hızlı bir iş yönetim deneyimi sağla.',
        description: 'VUK 507 ve 509 (Vergi Usul Kanunu) tebliğine uygun olarak geliştirilen AHL Pay Android POS ile işletmenin satışlarını artırma ve büyüme potansiyelini maksimize et.',
        imagePath: 'assets/uploads/products/products/kurumsal_androidpos.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Android POS</>,
        childrens: [
            {
                title: 'Güvenli ve Esnek Ödeme Seçenekleri',
                desc: 'Kıymetli Madenleriniz ile yatırımlarınız güvende'
            },
            {
                title: 'Etkili Ödeme İşlemleri',
                desc: 'Android POS ile ödemelerini anında al. Kullanıcı dostu arayüzü sayesinde, işlemleri hızlıca tamamlayarak müşterilerine kesintisiz bir deneyim sunar.'
            },
            {
                title: 'Hızlı ve Güvenli Ödeme Alma',
                desc: 'Temassız özelliği sayesinde Android POS ile, kredi kartlarından, banka kartlarından veya ön ödemeli kartlardan güvenle ödeme al.'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
