// @ts-nocheck
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';

const pageJSON = {
    detail: [
        {
            data: <>
                <h1>
                    AHL Pay:<br />
                    Finans ve Teknolojinin Kesişme Noktası.
                </h1>

                {/* <picture><img src="assets/uploads/about/1024x768.jpg" alt="" /></picture> */}

                <article>

                    <blockquote>AHL Pay markası ile faaliyetlerini sürdüren Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, bir Ahlatcı Holding'in iştirakidir.</blockquote><br />

                    Ahlatcı Holding'in altın, finans, enerji, ar-ge, otomotiv, gayrimenkul, sağlık ve savunma sanayi sektörlerindeki başarılarına katılan AHL Pay, 2019 yılında lisans alarak finans sektöründe faaliyetlere başlamıştır. 2021’de alınan faaliyet izinleri sonrası, 2022 yılının haziran ayı itibarıyla da sürdürülebilir çalışmalarına devam etmektedir.<br /><br />

                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, 6493 sayılı kanun çerçevesinde TCMB tarafından yetkilendirilen Elektronik Para ve Ödeme Hizmetleri kuruluşudur. Bankalararası Kart Merkezi (BKM)’nin banka dışı üyesidir. TROY, Mastercard ve Visa şemaları ile kart ihraç etme yetkisine sahip olan elektronik para kuruluşudur.<br /><br />

                    AHL Pay, ödeme hizmetleri ve elektronik para alanında inovatif çözümler sunmakta ve ödeme alanındaki faaliyetlerin gelişimini sağlamaktadır. Müşterilerine en güvenli ve en hızlı hizmeti sunma amacıyla çalışmalarını sürdüren AHL Pay, müşteri memnuniyetine önem vermektedir.<br /><br />

                    AHL Pay’li olan bireysel kullanıcılar; para transferleri, kurum ve fatura ödemeleri, kartlı ve kartsız işlemler, fiziki ve kaydi kıymetli maden işlemleri, altın alma ve satma, yatırım işlemleri, dijital oyun satın alma ve dünya markalarının fırsatlarından yararlanmaktadır. AHL Pay mobil ile tüm finansal akış hızlıca takip edilmekte ve güvenle alışveriş yapılmaktadır.<br /><br />

                    Aynı zamanda kurumsal müşterilere özel avantajlı komisyon oranları sunulmaktadır. SanalPOS, CepPOS, AndroidPOS ve AltınPOS gibi kurumsal hizmetlerle beraber yenilikçi ödeme çözümleri de sağlamaktadır.<br /><br />

                    Kuyumculuk sektörüne özel geliştirdiği AltınPOS ile Türkiye genelinde yüzlerce kuyumcuya POS işlemleri için geniş kapsamlı bir ürün hizmeti sunmaktadır. Kuyumcuların sadece ödeme işlemi sonrasında kur riskini ve ürün tedariğini de içeren AltınPOS, Türkiye’deki kuyumcular için önemli bir çözüm kaynağı olmuştur.<br /><br />

                    SanalPOS ürünü ile e-ticaret sektörlerindeki birçok ticari ve kurumsal firmaya hizmet vermektedir.<br /><br />

                    Mobil uygulama olan CepPOS, tüm telefon ve tabletlerin POS cihazına dönüşmesini sağlamaktadır. Uygulama ile hızlı ve güvenli bir şekilde ödeme tahsis edilmektedir.<br /><br />

                    BenimFaturam.com.tr web sitesi ve mobil uygulaması ile ülke genelindeki tüm kurum faturalarının taksitli ödenebilmesini ve nakit iade alınabilmesine imkân tanımaktadır.<br /><br />

                    AHL Pay geniş kaynakları ve güçlü altyapısıyla, finans sektöründeki değişimlere uyum sağlayarak, dijital çağın getirdiği fırsatları en iyi şekilde değerlendiren bir finans kurumu olarak öne çıkmaktadır.<br /><br />

                    İnternet alışverişlerinde kullanılması için Sanal Card ile tüm alışverişlerinizde geçerli fiziki kart müşterilerimize sunulmaktadır.<br /><br />

                    Sanal Card, AHL Pay hesabına bağlı bir şekilde oluşturulan sanal kart ile online alışverişler hızlı ve güvenli bir şekilde gerçekleştirilmektedir.<br /><br />

                </article>

            </>,
            languageCode: 'tr'
        },
        {
            data: <>
                <h1>
                    AHL Pay:<br />
                    Finans ve Teknolojinin Kesişme Noktası.
                </h1>

                {/* <picture><img src="assets/uploads/about/1024x768.jpg" alt="" /></picture> */}

                <article>

                    <blockquote>AHL Pay markası ile faaliyetlerini sürdüren Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, bir Ahlatcı Holding'in iştirakidir.</blockquote><br />

                    Ahlatcı Holding'in altın, finans, enerji, ar-ge, otomotiv, gayrimenkul, sağlık ve savunma sanayi sektörlerindeki başarılarına katılan AHL Pay, 2019 yılında lisans alarak finans sektöründe faaliyetlere başlamıştır. 2021’de alınan faaliyet izinleri sonrası, 2022 yılının haziran ayı itibarıyla da sürdürülebilir çalışmalarına devam etmektedir.<br /><br />

                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, 6493 sayılı kanun çerçevesinde TCMB tarafından yetkilendirilen Elektronik Para ve Ödeme Hizmetleri kuruluşudur. Bankalararası Kart Merkezi (BKM)’nin banka dışı üyesidir. TROY, Mastercard ve Visa şemaları ile kart ihraç etme yetkisine sahip olan elektronik para kuruluşudur.<br /><br />

                    AHL Pay, ödeme hizmetleri ve elektronik para alanında inovatif çözümler sunmakta ve ödeme alanındaki faaliyetlerin gelişimini sağlamaktadır. Müşterilerine en güvenli ve en hızlı hizmeti sunma amacıyla çalışmalarını sürdüren AHL Pay, müşteri memnuniyetine önem vermektedir.<br /><br />

                    AHL Pay’li olan bireysel kullanıcılar; para transferleri, kurum ve fatura ödemeleri, kartlı ve kartsız işlemler, fiziki ve kaydi kıymetli maden işlemleri, altın alma ve satma, yatırım işlemleri, dijital oyun satın alma ve dünya markalarının fırsatlarından yararlanmaktadır. AHL Pay mobil ile tüm finansal akış hızlıca takip edilmekte ve güvenle alışveriş yapılmaktadır.<br /><br />

                    Aynı zamanda kurumsal müşterilere özel avantajlı komisyon oranları sunulmaktadır. SanalPOS, CepPOS, AndroidPOS ve AltınPOS gibi kurumsal hizmetlerle beraber yenilikçi ödeme çözümleri de sağlamaktadır.<br /><br />

                    Kuyumculuk sektörüne özel geliştirdiği AltınPOS ile Türkiye genelinde yüzlerce kuyumcuya POS işlemleri için geniş kapsamlı bir ürün hizmeti sunmaktadır. Kuyumcuların sadece ödeme işlemi sonrasında kur riskini ve ürün tedariğini de içeren AltınPOS, Türkiye’deki kuyumcular için önemli bir çözüm kaynağı olmuştur.<br /><br />

                    SanalPOS ürünü ile e-ticaret sektörlerindeki birçok ticari ve kurumsal firmaya hizmet vermektedir.<br /><br />

                    Mobil uygulama olan CepPOS, tüm telefon ve tabletlerin POS cihazına dönüşmesini sağlamaktadır. Uygulama ile hızlı ve güvenli bir şekilde ödeme tahsis edilmektedir.<br /><br />

                    BenimFaturam.com.tr web sitesi ve mobil uygulaması ile ülke genelindeki tüm kurum faturalarının taksitli ödenebilmesini ve nakit iade alınabilmesine imkân tanımaktadır.<br /><br />

                    AHL Pay geniş kaynakları ve güçlü altyapısıyla, finans sektöründeki değişimlere uyum sağlayarak, dijital çağın getirdiği fırsatları en iyi şekilde değerlendiren bir finans kurumu olarak öne çıkmaktadır.<br /><br />

                    İnternet alışverişlerinde kullanılması için Sanal Card ile tüm alışverişlerinizde geçerli fiziki kart müşterilerimize sunulmaktadır.<br /><br />

                    Sanal Card, AHL Pay hesabına bağlı bir şekilde oluşturulan sanal kart ile online alışverişler hızlı ve güvenli bir şekilde gerçekleştirilmektedir.<br /><br />

                </article>


            </>,
            languageCode: 'en'
        }
    ]
}

const AboutUsPage = () => {

    const { language } = useLanguage();

    const childrens = <main page="inside">

        <section element="about">
            <div>
                {pageJSON.detail.find(x => x.languageCode == language)?.data}
            </div>
        </section>

    </main>;

    return <MainLayout children={childrens} />
};

export default AboutUsPage;