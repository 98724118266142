// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'AHL Pay ile Para Yükle',
        headerTitle: 'Para Yükle',
        title: "AHL Pay ile Para Yükle",
        shortDescription: 'Para Yükleme işlemi ile finansal özgürlüğünü kontrol altında tut. Online bankacılık işlemlerini kolaylaştır ve paranı her zaman, her yerde kullanıma hazır hale getir.',
        description: "Transferler ekranında yer alan Para Yükle seçeneğine tıklayarak, karşına gelen bilgiler ile, şahsi banka hesabından AHL Pay hesabına para yükleme işlemini güvenle yapabilirsin. Para yükleme işlemi anında gerçekleşir, bu sayede paran hesabında hemen kullanıma hazır hale gelir. Bakiyeni mobil ödemelerinde, online alışverişlerinde veya fatura ödemelerinde rahatlıkla kullanabilirsin",
        imagePath: 'assets/uploads/products/phones/parayukle.svg',
        title2: <>Nasıl<span> Para yüklerim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Kolayca Para Yükle',
                desc: "Banka hesaplarından ve ya AHL Card ile tüm ATM'lerden para yükle"
            },
            {
                title: 'Hızlı ve Güvenli',
                desc: 'Güvenle para yatır, anında hesabına geçsin'
            },
            {
                title: 'Keyifle Harca',
                desc: 'Bakiyeni yurt içindeki alışverişlerinde ve e-ticaret sitelerinde kullan'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
