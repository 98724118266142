// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const campaigns = [
  {
    "id": 5,
    "title": "* 27.08.2024 GS - YGB Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – Youngboys UEFA Avrupa Ligi Maç Bileti Talihlileri:\n\nASİL \n1- MUHAMMED DÜlGE\n2- YAĞIZ TOKALI\n3- CEMAL KURT\n4- CENGİZ HAN\n5- BURAK TURUNÇTUR\n6- VURAL EROL\n7- MUSTAFA ÖMER KIYAR\n8- GÖKHAN YÜREKLİ\n9- SAMET DENİZ\n10- OĞUZ CEM ÖNTAŞ",
    "languageCode": "tr",
    "imagePath": "assets/uploads/products/icons/gs_elf.png"
  }
]

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(5);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.imagePath} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
