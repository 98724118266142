// @ts-nocheck
import { createBrowserRouter } from "react-router-dom";
import ContactPage from "../pages/contact/contact";
import Home from "../pages/home/home";
import FAQPage from "../pages/faq/FAQ";
import Career from "../pages/career/career";
import CareerDetail from "../pages/career/career-detail";
import PageKeys from "../constants/Pagekeys";
import AboutLogos from "../pages/about-logos/about-logos";
import AboutMedia from "../pages/about-media/about-media";
import Agreements from "../pages/agreements/agreements";
import Certificates from "../pages/certificates/certificates";
import Forms from "../pages/forms/forms";
import LegalInfos from "../pages/legalinfos/legalinfos";
import AboutUsPage from "../pages/about-us/AboutUs";
import FeesAndLimitsPage from "../pages/feesAndLimits/FeesAndLimitsPage";
import BlogPage from "../pages/blogs/Blogs";
import BlogDetailsPage from "../pages/blogs/BlogDetail";
import ProductCorporatePage from "../pages/products/Corporate";
import ProductIndividualPage from "../pages/products/basvuru";
import CampaignsPage from "../pages/campaigns/campaigns";
import { title } from "process";
import ProductIndividualV2Page from "../pages/products/IndividualV2";
import ProductCorporateV2Page from "../pages/products/Corporate2";
import ProductApplicationModal from "../pages/products/modals/ProductApplication";
import ApplyPage from "../pages/apply/apply";
import LiveChat from "./app/components/LiveChat";
import NotFound from "../pages/notfound/NotFound";
import Test from '../pages/test/Test';
import Test1 from '../pages/test/Test1';
import Test2 from '../pages/test/Test2';
import Test3 from '../pages/test/Test3';
import Test4 from '../pages/test/Test4';
import Test5 from '../pages/test/Test5';
import Test6 from '../pages/test/Test6';
import Test7 from '../pages/test/Test7';
import Test8 from '../pages/test/Test8';
import Test9 from '../pages/test/Test9';
import Test10 from '../pages/test/Test10';
import TestKurumsal from '../pages/test/TestKurumsal';
import TestKurumsal1 from '../pages/test/TestKurumsal1';
import TestKurumsal2 from '../pages/test/TestKurumsal2';
import TestKurumsal3 from '../pages/test/TestKurumsal3';
import TestKurumsal4 from '../pages/test/TestKurumsal4';
import TestKurumsal5 from '../pages/test/TestKurumsal5';
import TestKurumsal6 from '../pages/test/TestKurumsal6';
import TestKampanya from '../pages/test/TestKampanya';
import TestKampanya1 from '../pages/test/TestKampanya1';
import TestKampanya2 from '../pages/test/TestKampanya2';
import TestKampanya3 from '../pages/test/TestKampanya3';
import TestKampanya4 from '../pages/test/TestKampanya4';
import TestKampanya5 from '../pages/test/TestKampanya5';
import TestKampanya6 from '../pages/test/TestKampanya6';
import TestKampanya7 from '../pages/test/TestKampanya7';
import TestKampanya8 from '../pages/test/TestKampanya8';
import TestKampanya9 from '../pages/test/TestKampanya9';
import TestKampanya10 from '../pages/test/TestKampanya10';
import TestKampanya11 from '../pages/test/TestKampanya11';
import TestKampanya12 from '../pages/test/TestKampanya12';
import TestKampanya13 from '../pages/test/TestKampanya13';
import TestKampanya14 from '../pages/test/TestKampanya14';
import TestKampanya15 from '../pages/test/TestKampanya15';
import TestKampanya16 from '../pages/test/TestKampanya16';
import TestKampanya17 from '../pages/test/TestKampanya17';

export const RouterList = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/basvuru",
        element: <ApplyPage openModal={true} setOpenModal={null} />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
    {
        path: "/test",
        element: <Test />,
    },
    {
        path: "/test1",
        element: <Test1 />,
    },
    {
        path: "/test2",
        element: <Test2 />,
    },
    {
        path: "/test3",
        element: <Test3 />,
    },
    {
        path: "/test4",
        element: <Test4 />,
    },
    {
        path: "/test5",
        element: <Test5 />,
    },
    {
        path: "/test6",
        element: <Test6 />,
    },
    {
        path: "/test7",
        element: <Test7 />,
    },
    {
        path: "/test8",
        element: <Test8 />,
    },
    {
        path: "/test9",
        element: <Test9 />,
    },
    {
        path: "/test10",
        element: <Test10 />,
    },
    {
        path: "/testKurumsal",
        element: <TestKurumsal />,
    },
    {
        path: "/testKurumsal1",
        element: <TestKurumsal1 />,
    },
    {
        path: "/testKurumsal2",
        element: <TestKurumsal2 />,
    },
    {
        path: "/testKurumsal3",
        element: <TestKurumsal3 />,
    },
    {
        path: "/testKurumsal4",
        element: <TestKurumsal4 />,
    },
    {
        path: "/testKurumsal5",
        element: <TestKurumsal5 />,
    },
    {
        path: "/testKurumsal6",
        element: <TestKurumsal6 />,
    },
    {
        path: "/TestKampanya",
        element: <TestKampanya />,
    },
    {
        path: "/TestKampanya1",
        element: <TestKampanya1 />,
    },
    {
        path: "/TestKampanya2",
        element: <TestKampanya2 />,
    },
    {
        path: "/TestKampanya3",
        element: <TestKampanya3 />,
    },
    {
        path: "/TestKampanya4",
        element: <TestKampanya4 />,
    },
    {
        path: "/TestKampanya5",
        element: <TestKampanya5 />,
    },
    {
        path: "/TestKampanya6",
        element: <TestKampanya6 />,
    },
    {
        path: "/TestKampanya7",
        element: <TestKampanya7 />,
    },
    {
        path: "/TestKampanya8",
        element: <TestKampanya8 />,
    },
    {
        path: "/TestKampanya9",
        element: <TestKampanya9 />,
    },
    {
        path: "/TestKampanya10",
        element: <TestKampanya10 />,
    },
    {
        path: "/TestKampanya11",
        element: <TestKampanya11 />,
    },
    {
        path: "/TestKampanya12",
        element: <TestKampanya12 />,
    },
    {
        path: "/TestKampanya13",
        element: <TestKampanya13 />,
    },
    {
        path: "/TestKampanya14",
        element: <TestKampanya14 />,
    },
    {
        path: "/TestKampanya15",
        element: <TestKampanya15 />,
    },
    {
        path: "/TestKampanya16",
        element: <TestKampanya16 />,
    },
    {
        path: "/TestKampanya17",
        element: <TestKampanya17 />,
    },
    {
        path: "/",
        unique: PageKeys.CONTACT_US,
        children: [
            {
                path: 'contact',
                element: <ContactPage />,
                languageCode: 'en',
                title: 'Contact Us'
            },
            {
                path: 'bize-ulasin',
                element: <ContactPage />,
                languageCode: 'tr',
                title: 'Bize Ulaşın'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.FAQ,
        children: [
            {
                path: 'faq',
                element: <FAQPage />,
                languageCode: 'en',
                title: 'Frequently Asked Questions'
            },
            {
                path: 'sss',
                element: <FAQPage />,
                languageCode: 'tr',
                title: 'Sıkça Sorulan Sorular'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.CAREER,
        children: [
            {
                path: 'career',
                element: <Career />,
                languageCode: 'en',
                title: 'Career'
            },
            {
                path: 'kariyer',
                element: <Career />,
                languageCode: 'tr',
                title: 'Kariyer'
            }
        ]
    },
    // {
    //     path: '/career',
    //     element: <Career />
    // },
    {
        path: '/career-detail',
        element: <CareerDetail />
    },
    {
        path: '/logo',
        element: <AboutLogos />
    },
    {
        path: '/media',
        element: <AboutMedia />
    }
    ,
    {
        path: '/',
        unique: PageKeys.CAMPAIGNS,
        children: [
            {
                path: 'campaigns',
                element: <CampaignsPage />,
                languageCode: 'en',
                title: 'Campaigns'
            },
            {
                path: 'campaigns',
                element: <CampaignsPage />,
                languageCode: 'tr',
                title: 'Kampanyalar'
            }
        ]

    },
    {
        path: '/',
        children: [
            {
                path: '/Agreements',
                element: <Agreements />,
                languageCode: 'en'
            },
            {
                path: '/sozlesmeler',
                element: <Agreements />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/iso',
        element: <Certificates />
    },
    {
        path: '/',
        children: [
            {
                path: '/forms',
                element: <Forms />,
                languageCode: 'en'
            },
            {
                path: '/formlar',
                element: <Forms />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: '/legal-information',
                element: <LegalInfos />,
                languageCode: 'en'
            },
            {
                path: '/yasal-bilgiler',
                element: <LegalInfos />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'about-us',
                element: <AboutUsPage />,
                languageCode: 'en'
            },
            {
                path: 'hakkimizda',
                element: <AboutUsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.FEES_AND_LIMITS,
        children: [

            {
                path: 'ucret-ve-limitler',
                element: <FeesAndLimitsPage />,
                languageCode: 'tr',
                title: 'Ücret ve Limitler'
            },
            {
                path: 'fees-and-limits',
                element: <FeesAndLimitsPage />,
                languageCode: 'en',
                title: 'Fees & Limits'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.BLOGS,
        children: [
            {
                path: 'blogs',
                element: <BlogPage />,
                languageCode: 'en',
                title: 'Blog'
            },
            {
                path: 'blogs',
                element: <BlogPage />,
                languageCode: 'tr',
                title: 'Blog'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'blog-detail',
                element: <BlogDetailsPage />,
                languageCode: 'en'
            },
            {
                path: 'blog-detail',
                element: <BlogDetailsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanyalar',
                element: <CampaignsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanyalar',
                element: <CampaignsPage />,
                languageCode: 'en'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanya-detay',
                element: <CampaignsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanya-detay',
                element: <CampaignsPage />,
                languageCode: 'en'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_INDIVIDUAL_V2,
        children: [
            {
                path: 'products-corporate-old',
                element: <ProductCorporatePage />,
                languageCode: 'en',
                title: 'Corporate'
            },
            {
                path: 'products-corporate-old',
                element: <ProductCorporatePage />,
                languageCode: 'tr',
                title: 'Kurumsal'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_INDIVIDUAL,
        children: [
            {
                path: 'products-individual',
                element: <ProductIndividualV2Page />,
                languageCode: 'en',
                title: 'Retail'
            },
            {
                path: 'products-individual',
                element: <ProductIndividualV2Page />,
                languageCode: 'tr',
                title: 'Bireysel'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_CORPORATE,
        children: [
            {
                path: 'products-corporate',
                element: <ProductCorporateV2Page />,
                languageCode: 'en',
                title: 'corporate'
            },
            {
                path: 'products-corporate',
                element: <ProductCorporateV2Page />,
                languageCode: 'tr',
                title: 'Kurumsal'
            }
        ]
    },
];

export const GetRouteDetail = (uniqueKey, language) => {
    return RouterList.find(x => x.unique == uniqueKey).children.find(x => x.languageCode == language);
}

export const router = () => { return createBrowserRouter(RouterList); }