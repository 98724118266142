// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'AHL Pay SanalPOS',
        headerTitle: 'SanalPOS',
        title: "AHL Pay SanalPOS",
        shortDescription: 'AHL Pay Sanal Pos, işletmenin finansal işlemlerini sadeleştirirken, aynı zamanda hızlı, güvenli ve ekonomik bir çözüm sunar. Kullanıcılar, tek çekim ve taksit imkanları ile tüm işlemlerini tek bir panel üzerinden takip edebilir, Masterpass entegrasyonu ile tek bir tıkla kayıtlı kartlarını ödeme ekranında listeleyebilir.',
        description: 'Sanal POS, işletmene online ödeme imkanı sunar. Kullanıcı dostu arayüzü sayesinde, ödemelerini kolayca takip et. Güvenli ve hızlı işlemlerle müşterilerine keyifli bir alışveriş deneyimi yaşat',
        imagePath: 'assets/uploads/products/products/sanalpos_odeme_pc.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Sanal POS</>,
        childrens: [
            {
                title: 'Anında Ödeme Almanın Kolay Yolu',
                desc: 'Sanal POS ile ödemelerini dakikalar içinde al, düşük işlem ücretleri ile karlılığını artır.'
            },
            {
                title: 'Güvenli Ödeme Seçenekleri',
                desc: 'Gelişmiş güvenlik sistemleri ile her işlemde veri güvenliğini korur'
            },
            {
                title: 'Kullanıcı Dostu Arayüz',
                desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
