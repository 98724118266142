// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const campaigns = [
  {
    "id": 9,
    "title": "* 06.10.2024 GS - ALY Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – Alanya Trendyol Süper Lig Maç Bileti Talihlileri:\n\nASİL \n1- GÜRHAN KARAKUZU\n2- ÇINAR AKDAĞ\n3- KENAN ÖZKAN\n4- İHSAN YILDIZ\n5- MUHAMMED GÜNER\n6- MEHMET ALİ TEKDEMİR\n7- DOĞAN KARACA\n8- FURKAN KARAGÖZ\n9- YUSUF KEREM MUTİ\n10- BARIŞ AYDIN\n11- GÜRKAN AYDIN\n12- GÜLCAN KILIÇASLAN\n13- MUTLU GÖKHAN UÇAR\n14- YUSUF SIRCALI\n15- SİNAN ÇALIŞIR\n\nYEDEK \n1- ONUR DURMUŞ\n2- FATİH ÜSAME ALIÇ\n3- SERDAR AKSOY\n4- SEMİH ÇOLAK\n5- HAMDİ ÇETİN\n6- CEYHUN SAKALLI\n7- MUSTAFA BAPUN\n8- ALİ GÜNCAN\n9- EMİR GÜLENÇ\n10- FERDİ KOP",
    "languageCode": "tr",
    "imagePath": "assets/uploads/products/icons/gsalanya.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(9);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
