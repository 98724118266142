// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const campaigns = [
  {
    "id": 11,
    "title": "* 28.10.2024 GS - BJK Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – Beşiktaş Maç Bileti Yarışması Talihlileri:\n\nASİL \n1- MURAT KALELİ\n2- YAĞIZ TOKALI\n3- SERHAT ÖZYURT\n4- EGE IRGIZ\n5- GÜNEY CAN\n6- BAŞAK ÇİÇEK\n7- ATAKAN VELİ SAĞLAM\n8- OSMAN AKIL\n9- ALPER YAZICI\n10- MAHMUT EMİR AYGÜN\n\nYEDEK \n1- SONGÜL KARASLAN\n2- MEHMET ERTAŞ\n3- İSMAİL KARADABAN\n4- BATUHAN AKIN\n5- EZGİ BENEK BOZKURT\n6- KADİR KARA\n7- İSA YÜREKTEN\n8- EMRULLAH ACABEY\n9- ABDULBAKİ ŞAHİNER\n10- ESRA YALÇIN\n11- CENAP GÖKHAN HÜDAİ\n12- AHMET ARDA ARIKAN\n13- BURCU AKIN ŞİMŞEK\n14- BUĞRA KOCATÜRK\n15- LEYLA GÜRSES YILMAZ",
    "languageCode": "tr",
    "imagePath": "assets/uploads/products/icons/gsbjk.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(11);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
