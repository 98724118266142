export { };

declare global {
    interface Array<T> {
        take(count: number): T[];
        skip(count: number): T[];
    }
}

Array.prototype.take = function <T>(count: number): T[] {
    if (count < 0) return [];
    return this.slice(0, count);
};

Array.prototype.skip = function <T>(count: number): T[] {
    if (count < 0) return this;
    return this.slice(count);
};