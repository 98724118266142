import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';

const NotFound = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/'); 
    };

    return (
        <div style={notFoundContainer}>
            {/* Logo */}
            <img src="/assets/logo.png" alt="Logo" style={notFoundLogo} />
            
            {/* 404 Mesajı */}
            <h1 style={notFoundTitle}>404</h1>
            
            {/* Açıklama */}
            <p style={notFoundText}>Üzgünüz, Aradığın Sayfa Bulunamadı.</p>
            
            {/* Ana Sayfaya Dön Butonu */}
            <button style={notFoundButton} onClick={handleBackToHome}>
                Ana Sayfaya Dön
            </button>
        </div>
    );
};

const notFoundContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '0 20px',
};

const notFoundLogo: CSSProperties = {
    width: '220px',
    marginBottom: '30px',
};

const notFoundTitle: CSSProperties = {
    fontSize: '96px',
    fontWeight: 'bold',
    margin: '0',
    color: '#002abe', 
};

const notFoundText: CSSProperties = {
    fontSize: '20px',
    color: '#002abe', 
    margin: '20px 0',
};

const notFoundButton: CSSProperties = {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#05aa3c', 
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

export default NotFound;

