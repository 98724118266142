// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = [
    {
        id: 1,
        title: 'Elektronik İleti Onay Formu',
        data: <><h1>AHLATCI ÖDEME ve ELEKTRONİK PARA HİZMETLERİ A.Ş.</h1>
            <h2>VERİ SORUMLUSU’NA BAŞVURU FORMU</h2>
            <article>
                <h2>1. Genel Açıklamalar</h2>
                <p>
                    6698 Sayılı Kişisel Verilerin Korunması Kanunu’nda (“KVKK”) ilgili kişi olarak tanımlanan
                    kişisel veri sahiplerine (“Başvuru Sahibi”) KVKK’nın 11. maddesi uyarınca kişisel verilerinin
                    işlenmesine ilişkin birtakım taleplerde bulunma hakkı tanınmıştır.
                </p>
                <p>
                    Başvuru formu, AHLATCI ÖDEME ve ELEKTRONİK PARA HİZMETLERİ A.Ş. (“AHL
                    Pay”) ile olan ilişkinizi tespit ederek, varsa, AHL Pay tarafından işlenen kişisel verilerinizin
                    eksiksiz olarak belirlenerek, ilgili başvurunuza doğru ve kanuni süresinde cevap verilebilmesi
                    için hazırlanmıştır. Kişisel verilerinizin güvenliğinin sağlanması ve hukuka aykırı veri
                    aktarımının önlenmesi amacıyla, kimlik ve yetki tespiti için AHL Pay tarafından ek bilgiler
                    istenebilir. Başvuru Sahibi tarafından belirtilen bilgilerin doğru ve/veya güncel olmaması ya da
                    taleplerin yetkisiz olması halinde, bu hususa ilişkin sorumluluk Başvuru Sahibi’nindir.
                </p>
                <p>
                    Veri Sorumlusu’na Başvuru Usul Ve Esasları Hakkında Tebliğ’in 7. maddesi uyarınca Başvuru
                    Sahibi’nin başvurusuna yazılı olarak cevap verilecekse, on sayfaya kadar ücret alınmaz. On
                    sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem ücreti alınabilir. Başvuruya cevabın CD,
                    flash bellek gibi bir kayıt ortamında verilmesi halinde kayıt ortamının maliyeti kadar ücret talep
                    edilebilir.
                </p>

                <h2>2. KVKK’nın 11. Maddesi Çerçevesinde Başvuru Hakkının Kapsamı</h2>
                <p>Başvuru Sahibi AHL Pay’e başvurarak aşağıda yer alan konularda talepte bulunabilir:</p>
                <ol>
                    <li>Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerinin işlenme amacı ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Kişisel verilerinin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri öğrenme,</li>
                    <li>Kişisel verilerinin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinin silinmesini, yok edilmesini veya anonim hale getirilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerinin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                </ol>

                <h2>3. Başvuru Yöntemi</h2>
                <p>
                    KVKK’nın 13. maddesinin birinci fıkrası uyarınca; bu haklara ilişkin olarak yapılacak
                    başvuruların yazılı ve imzalı olarak veya Kişisel Verilerin Korunması Kurulu (“Kurul”)
                    tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi gerekmektedir.
                </p>
                <p>
                    Bu çerçevede yazılı olarak yapılacak başvurular, işbu formun çıktısı alınarak;
                </p>
                <ul>
                    <li>Başvuru Sahibi’nin şahsen elden başvurusu ile,</li>
                    <li>Noter vasıtasıyla,</li>
                    <li>Kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ile veya  Başvuru
                        Sahibi’nin tarafımıza daha önce bildirdiği ve sistemimizde kayıtlı bulunan elektronik posta
                        adresinden göndereceği e-mail ile tarafımıza iletilebilecektir.</li>
                </ul>

                <p>
                    <strong>Başvuru Yöntemi</strong> <br />
                    Başvuru Gönderiminde Belirtilecek Bilgi <br />
                    Başvurunun Yapılacağı Adres
                </p>
                <ul>
                    <li>Şahsen Başvuru
                        <ul>
                            <li>(Başvuru Sahibi’nin bizzat gelerek kimliğini tevsik edici belge ile başvurması)</li>
                            <li>Zarfın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</li>
                            <li>İçerenköy Mahallesi Yeşilvadi Sokak NO:3/4 Kat 8 Ataşehir/İstanbul</li>
                        </ul>
                    </li>
                    <li>Noter vasıtasıyla tebligat
                        <ul>
                            <li>Tebligat zarfına “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</li>
                        </ul>
                    </li>
                    <li>“Güvenli eloktronik imza” ile imzalanarak Kayıtlı Elektronik Posta (KEP) Yoluyla
                        <ul>
                            <li>E-posta’nın konu kısmına “Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.</li>
                        </ul>
                    </li>
                    <li>Mobil İmza ya da Eposta ile Başvuru
                        <ul>
                            <li>[İlgili kişi tarafından veri sorumlusuna daha önce bildirilen ve Veri Sorumlusu’nun sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle]</li>
                            <li>E-posta’nın konu kısmına “Kişisel Verilerin Korunması Kanunu Bilgi Talebi” yazılacaktır.</li>
                        </ul>
                    </li>
                </ul>
                <p>E-posta adresi: ahlatciodeme@hs03.kep.tr</p>

                <p>
                    AHL Pay, Başvuru Sahibi tarafından e-mail yolu ile yapılacak başvurularda
                    Başvuru Sahibi’nin kimliğini doğrulamak için ek bilgiler talep edebilecek, gerekli önlemleri
                    alabilecektir.
                </p>
                <p>
                    Tarafımıza iletilmiş olan başvurularınız KVKK’nın 13. maddesinin ikinci fıkrası gereğince,
                    talebin niteliğine göre talebinizin tebliği veya ulaştığı tarihten itibaren otuz gün içinde yazılı
                    veya elektronik ortamda cevap verilecektir.
                </p>

                <h2>4. Kimlik ve İletişim Bilgileriniz</h2>

                <p><strong>A. Başvuru Sahibi İletişim Bilgileri</strong></p>
                <p>Ad</p>
                <p>Soyad</p>
                <p>Tc Kimlik No / Pasaport No (yabancı ise)</p>
                <p>Telefon</p>
                <p>E-Posta</p>
                <p>İkamet veya İşyeri Adresi</p>

                <p><strong>B. Lütfen AHL Pay ile olan ilişkinizi belirtiniz. (Ziyaretçi, Müşteri, İş ortağı çalışan, çalışan adayı, eski çalışan, üçüncü taraf firma çalışanı, hissedar gibi)</strong></p>
                <p> Ziyaretçi</p>
                <p> Müşteri</p>
                <p> İş ortağı</p>
                <p> Çalışan</p>
                <p> Çalışan adayı</p>
                <p> Eski Çalışan</p>
                <p> Diğer:……………………………………</p>

                <p>Şirketimiz içerisinde iletişimde olduğunuz Birim:</p>
                <p>Konu:</p>

                <h2>5. Talep Konusu</h2>
                <p>Lütfen KVKK kapsamındaki talebinizi detaylı olarak belirtiniz:</p>

                <p>Başvuru Sahibi Adı</p>
                <p>Soyadı</p>
                <p>İmza (yazılı başvuru ise):</p>
            </article></>,
        languageCode: 'tr'
    },
    {
        id: 2,
        title: 'Açık Rıza Beyan Formu',
        data: <><h1>AÇIK RIZA BEYANI</h1>
            <article>
                <p>
                    Kullanıcı, AHL Pay tarafından ürün ve hizmetlerinin sunulabilmesi, bu konuda
                    aldığınız/alacağınız ürün ve hizmete ilişkin iletişim kurulabilmesi, indirim, avantaj, fayda,
                    bilgilendirme vb. gibi ticari ve yasal amaçlarla veri, ses ve görüntü içerikli iletilerin
                    gönderilmesine ve yine ürün ve hizmetlerinin sunulabilmesi, bu konuda aldığınız/alacağınız
                    ürün ve hizmete ilişkin iletişim kurulabilmesi, indirim, avantaj, fayda, bilgilendirme vb. gibi
                    pazarlama faaliyetlerinde kullanılabilmesi, ürün/hizmet teklifi, modelleme, raporlama, skorlama,
                    risk izleme, şirketimizin faaliyetleri ile ilişkili olarak kişisel verilerin işlenmesine özgür iradesi ile
                    açıkça rıza gösterir.
                </p></article></>,
        languageCode: 'tr'
    },
    {
        id: 3,
        title: 'Kart Harcama İtiraz Formu',
        data: <><h1>KART HARCAMA İTİRAZ FORMU</h1> 
        <a href="/assets/images/Harcama İtiraz Formu.pdf" download>
        <br />
            <button type="button">İndir</button>
        </a>
            <article>
            <h2>1. Kart Harcama İtiraz Formu</h2>
<p>Formu imzalayıp aşağıdaki e-posta adresine gönderebilirsiniz:</p>
<p>E-posta: <strong>harcamaitirazi@ahlpay.com.tr</strong></p>

<h2>2. Kart Bilgileri</h2>
<p>Kart Numarası:</p>

<h2>3. İşlem Detayları</h2>
<table>
    <tr>
        <th>İşlem Yapılan İş Yeri Adı</th>
        <th>İşlem Tutarı</th>
        <th>İşlem Tarihi</th>
    </tr>
    <tr>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td></td>
        <td></td>
        <td></td>
    </tr>
</table>
<p>Not: İtiraz edilen işlem adedi 4’ten fazla ise lütfen Ek Form’u doldurunuz.</p>

<h2>4. İtiraz Sebepleri</h2>
<p>Lütfen itiraz sebebinizi yalnızca bir kutuya (x) işareti koyarak belirtiniz:</p>
<ul>
    <li>( ) Aynı işlem ekstreme iki kere yansıtılmıştır.</li>
    <li>( ) Yukarıda belirtilen işlemin tarafımdan ya da yetki verdiğim bir kişi tarafından yapılmadığını teyit ederim. Bu işlemler yapıldığında kart mülkiyetim altındaydı. (Bu seçeneği işaretlerseniz, itiraza konu olan kartınız güvenlik nedeniyle kapatılıp yenilenecektir.)</li>
    <li>( ) İşlem, iş yeri tarafından iptal edilmesine rağmen kartıma alacak/iade tutarı gönderilmemiştir. (Eğer iade slibi mevcutsa lütfen dilekçeye ekleyiniz).</li>
    <li>( ) İşlem tutarı hatalıdır. .......... olması gerekirken .......... olarak yapılmıştır. (Doğru tutarı gösteren belgeyi ekleyiniz).</li>
    <li>( ) Harcama tutarı farklı şekilde ödendiği halde kartıma borç olarak kaydedilmiştir. (İlgili ödeme belgesi ektedir).</li>
    <li>( ) .../.../... tarihine kadar teslim edilmesi gereken mal/hizmeti teslim almadım ve .../.../... tarihinde iş yerine bildirdim. Ancak, iş yerinden olumlu bir yanıt alamadım. (Teslim alınmayan mal/hizmetin belirtildiği belge ve iş yerinin cevabı ektedir. Üzerinde teslimat tarihi taahhüdünün bulunduğu fatura ektedir.)</li>
    <li>( ) İş yerinden gelen ürünü geri gönderdiğim halde kredi kartıma iade yapılmamıştır. (Kanıt ekleyiniz).</li>
    <li>( ) Teslim edilen mal hatalı, kusurlu veya istenilen ürünle gelen ürünün kalitesi birbirinden farklıdır. İş yeriyle çözüm için görüştüm fakat başarılı olamadım. (Açıklama ve kanıt ekleyiniz).</li>
    <li>( ) Diğer (Açıklama ekleyiniz).</li>
</ul>

<h2>5. Başvuru Süreci</h2>
<p>İtirazınız, uluslararası işlem itiraz kuralları gereği incelenecektir. Bu süreç sonunda mail veya SMS ile bilgilendirileceksiniz.</p>
<h2>6. Kart Sahibi Bilgileri</h2>
<p><strong>Kart Sahibinin Adı ve Soyadı:</strong></p>
<p><strong>Telefon Numarası:</strong></p>
<p><strong>E-Posta:</strong></p>
<p><strong>Tarih:</strong></p>
<p><strong>İmza:</strong></p>

               
        </article></>,
        languageCode: 'tr'
    }

];

const Forms = () => {

    const [activeData, setActiveData] = useState(1);

    const childrens = <main page="inside">

        <section element="agreements">

            <h2>Formlar</h2>

            <div>
                <aside>

                    <ul>
                        {pageJSON.map((item) => {
                            return <li active={item.id == activeData ? 'true' : undefined}><a href="#" onClick={() => setActiveData(item.id)} title={item.title}>{item.title}</a></li>
                        })}
                    </ul>

                </aside>
                <content>

                    {pageJSON.find(x => x.id == activeData)?.data}

                </content>
            </div>
        </section>

    </main>

    return <MainLayout children={childrens} />
};


export default Forms;