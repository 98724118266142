// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

export const CardStack = () => {

    const [cards, setCards] = useState([
        { id: 1, src: "assets/images/AHLFTL.png" },
        { id: 2, src: "assets/images/AHLFT.png" }
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const containerRef = useRef(null);

    const [isAnimationStarted, setAnimationStarted] = useState(false);
    const [isFirstAnimationClass, setIsFirstAnimationClass] = useState(true);

    useEffect(() => {
        if (isAnimationStarted) {
            const animateCards = () => {
                setIsAnimating(true);

                setTimeout(() => {
                    setCards(prevCards => {
                        const newCards = [...prevCards];
                        const [firstCard] = newCards.splice(0, 1);
                        return [...newCards, firstCard];
                    });

                    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
                    setIsAnimating(false);
                }, 300);
            };

            const intervalId = setInterval(animateCards, 3000);

            return () => clearInterval(intervalId);
        }
    }, [cards.length, isAnimationStarted]);

    useEffect(() => {
        if (containerRef.current) {
            const cardElements = containerRef.current.querySelectorAll('.credit-card');
            cardElements.forEach((card, index) => {
                card.style.zIndex = cardElements.length - index;
                if (index === 0) {
                    card.style.transform = 'translate(0, 0) rotate3d(1.4, 0.8, 1, -65deg)';
                } else if (index === 1) {
                    card.style.transform = 'translate(0, 80px) rotate3d(1.4, 0.8, 1, -65deg)';
                } else if (index === 2) {
                    card.style.transform = 'translate(0, 150px) rotate3d(1.4, 0.8, 1, -65deg)';
                }
            });
        }
    }, [cards]);

    useEffect(() => {
        ScrollTrigger.create({
            trigger: containerRef.current,
            start: "top bottom",
            onEnter: () => {

                setInterval(() => {
                    setAnimationStarted(true);
                    setIsFirstAnimationClass(false);
                }, 500)

                console.log('triggered')
            },
            once: true
        });
    }, []);

    return (
        <div className={`credit-card-container`} id='credit-card' ref={containerRef}>
            {cards.map((card, index) => (
                <div
                    key={card.id}
                    className={`credit-card credit-card${index + 1} ${index === 0 && isAnimating ? 'move-right' : ''} ${(isFirstAnimationClass ? 'credit-card-main-transform' : '')}`}
                >
                    <img src={card.src} alt={`Credit card ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};