// @ts-nocheck
import { act, useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";

import { useLanguage } from "../../contexts/LanguageContext";

import Pagination from "../../common/Pagination";
import '../../common/Extensions';
import ContactModal from "./modals/ContactModal";

var categoryIdCounter = 1;
const categoriesJSON = [
    {
        id: 1,
        title: 'Tümü',
        languageCode: 'tr'
    },
    {
        id: 1,
        title: 'All',
        languageCode: 'en'
    },
    {
        id: 11,
        title: 'Uygulama',
        languageCode: 'tr'
    },
    {
        id: 11,
        title: 'Application',
        languageCode: 'en'
    },
    {
        id: 7,
        title: 'AHL Pay Hesap',
        languageCode: 'tr'
    },
    {
        id: 7,
        title: 'AHL Pay Wallet',
        languageCode: 'en'
    },
    {
        id: 10,
        title: 'Bireysel Hesap',
        languageCode: 'tr'
    },
    {
        id: 10,
        title: 'Retail Account',
        languageCode: 'en'
    },
    {
        id: 4,
        title: 'Sanal/Fiziki Kart',
        languageCode: 'tr'
    },
    {
        id: 4,
        title: 'Virtual Cards',
        languageCode: 'en'
    },
    {
        id: 2,
        title: 'Fiziki Altın/Gümüş',
        languageCode: 'tr'
    },
    {
        id: 2,
        title: 'Physical Gold & Silver',
        languageCode: 'en'
    },
    {
        id: 3,
        title: 'Kıymetli Maden',
        languageCode: 'tr'
    },
    {
        id: 3,
        title: 'Precious Metal',
        languageCode: 'en'
    },
    {
        id: 8,
        title: 'Para Gönderme',
        languageCode: 'tr'
    },
    {
        id: 8,
        title: 'Money Transfer',
        languageCode: 'en'
    },
    {
        id: 9,
        title: 'Para İste',
        languageCode: 'tr'
    },
    {
        id: 9,
        title: 'Money Request',
        languageCode: 'en'
    },
    {
        id: 6,
        title: 'Fatura Ödemeleri',
        languageCode: 'tr'
    },
    {
        id: 6,
        title: 'Bill Payments',
        languageCode: 'en'
    },
    {
        id: 5,
        title: 'Kampanyalar',
        languageCode: 'tr'
    },
    {
        id: 5,
        title: 'Campaigns',
        languageCode: 'en'
    },
    {
        id: 12,
        title: 'Gizlilik & Güvenlik',
        languageCode: 'tr'
    },
    {
        id: 12,
        title: 'Privacy & Security',
        languageCode: 'en'
    }
];


const baslik = {
    baslik1: [
        {
            title: <h1>Sıkça Sorulan Sorular</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Frequently Asked Questions</h1>,
            languageCode: 'en'
        }
    ],
    baslik2: [
        {
            title: <h2>Sunduğumuz hizmetlerle ilgili sorularınızın cevaplarına buradan ulaşabilirsin.</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2> You can find the answers to your questions about the services we offer here.</h2>,
            languageCode: 'en'
        }
    ],
    baslik3: [
        {
            title: <h5>Aradığınızı bulamadınız mı?</h5>,
            languageCode: 'tr'
        },
        {
            title: <h5>Did you not find what you were looking for?</h5>,
            languageCode: 'en'
        }
    ],
    baslik4: [
        {
            title: <h6>Aradığınız sorunun cevabını bulamadıysanız bize ulaşın.</h6>,
            languageCode: 'tr'
        },
        {
            title: <h6>If you haven't found the answer to the question you are looking for, please contact us.</h6>,
            languageCode: 'en'
        }
    ],
    baslik5: [
        {
            title: <h3>İletişim Formu</h3>,
            languageCode: 'tr'
        },
        {
            title: <h1>Contact Form</h1>,
            languageCode: 'en'
        }
    ],
}

const faqJSON = [
    
        {
            "title": "Hesabım anonim, fiziki altın/gümüş satın alabilir miyim?",
            "shortDescription": "Fiziki altın/gümüş satın alabilmen için 18 yaşından büyük ve Onaylı hesap türüne sahip olman gerekiyor.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Can I purchase physical gold/silver with a beginner account?",
            "shortDescription": "Unfortunately, to purchase physical gold/silver, you need to be over 18 years old and have an 'Approved' account type.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Aldığım ürünü iade edebilir miyim?",
            "shortDescription": "Ürünler iade edilemez.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Can I return the product I purchased?",
            "shortDescription": "Products cannot be returned.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Kredi kartım ile fiziki altın/gümüş satın alabilir miyim?",
            "shortDescription": "Evet. Ürün ödemelerini hem AHL Pay hesabından hem de kredi kartından yapabilirsin. Hesabında bakiyen yoksa, Para Yükle seçeneği ile bakiye yükleyerek fiziki altın satın alabilirsin.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Can I purchase physical gold/silver with my credit card?",
            "shortDescription": "Yes, you can make payments from your AHL Pay account or your credit card. If you don't have sufficient balance in your wallet, you can top up using the 'Load Money' option to purchase physical gold.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Siparişimi iptal edebilir miyim?",
            "shortDescription": "Siparişin oluşturulduktan sonra iptal edilemez.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Can I cancel my order?",
            "shortDescription": "Orders cannot be canceled once they are placed.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Kargom ne zaman ulaşır?",
            "shortDescription": "Ürünler, Ahlatcı Kuyumculuk tarafından gönderiliyor. Ürünlerin hazırlandığında kargo firması tarafından SMS ile bilgilendirileceksin.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "When will my shipment arrive?",
            "shortDescription": "Your product is sent by Ahlatcı Kuyumculuk. You will be notified by SMS when your products are prepared and shipped by the cargo company.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Fiziki altınım/gümüşüm güvence altında mıdır?",
            "shortDescription": "Evet. Siparişin sana ulaşana kadar Ahlatcı Kuyumculuk sigortası altındadır.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Is my physical gold/silver insured?",
            "shortDescription": "Yes, your order is insured by Ahlatcı Kuyumculuk until it reaches you.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Komisyon ücreti alınıyor mu?",
            "shortDescription": "Hayır. Alım-satım işlemlerinde komisyon ücreti alınmıyor.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "Is there a commission fee?",
            "shortDescription": "No, there is no commission fee for your payments.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Faturalandırma nasıl yapılıyor?",
            "shortDescription": "Faturan, ürünün ile birlikte fiziki olarak kargo ile gönderiliyor.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "How is invoicing done?",
            "shortDescription": "Your invoice is sent physically along with your product via cargo.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Ürünün kargoda bir sorunla karşılaşması durumunda süreç nasıl işlemektedir?",
            "shortDescription": "Siparişin, Ahlatcı Kuyumculuk tarafından PTT Kargo ile, ücretsiz sigortalı kargo güvencesiyle adresine gönderilir. Siparişin kargoda hasar görmesi veya kaybolması durumunda, yaşanan zarar kesinlikle sana yansıtılmaz. Kaybolan ürün yerine, sipariş ettiğin ürünün yenisi teslim edilmek üzere tekrar yola çıkar.",
            "categoryId": 2,
            "languageCode": "tr"
        },
        {
            "title": "What is the process if there is an issue with the product during shipment?",
            "shortDescription": "Your order is sent by Ahlatcı Kuyumculuk to your address with free insured shipping via PTT Kargo. If your order is damaged or lost during shipment, the incurred loss will not be charged to you. If the product is lost during shipment, it will be replaced with a new one and sent to you again.",
            "categoryId": 2,
            "languageCode": "en"
        },
        {
            "title": "Hesabım anonim, kıymetli maden satın alabilir miyim?",
            "shortDescription": "Kıymetli maden satın alabilmen için 18 yaşından büyük ve Onaylı hesap türüne sahip olman gerekiyor.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Can I purchase precious metals with an anonymous account?",
            "shortDescription": "Unfortunately, to purchase precious metals, you need to be over 18 years old and have an 'Approved' account type.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Aldığım kıymetli madeni AHL Pay hesabı olan kullanıcılara transfer sağlayabilir miyim?",
            "shortDescription": "Bunun için geliştirmelerimiz devam ediyor. Kıymetli maden transferi yalnızca kıymetli maden hesabı olan AHL Pay kullanıcıları arasında gerçekleşebilecek.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Can I transfer the precious metal I purchased to users with an AHL Pay account?",
            "shortDescription": "We are continuing to develop this feature. Precious metal transfers will only be possible between AHL Pay users who have a precious metal account.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Kredi kartım ile ödeme yapabilir miyim?",
            "shortDescription": "Hayır. Ürün ödemelerini AHL Pay hesabından yapabilirsin. Hesabında bakiyen yoksa 'Para Yükle' seçeneği ile bakiye yükleyerek kıymetli maden satın alabilirsin.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Can I make payments with my credit card?",
            "shortDescription": "No, you can make payments from your AHL Pay wallet account. If you don't have sufficient balance in your wallet, you can top up using the 'Load Money' option to purchase precious metals.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Komisyon ücreti alınıyor mu?",
            "shortDescription": "Hayır. Alım-satım işlemlerinde komisyon ücreti alınmıyor.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Is there a commission fee?",
            "shortDescription": "No, there is no commission fee for your buy-sell transactions.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Altın ayar ve saflık değeri nedir?",
            "shortDescription": "24 ayar, 995/1000 saflık değerinde.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "What is the gold's carat and purity?",
            "shortDescription": "The gold is 24 carats, with a purity of 995/1000.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Gümüş saflık değeri nedir?",
            "shortDescription": "Gümüş, 1000/1000 saflık değerinde.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "What is the purity of silver?",
            "shortDescription": "The silver has a purity of 1000/1000.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Platin saflık değeri nedir?",
            "shortDescription": "Platin, 1000/1000 saflık değerinde.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "What is the purity of platinum?",
            "shortDescription": "The platinum has a purity of 1000/1000.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Kambiyo vergisi ödeyecek miyim?",
            "shortDescription": "8 Ağustos 2023 tarihinde Gelir İdaresi Başkanlığı tarafından yapılan düzenleme ile kıymetli maden işlemlerinde uygulanan 'Binde 2' oranındaki Kambiyo Gider Vergisi kaldırıldı.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Will I pay the foreign exchange tax?",
            "shortDescription": "As of August 8, 2023, the '0.2%' Foreign Exchange Transaction Tax on precious metals transactions has been removed by the Revenue Administration.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Ne zaman alım-satım yapabilirim?",
            "shortDescription": "7/24 alım-satım işlemi yapabilirsin.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "When can I buy or sell?",
            "shortDescription": "You can perform buy-sell transactions 24/7.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Aldığım kıymetli madenleri daha sonra fiziki olarak teslim alabilir miyim?",
            "shortDescription": "Hayır, satın aldığın kıymetli madenler sadece 'KAYDİ' olarak hesabında tutulur. Fiziki altın & gümüş almak için lütfen uygulamadaki 'FİZİKİ ALTIN & GÜMÜŞ' kısmına bak.",
            "categoryId": 3,
            "languageCode": "tr"
        },
        {
            "title": "Can I physically receive the precious metals I purchased later?",
            "shortDescription": "No, the purchased precious metals are only held in your account. For physical gold & silver, please refer to the 'PHYSICAL GOLD & SILVER' section in our app.",
            "categoryId": 3,
            "languageCode": "en"
        },
        {
            "title": "Sanal karta nasıl başvurabilirim?",
            "shortDescription": "AHL Pay uygulamasında yer alan 'Kart' butonuna tıklayarak, sanal kart oluşturma süreci ile kartını ücretsiz olarak oluşturabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I apply for a virtual card?",
            "shortDescription": "You can create your virtual card for free by clicking the 'Card' button in the AHL Pay application and following the steps to create a virtual card.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kart oluşturmak ücretli mi?",
            "shortDescription": "Hayır. Sanal kartını ücretsiz bir şekilde uygulama içerisinden oluşturabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Is there a fee to create a virtual card?",
            "shortDescription": "No, you can create your virtual card for free within the app.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kart aidatı bulunmakta mıdır?",
            "shortDescription": "Hayır. Sanal kartını ücretsiz bir şekilde oluşturabilirsin, süreç içerisinde de herhangi bir ücret ödemezsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Does the virtual card have a maintenance fee?",
            "shortDescription": "No, you can create your virtual card for free and there are no fees during the process.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kartımı hangi alanlarda kullanabilirim?",
            "shortDescription": "Sanal kartını güvenli e-ticaret sitelerinde kullanabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Where can I use my virtual card?",
            "shortDescription": "You can use it on secure e-commerce sites. You can also benefit from AHL Pay promotions that can be applied to your virtual card.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kartımı dondurabilir/kapatabilir miyim?",
            "shortDescription": "Evet. AHL Pay uygulamamızın AHL Card sekmesinden sanal kartına tıkladıktan sonra Kart Ayarları butonu ile kartını dondurabilir ve dilediğin zaman yeniden aktif edebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Can I freeze/deactivate my virtual card?",
            "shortDescription": "Yes. After logging into the AHL Pay app, you can freeze or later reactivate your card by going to the Cards section and using the Settings button in the top right corner.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kartımın limiti nedir?",
            "shortDescription": "Hesap bakiyen kadar kullanabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "What is the limit of my virtual card?",
            "shortDescription": "You can use it up to the amount of your wallet balance.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Sanal kartım bloke oldu ne yapmalıyım?",
            "shortDescription": "444 90 66 numaralı iletişim merkezimizden veya destek@ahlpay.com.tr adresinden bize ulaşabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "My virtual card is blocked, what should I do?",
            "shortDescription": "You can contact us at our call center at 444 90 66 or via email at destek@ahlpay.com.tr.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {/////////////
            "title": "AHL Card ile Neler Yapabilirim?",
            "shortDescription": "AHL Card ile harcamalarını etkili bir şekilde takip edebilir ve hesabına yüklediğin tutar kadar harcama gerçekleştirebilirsin. AHL Card’ı yurt içindeki alışverişlerinin yanı sıra internet alışverişlerinde de kullanabilirsin. Bu sayede bütçeni daha iyi yönetebilir ve harcamalarını her zaman kontrol altında tutabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "What can I do with the AHL Card?",
            "shortDescription": "With the AHL Card, you can effectively track your expenses and spend only as much as you have loaded onto your account. You can use the AHL Card not only for domestic purchases but also for online shopping. This way, you can manage your budget better and keep your spending under control.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card nasıl başvurabilirim?",
            "shortDescription": "Uygulamaya giriş yaptıktan sonra AHL Card sekmesinde 'Dark' ve 'Moonlight' seçeneklerimizi bulabilirsin. Kartlarımızdan hangisi senin zevkine hitap ediyorsa, dilediğin kartı talep edebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I apply for the AHL Card?",
            "shortDescription": "After logging into the app, you can find our 'Dark' and 'Moonlight' options in the AHL Card section. You can request whichever card appeals to your taste.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card başvuru ücreti ne kadardır?",
            "shortDescription": "AHL Card başvuru ücreti 99,90 TL'dir. Şu anda '200 TL Nakit Hediye' kampanyamız mevcuttur, kartını aktifleştirdikten sonra hesabına 200 TL nakit hediye yüklenecektir.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "What is the application fee for the AHL Card?",
            "shortDescription": "Currently, there is an ongoing campaign, and the application fee for the AHL Card is 99.90 TL. After activating your card, a cash gift of 200 TL will be loaded onto your account.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card nasıl aktif ederim?",
            "shortDescription": "AHL Card Dark veya Moonlight kartın adresine teslim edildikten sonra, AHL Card menüsünden 'Aktive Et' butonuna tıkla. Kart Numarası, SKT ve CVV bilgilerini gir, şifreni oluştur, kartın aktif hale gelsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I apply for the AHL Card?",
            "shortDescription": "After logging into the app, you can find our 'Dark' and 'Moonlight' options in the AHL Card section. You can request whichever card appeals to your taste.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card aktif edemiyorum, ne yapmalıyım?",
            "shortDescription": "AHL Card'ınızı aktif etmekte sorun yaşıyorsan, 444 90 66 numaralı müşteri destek hattımızdan bizi arayabilir veya destek@ahlpay.com.tr adresimizden AHL Pay ekibimize ulaşabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "I cannot activate my AHL Card; what should I do?",
            "shortDescription": "If you are having trouble activating your AHL Card, you can call our customer support hotline at 444 90 66 or reach out to our AHL Pay team at destek@ahlpay.com.tr.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card aidat ücreti alınıyor mu?",
            "shortDescription": "AHL Card için herhangi bir kart aidat ücreti alınmamaktadır.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Is there an annual fee for the AHL Card?",
            "shortDescription": "No annual fee is charged for the AHL Card.",
            "categoryId": 4,
            "languageCode": "en"
        }
        ,
        {
            "title": "AHL Card ile ATM'den para çekebilir miyim?",
            "shortDescription": "Evet, AHL Card ile tüm ATM'lerden kolaylıkla para çekebilir ve yatırabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Can I withdraw money from an ATM with the AHL Card?",
            "shortDescription": "Yes, you can easily withdraw and deposit money at all ATMs using your AHL Card.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card nerelerde kullanabilirim?",
            "shortDescription": "TROY logolu AHL Card’ını yurt içinde her yerde kullanabilirsin. Banka kartının geçerli olduğu her noktada işlemlerini gerçekleştirebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Where can I use the AHL Card?",
            "shortDescription": "You can use your TROY-branded AHL Card everywhere in the country. You can perform transactions at any location where bank cards are accepted.",
            "categoryId": 4,
            "languageCode": "en"
        }
        ,
        {
            "title": "AHL Card'a nasıl para yüklerim?",
            "shortDescription": "AHL Card'ına dilersen banka hesabından dilersen ATM üzerinden para yükleme işlemi gerçekleştirebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I load money onto my AHL Card?",
            "shortDescription": "You can load money onto your AHL Card either from your bank account or through an ATM.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card seçenekleri nedir?",
            "shortDescription": "AHL Card’ı sanal kart olarak oluşturabilirsin. Fiziksel kart talep etmek istersen, AHL Card Dark ve AHL Card Moonlight olmak üzere iki seçeneğimiz mevcut, dilediğin tasarımı seçerek kart talep edebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "What are the options for the AHL Card?",
            "shortDescription": "You can create the AHL Card as a virtual card. If you want a physical card, we have two options: AHL Card Dark and AHL Card Moonlight. You can choose the design you prefer and request a card.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Hesap bakiyemi tüm AHL Card'larımda kullanabilir miyim?",
            "shortDescription": "AHL Card hesap bakiyen, tüm AHL Card'larında kullanılabilir. Kartını iptal ettiğinde bakiyen hesabında kalır; yeni bir kart talep ettiğinde mevcut bakiyeni yine kullanmaya devam edebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Can I use my account balance across all my AHL Cards?",
            "shortDescription": "Your AHL Card account balance can be used on all your AHL Cards. If you cancel your card, your balance remains in your account; you can continue using your existing balance on your new cards.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card’ım ne zaman teslim edilir?",
            "shortDescription": "AHL Card ortalama 2 hafta içinde adresine kurye ile teslim edilir. Kart başvurusu yapıldıktan sonra, 'AHL Card' sekmesinden 'Kart Detay' sayfasına girilerek kurye aşaması takip edilebilir. Kartın dağıtıma çıktığında, kurye firmasının göndereceği SMS üzerinden gönderi takibi yapabilirsin. Kartın eline ulaşmaması durumunda, destek@ahlpay.com.tr adresinden veya 444 90 66 numaralı müşteri destek hattımızdan bize ulaşabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "When will my AHL Card be delivered?",
            "shortDescription": "The AHL Card is typically delivered to your address within 2 weeks by courier. After applying for the card, you can track the courier stage from the card details page in the AHL Card section. If your card does not reach you, you can contact us at destek@ahlpay.com.tr or call our customer support at 444 90 66.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card için harcama itirazında bulunabilir miyim?",
            "shortDescription": "Eğer yapılan AHL Card harcamalarının sana ait olmadığını düşünüyorsan, harcama itirazında bulunabilirsin. Bilgin dışında yapılmış olan işlemler için ters ibraz (chargeback) süreci başlatılır. Sürecin başlatılması için destek@ahlpay.com.tr adresinden veya 444 90 66 numaralı müşteri destek hattımızdan bize ulaşabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Can I file a dispute for an expense on my AHL Card?",
            "shortDescription": "If you believe that the AHL Card transactions are not yours, you can file a dispute for the expense. A chargeback process will be initiated for transactions made without your knowledge. To start the process, you can contact us via email at destek@ahlpay.com.tr or call our customer support line at 444 90 66. You can access the expense dispute form through the provided link.",
            "categoryId": 4,
            "languageCode": "en"
        }
        ,
        {
            "title": "Kartımı nasıl iptal edebilirim?",
            "shortDescription": "Kartını iptal etmek istediğinde, kart kullanıma kapatılır ve tekrar açılması mümkün olmaz. Kartını geçici olarak kullanıma kapatmak istersen, AHL Card menüsünden Kart Ayarları> Kartı Dondur özelliğini kullanabilirsin. Dilediğin zaman kartını yeniden aktifleştirebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I cancel my card?",
            "shortDescription": "When you want to cancel your card, it will be disabled and cannot be reopened. If you want to temporarily disable your card, you can use the 'Freeze Card' feature in the Card Settings of the AHL Card menu. You can reactivate your AHL Card at any time.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Kart bilgilerimi kimlerle paylaşabilirim?",
            "shortDescription": "Kart bilgilerin sana özeldir; bu bilgileri ve şifreni AHL Pay çalışanları da dahil kimseyle paylaşma. AHL Pay çalışanları, senden telefon/SMS/E-posta gibi kanallardan kart bilgilerini asla talep etmez.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Who can I share my card information with?",
            "shortDescription": "Your card information is private; do not share it with anyone, including AHL Pay employees. AHL Pay employees will never ask for your card information through phone/SMS/email.",
            "categoryId": 4,
            "languageCode": "en"
        },

        {
            "title": "AHL Card'ım ATM'de kaldı, nasıl geri alabilirim?",
            "shortDescription": "AHL Card'ın bir banka şubesinin ATM'inde kaldıysa, görevlilerden yardım isteyerek kartını alabilirsin. Eğer AHL Card’ın şubesi olmayan bir ATM’de kaldıysa, yeni kart başvurusunda bulunman gerekir. Yeni AHL Card'ın için, AHL Pay uygulaması üzerinden dilediğin kartı seçerek yeni kart başvurusunda bulunabilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "My card got stuck in the ATM; how can I retrieve it?",
            "shortDescription": "If your AHL Card got stuck in an ATM at a bank branch, you can ask the staff for help to retrieve it. If your AHL Card is stuck in an ATM without a branch, you will need to apply for a new card. You can select your desired card option and apply for a new AHL Card through the AHL Pay app.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card’ımı dondurabilir miyim?",
            "shortDescription": "AHL Card'ını dilediğin kadar kullanıma açıp kapatabilirsin. Uygulama üzerinden 'AHL Card' sekmesinden kartını seç, 'Kartı Dondur' butonuna tıkla, kartını geçici olarak kapat. Kartın kullanıma kapandığında, karttan harcama yapılamaz. AHL Card'ını tekrar açtığında, kart numarası, son kullanım tarihi ve CVV bilgileri değişmez.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "Can I freeze my AHL Card?",
            "shortDescription": "You can open and close your AHL Card as many times as you like. Select your card from the AHL Card section in the app and click the ‘Freeze Card’ button to temporarily disable it. When your card is disabled, no transactions can be made. When you reopen your card, your card number, expiration date, and CVV information remain unchanged.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "AHL Card şifremi nasıl değiştirebilirim?",
            "shortDescription": "AHL Card'ının şifresini değiştirmek için uygulamaya giriş yap ve 'AHL Card' menüsü altından şifresini değiştirmek istediğin kartını seç. Kart Ayarları>Şifre Değiştir ekranına giderek yeni bir şifre belirleyebilirsin.",
            "categoryId": 4,
            "languageCode": "tr"
        },
        {
            "title": "How can I change my AHL Card password?",
            "shortDescription": "To change the password of your AHL Card, log into the app and select the card whose password you want to change from the AHL Card menu. Then, go to Card Settings > ‘Change Password’ screen to set a new password.",
            "categoryId": 4,
            "languageCode": "en"
        },
        {
            "title": "Kampanyalara katılım ücretli mi?",
            "shortDescription": "Kampanyaya katılım tamamen ücretsizdir.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "Is there a fee to participate in campaigns?",
            "shortDescription": "Participation in the campaigns is completely free of charge.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Kampanyalara nasıl katılırım?",
            "shortDescription": "Kampanyaya katılmak için AHL Pay uygulamasını indirmen ve kimlik doğrulama aşamasını geçmen gerekiyor. Anasayfadaki kampanyalar ekranına tıklayarak ilgilendiğin kampanyanın katılım şartlarını ve detaylarını görebilirsin.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "How can I participate in the campaigns?",
            "shortDescription": "To participate in the campaigns, you need to use the AHL Pay wallet and complete the identity verification process. You can click on the campaigns section on the homepage and read the participation requirements and details of the campaign you are interested in.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Kodum geçersiz diyor, ne yapmalıyım?",
            "shortDescription": "Kodun geçersiz olması veya gelmemesi durumunda 444 90 66 numaralı iletişim merkezi veya destek@ahlpay.com.tr adresinden bize ulaşabilirsin.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "My code says it’s invalid, what should I do?",
            "shortDescription": "If your code is invalid or you haven't received it, you can reach us at 444 90 66 or destek@ahlpay.com.tr.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Kampanyaların süresi ne kadar? Kaç defa katılabilirim?",
            "shortDescription": "Detaylı bilgileri ilgili kampanyanın açıklama kısmında bulabilirsin.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "How long do the campaigns last? How many times can I participate?",
            "shortDescription": "You can find detailed information in the description section of the respective campaign.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Kampanyalara katılım şartı var mıdır?",
            "shortDescription": "Kampanyalardan faydalanabilmek için kimlik doğrulama aşamalarını geçmen gerekiyor. Kampanyalara katılma şartlarından biri de son 10 gün içinde AHL Pay hesabın ile en az bir finansal işlem yapmış olman.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "Are there any conditions for participating in the campaigns?",
            "shortDescription": "To benefit from the campaigns, you need to have completed the identity verification process. Another condition for participating in the campaigns is that you must have made at least one financial transaction through the AHL Pay wallet within the last 10 days.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Arkadaşımı nasıl davet ederim?",
            "shortDescription": "Uygulama içerisindeki 'Diğer' sekmesinden davet kodunu arkadaşınla paylaşabilirsin.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "How can I invite my friend?",
            "shortDescription": "You can share your invitation code with your friend through the 'Others' section in the app.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Arkadaşımı Davet ettim 50 TL gelmedi?",
            "shortDescription": "Arkadaşının Onaylı hesaba geçmesi durumunda, hem sana hem de arkadaşına 50 TL nakit hediye verilir.",
            "categoryId": 5,
            "languageCode": "tr"
        },
        {
            "title": "I invited my friend but didn’t receive the 50 TL, what should I do?",
            "shortDescription": "Once your friend upgrades to an approved account, both you and your friend will receive a 50 TL cashback.",
            "categoryId": 5,
            "languageCode": "en"
        },
        {
            "title": "Faturalarımı nasıl ödeyebilirim?",
            "shortDescription": "AHL Pay Hesabındaki 'Ödemeler > Fatura Ödeme' adımlarını takip ederek işlemini gerçekleştirebilirsin. Hesabında bakiye bulundurman gerekiyor.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "How can I pay my bills?",
            "shortDescription": "You can complete your payment by following the steps 'Payments > Bill Payment' within the AHL Pay Wallet. You need to have a balance in your wallet.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "Hangi faturalarımı ödeyebilirim?",
            "shortDescription": "Doğalgaz, Elektrik, Su, Cep Telefonu ve Telekomünikasyon faturalarını ödeyebilirsin. Detaylı bilgi için AHL Pay hesabındaki 'Ödemeler' ekranını ziyaret edebilirsin.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "Which bills can I pay?",
            "shortDescription": "You can pay bills for Natural Gas, Electricity, Water, Mobile Phones, and Telecommunications. For more detailed information, you can visit the 'Payments' screen within the AHL Pay wallet.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "Fatura ödemeleri ücretli midir?",
            "shortDescription": "Evet. AHL Pay uygulaması üzerinden ödediğin faturalardan 0,50 TL ücret alınır.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "Are bill payments charged?",
            "shortDescription": "No, you can pay your bills for free through the AHL Pay wallet.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "Fatura ödemelerim ilgili kuruma ne zaman yansır?",
            "shortDescription": "Fatura ödemelerin ilgili kuruma anında yansır.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "When will my bill payments reflect to the respective institution?",
            "shortDescription": "Your bill payments are reflected to the respective institution immediately.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "Ödemesini gerçekleştirdiğim faturamı iptal edebilir miyim?",
            "shortDescription": "Yalnızca ödeme kanalı olarak hizmet veriyoruz, iptal veya iade için ödeme yaptığın kurum ile irtibata geçebilirsin.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "Can I cancel a bill payment I have made?",
            "shortDescription": "We only act as a payment channel and cannot provide cancellation or refund options. For such cases, you may contact the institution to which you made the payment.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "Başkası adına düzenlenen faturayı ödeyebilir miyim?",
            "shortDescription": "Evet, başkasına ait faturayı ödeyebilirsin.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "Can I pay bills issued in someone else's name?",
            "shortDescription": "Yes, you can pay bills issued in someone else's name.",
            "categoryId": 6,
            "languageCode": "en"
        },
        {
            "title": "How can I view the receipt for the bill I paid?",
            "shortDescription": "You can view the receipt generated from the transaction on the Wallet History screen.",
            "categoryId": 6,
            "languageCode": "en"
        },

        {
            "title": "Ödediğim faturanın dekontunu nasıl görebilirim?",
            "shortDescription": "İşlem sonucunda oluşan dekontu, Hesap Geçmişi ekranından görebilirsin.",
            "categoryId": 6,
            "languageCode": "tr"
        },
        {
            "title": "How can I view the receipt for the bill I paid?",
            "shortDescription": "You can view the receipt generated from the transaction on the Wallet History screen.",
            "categoryId": 6,
            "languageCode": "en"
        },
        
        {
            "title": "AHL Pay uygulamasında SMS gelmiyor, ne yapmalıyım?",
            "shortDescription": "Öncelikli olarak spam ve engellenen mesajlarını kontrol etmelisin. SMS gelmediyse 444 90 66 numaralı iletişim merkezimize veya destek@ahlpay.com.tr adresinden bize ulaşabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "I am not receiving SMS messages in the AHL Pay Wallet, what should I do?",
            "shortDescription": "First, check your spam and blocked messages. If you still don’t receive SMS, you can contact us at 444 90 66 or destek@ahlpay.com.tr.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay Hesap güvenlik sorusunun cevabını unuttum, şifremi yenileyemiyorum, ne yapmalıyım?",
            "shortDescription": "444 90 66 numaralı iletişim merkezimizden veya destek@ahlpay.com.tr adresinden bize ulaşabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "I forgot the answer to my AHL Pay Wallet security question and cannot reset my password, what should I do?",
            "shortDescription": "You can contact us at 444 90 66 or destek@ahlpay.com.tr for assistance.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay ile 7/24 işlem yapabilir miyim?",
            "shortDescription": "AHL Pay Hesabın ile 7/24 işlemlerini gerçekleştirebilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "Can I perform transactions 7/24 with AHL Pay?",
            "shortDescription": "Yes, you can carry out transactions 7/24 with the AHL Pay Wallet.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "Para istediğim kişinin cep telefon numarasını bilmiyorum, ne yapmalıyım?",
            "shortDescription": "Rehberi bağlama özelliği ile, Para İste ekranında telefon rehberin otomatik olarak gelir. Para istediğin kişiyi kolayca seçebilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "I don’t know the phone number of the person I want to request money from, what should I do?",
            "shortDescription": "On the Request Money screen, you can use the contacts linking feature to automatically display your phone contacts on the screen, allowing you to easily select the person you want to request money from.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay hesabıma yatırdığım para ne zaman hesabıma geçer?",
            "shortDescription": "İşlem yoğunluğuna bağlı olarak 15-20 dakika içinde paran hesabına geçecektir.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "When will the money I deposited into my AHL Pay Wallet be credited to my account?",
            "shortDescription": "Depending on transaction volume, your money will be credited to your account within 15-20 minutes.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay hesabıma para yükleme işlemlerinde param hangi durumlarda iade edilir?",
            "shortDescription": "Banka hesabından yükleme yaparken, açıklama kısmına AHL Pay hesap numaranı eksik ya da hatalı yazman durumunda paran iade edilir.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "Under what circumstances will my money be refunded during the AHL Pay Wallet top-up process?",
            "shortDescription": "If you enter your AHL Pay Wallet number incorrectly or incompletely in the description field when topping up from your bank account, your money will be refunded.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay Hesabıma yüklediğim para hesabıma geçmedi, ne yapmalıyım?",
            "shortDescription": "AHL Pay ile para yükleme adımlarını doğru yaptıysan ve belirtilen süre içinde paran hesaba geçmediyse 444 90 66 ya da destek@ahlpay.com.tr iletişim kanalları üzerinden bize ulaşabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "The money I loaded into my AHL Pay Wallet did not appear in my account, what should I do?",
            "shortDescription": "If you are sure that you followed the correct steps for loading money into your AHL Pay Wallet and your money did not appear in the account within the specified time, you can reach us at 444 90 66 or destek@ahlpay.com.tr.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "Para Transferi yaparken açıklama kısmına AHL Pay Hesap numaramı yazmayı unuttum. Ne yapmalıyım, param kaybolur mu?",
            "shortDescription": "AHL Pay ile paran her zaman güvende. Eksik ya da hatalı girilen AHL Pay müşteri numarası ile yapılan transferler, ekiplerimiz tarafından incelenir. İnceleme sonrası paran banka hesabına iade edilir. Detaylı bilgi almak için, 444 90 66 numaralı iletişim merkezimizden veya destek@ahlpay.com.tr adresinden bize ulaşabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "I forgot to write my AHL Pay Wallet number in the description field when making a money transfer. What should I do? Will my money be lost?",
            "shortDescription": "Your money is always safe with AHL Pay. Transfers made with an incorrect or missing AHL Pay customer number are reviewed by our teams. Once the review is complete, your money will be refunded to your bank account. For more detailed information, you can call our Call Center at 444 90 66 or contact us via destek@ahlpay.com.tr.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "FAST nedir?",
            "shortDescription": "FAST (Fonların Anlık ve Sürekli Transferi), Türkiye Cumhuriyet Merkez Bankası tarafından geliştirilen ve 7/24 anında para transferi yapmanıza olanak tanıyan bir ödeme sistemidir. FAST ile bankalararası para transferleri saniyeler içinde gerçekleşir.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "What is FAST?",
            "shortDescription": "FAST (Instant and Continuous Transfer System of Funds) is a payment system developed by the Central Bank of the Republic of Turkey that allows instant money transfers 24/7. With FAST, money transfers between different banks occur within seconds.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "FAST ile para gönderme limitim nedir?",
            "shortDescription": "FAST gönderim limiti T.C. Merkez Bankası tarafından tek seferde maksimum 100.000 TL olarak belirlenmiştir.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "What is the limit for sending money via FAST?",
            "shortDescription": "The FAST sending limit is set by the Central Bank of the Republic of Turkey at a maximum of 100,000 TL per transaction.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay'de IBAN oluşmakta mıdır?",
            "shortDescription": "Evet, AHL Pay FAST işleticisi olduğu için kullanıcılar IBAN oluşturabilir. AHL Pay hesaplarından kolayca para transferi yapabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "Does AHL Pay provide IBANs?",
            "shortDescription": "Yes, as AHL Pay is a FAST operator, we can generate IBANs for our users. This allows you to easily transfer and receive money through your AHL Pay accounts.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay hesabıma nakit para yatırabilir miyim?",
            "shortDescription": "Evet, AHL Pay hesabına ATM üzerinden nakit para yatırabilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "Can I deposit cash into my AHL Pay account?",
            "shortDescription": "No, you can deposit money into your AHL Pay account through Bank Transfer/EFT/FAST.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "Hesabımda şüpheli bir işlem fark edersem ne yapmalıyım?",
            "shortDescription": "Hesabında şüpheli bir işlem fark ettiğinde 444 90 66 numaralı çağrı merkezimizi arayarak durumu bildirebilirsin. Müşteri temsilcilerimiz, güvenliğin için gerekli önlemleri alacak ve hesap güvenliğini sağlamak için yardımcı olacaktır.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "What should I do if I notice a suspicious transaction on my account?",
            "shortDescription": "If you notice a suspicious transaction on your account, you can report it by calling our Call Center at 444 90 66. Our customer representatives will take necessary measures to ensure your security and assist you with account safety.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay Hesabı ile QR kod ile ödeme yapabilir miyim?",
            "shortDescription": "QR kod ile ödeme seçeneği çok yakında eklenecek. Güncellemeler ve duyurular için bizi takip etmeye devam et.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "Can I make payments using QR codes with AHL Pay Wallet?",
            "shortDescription": "The QR code payment option will be added very soon. Please continue to follow us for updates and announcements.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay Hesap'ta yeni özellikler ne sıklıkla eklenir?",
            "shortDescription": "AHL Pay, kullanıcılarına en iyi deneyimi sunmak için sürekli olarak yeni özellikler ekler ve mevcut özellikleri iyileştirir. Yeni güncellemeler ve özellikler hakkında bilgi almak için uygulama içi bildirimleri ve AHL Pay'in resmi web sitesini takip edebilirsin.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
            "title": "How frequently are new features added to AHL Pay Wallet?",
            "shortDescription": "AHL Pay continuously adds new features and improves existing ones to provide the best experience for users. For information on new updates and features, you can check in-app notifications and AHL Pay's official website.",
            "categoryId": 7,
            "languageCode": "en"
        },
        {
            "title": "AHL Pay hesabım hakkında geri bildirimde bulunmak istersem ne yapmalıyım?",
            "shortDescription": "AHL Pay hesabın hakkında geri bildirimde bulunmak istersen, 444 90 66 numaralı çağrı merkezimizi arayarak görüşlerini iletebilirsin. Müşteri temsilcilerimiz, geri bildiriminizi dikkate alarak hizmet kalitemizi artırmak için gerekli değerlendirmeleri yapacaktır. Geri bildirimin bizim için değerlidir.",
            "categoryId": 7,
            "languageCode": "tr"
        },
        {
        "title": "How can I provide feedback about AHL Pay Wallet?",
        "shortDescription": "If you would like to provide feedback about the AHL Pay Wallet, you can call our Call Center at 444 90 66 to share your thoughts. Our customer representatives will consider your feedback to enhance our service quality. Your feedback is valuable to us.",
        "categoryId": 7,
        "languageCode": "en"
    },
    {
        "title": "How can I provide feedback about AHL Pay Wallet?",
        "shortDescription": "If you would like to provide feedback about the AHL Pay Wallet, you can call our Call Center at 444 90 66 to share your thoughts. Our customer representatives will consider your feedback to enhance our service quality. Your feedback is valuable to us.",
        "categoryId": 7,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay ile para gönderme işlemi ücretsiz midir?",
        "shortDescription": "AHL Pay hesabından, kendi banka hesabına 7/24 ücretsiz para gönderebilirsin.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "Is sending money using AHL Pay Wallet free of charge?",
        "shortDescription": "Yes, you can send money from your AHL Pay Wallet to your own bank account 24/7 for free.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabın ile başkasına ait Banka Hesabına nasıl para gönderebilirim?",
        "shortDescription": "AHL PAY hesabından sadece kendi banka hesabına para transferi yapabilirsin.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How can I send money to someone else’s bank account using AHL Pay Wallet?",
        "shortDescription": "With AHL Pay Wallet, you can only transfer money to your own bank account. Transfers to other bank accounts are not supported.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabım ile nasıl para gönderebilirim?",
        "shortDescription": "AHL Pay hesabından tüm AHL Pay üyelerine ve kendi banka hesabına para gönderebilirsin.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How can I send money using AHL Pay Wallet?",
        "shortDescription": "You can send money to all AHL Pay Wallet members and to your own bank account from your AHL Pay Wallet.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabı ile diğer AHL Pay kullanıcılarına nasıl para gönderebilirim?",
        "shortDescription": "AHL Pay hesabından tüm AHL Pay mobil üyelerine telefon numarası ya da hesap numarasını girerek ücretsiz bir şekilde para gönderebilirsin.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How can I send money to other AHL Pay Wallet users?",
        "shortDescription": "You can send money for free to other AHL Pay Wallet users by entering their phone number or wallet number in the app.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesap ile Kendi Banka Hesabıma gönderdiğim para transfer işlemi ne kadar sürede gerçekleşir?",
        "shortDescription": "AHL Pay Hesap ile kendi banka hesabına gönderdiğin paranın geçiş süresi, işlem yoğunluğuna ya da bankaya göre değişebilir.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How long does it take for a transfer to my own bank account using AHL Pay Wallet to be completed?",
        "shortDescription": "The transfer time to your own bank account may vary based on transaction volume or the bank’s processing time.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesap ile başka bir AHL Pay kullanıcısına gönderdiğim para transferi işlemi ne kadar sürede gerçekleşir?",
        "shortDescription": "AHL Pay ile gönderdiğin para anında AHL Pay kullanıcısına ulaşır.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How long does it take for a transfer to another AHL Pay Wallet user to be completed?",
        "shortDescription": "Money sent to another AHL Pay Wallet user is delivered instantly.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay ile Kendi Banka Hesabıma para gönderme limitlerim nedir?",
        "shortDescription": "AHL Pay Hesabım > Profil Ayarları > Ücretler ve Limitler tablosundan bu bilgilere ulaşabilirsin.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "What are the limits for sending money to my bank account using AHL Pay Wallet?",
        "shortDescription": "You can find this information in the 'Account Settings > Fees and Limits' section of your AHL Pay Wallet.",
        "categoryId": 8,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabım ile Banka hesabına nasıl para gönderebilirim?",
        "shortDescription": "AHL Pay hesap bakiyeni kendi banka hesaplarına gönderebilirsin. Banka hesabına bakiye göndermek için 'Transferler > Para Gönder > Kendi Banka Hesabına Para Gönder' adımlarını takip ederek para göndermek istediğin bankayı seç, IBAN numarasını ve tutarı yaz, transfer işlemini tamamla.",
        "categoryId": 8,
        "languageCode": "tr"
    },
    {
        "title": "How can I transfer money to a bank account using AHL Pay Wallet?",
        "shortDescription": "You can transfer your AHL Pay Wallet balance to your own bank accounts. To do this, follow these steps: 'Transfers > Send Money > Transfer to My Bank Account', select the bank, enter the IBAN number and amount, and complete the transfer.",
        "categoryId": 8,
        "languageCode": "en"
    },

    {
        "title": "I don’t know the phone number of the person I want to request money from. What should I do?",
        "shortDescription": "With the contact sync feature, your phone contacts will automatically appear on the Money Request screen. You can easily select the person you want to request money from.",
        "categoryId": 8,
        "languageCode": "en"
    },    
    {
        "title": "How can I transfer money to a bank account using AHL Pay Wallet?",
        "shortDescription": "You can transfer your AHL Pay Wallet balance to your own bank accounts. To do this, follow these steps: 'Transfers > Send Money > Transfer to My Bank Account', select the bank, enter the IBAN number and amount, and complete the transfer.",
        "categoryId": 8,
        "languageCode": "en"
    },

    {
        "title": "AHL Pay hesabıma Para İsteme özelliği nedir?",
        "shortDescription": "AHL Pay hesabın üzerinden AHL Pay hesabı olan sevdiklerinden yalnızca cep telefon numarasını girerek para isteyebilirsin.",
        "categoryId": 9,
        "languageCode": "tr"
    },
    {
        "title": "What is the Money Request feature in AHL Pay Wallet?",
        "shortDescription": "The Money Request feature allows you to request money from your AHL Pay Wallet contacts by simply entering their phone number.",
        "categoryId": 9,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabı ile nasıl para isterim?",
        "shortDescription": "AHL Pay Hesap > Transferler > Para İste ekranına tıklayarak para isteyeceğin AHL Pay müşterisinin cep telefon numarasını ve isteyeceğin para tutarını yazman yeterli olacaktır.",
        "categoryId": 9,
        "languageCode": "tr"
    },
    {
        "title": "How do I request money using AHL Pay Wallet?",
        "shortDescription": "Go to AHL Pay Wallet > Transfers > Money Request, then enter the phone number of the AHL Pay user you want to request money from and the amount you wish to request.",
        "categoryId": 9,
        "languageCode": "en"
    },
    {
        "title": "Para İste adımında AHL Pay Hesabı bulunan müşteri numarasını girerek Para isteme işlemini gerçekleştirebilir miyim?",
        "shortDescription": "Kullanıcının AHL Pay giriş yaptıktan sonra Transferler > Para İsteme adımında sadece kullanıcının telefon numarasını girerek veya rehberden seçerek gerçekleştirebilirsin.",
        "categoryId": 9,
        "languageCode": "tr"
    },
    {
        "title": "Can I request money by entering the AHL Pay Wallet user's customer number?",
        "shortDescription": "No, you should enter only the phone number of the user or select it from your contacts list when making a request in AHL Pay Wallet.",
        "categoryId": 9,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabımdan para isteme işlemi yaptığımda işlemin gerçekleşip, gerçekleşmediğini nasıl anlarım?",
        "shortDescription": "Transferler > Para İste > Para İsteme Talepleri adımından tüm Giden Para İsteklerini ve Gelen Para İsteklerini kontrol edebilirsin. Para istediğin kullanıcı transferi onaylarsa işlem bu ekrana yansımaz. Hesap Geçmişi ekranından işlem ve dekontu görüntüleyebilirsin.",
        "categoryId": 9,
        "languageCode": "tr"
    },
    {
        "title": "How can I know if my money request has been processed?",
        "shortDescription": "You can check the status of your money request by navigating to 'Transfers > Money Request > Money Request Requests'. If the recipient approves the request, it will not appear in this list. You can view the transaction and receipt in the Wallet History screen.",
        "categoryId": 9,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabıma gelen para isteklerini nereden görüntüleyebilirim?",
        "shortDescription": "Transferler > Para İste > Para İsteme Talepleri ekranına giriş yaparak hesabına gelen tüm para isteklerini buradan görüntüleyip onay/red verebilirsin.",
        "categoryId": 9,
        "languageCode": "tr"
    },
    {
        "title": "Where can I view incoming money requests in AHL Pay Wallet?",
        "shortDescription": "You can view all incoming money requests by going to 'Transfers > Money Request > Money Request Requests'. Here, you can review and either approve or reject the requests you receive.",
        "categoryId": 9,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabı nasıl oluşturulur?",
        "shortDescription": "AHL Pay uygulamasını indirip, kişisel bilgilerini ve şifre adımını tamamladıktan sonra başlangıç seviye kullanıcı olarak hesabın açılır. Tüm avantajlardan faydalanmak için 'Hesap Yükselt' adımlarını takip ederek kimlik doğrulaması yapabilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How do I create an AHL Pay Wallet account?",
        "shortDescription": "After downloading the AHL Pay app, complete the required personal information and password steps. Your account will be created with basic user status. To enjoy all the benefits of AHL Pay, complete the identity verification steps under 'Upgrade Account' to become a Verified user.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabı açmak ücretsiz midir?",
        "shortDescription": "AHL Pay hesap açılımı tamamen ücretsizdir.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "Is creating an AHL Pay Wallet account free?",
        "shortDescription": "Yes, opening an AHL Pay Wallet account is completely free.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabımın şifresini unuttum, ne yapmalıyım?",
        "shortDescription": "AHL Pay mobil uygulamasından 'Şifremi Unuttum' butonuna tıklayarak yeni şifre alabilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "What should I do if I forget my AHL Pay Wallet password?",
        "shortDescription": "You can obtain a new password by clicking the 'Forgot Password' button in the AHL Pay Wallet mobile app.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay şifremi nasıl değiştirebilirim?",
        "shortDescription": "AHL Pay > Hesabım > Profil Ayarlarım > Şifre Güncelleme sayfasından mevcut şifreni ve yeni şifreni girerek şifreni değiştirebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How can I change my AHL Pay password?",
        "shortDescription": "Go to AHL Pay Wallet > My Account > Profile Settings > Password Update. Enter your current password and new password to change your password.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabımı nasıl kapatabilirim?",
        "shortDescription": "444 90 66 numaralı telefon numarasından veya destek@ahlpay.com.tr üzerinden bizimle iletişime geçebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How can I close my AHL Pay Wallet account?",
        "shortDescription": "Contact us via phone at 444 90 66 or email at destek@ahlpay.com.tr to close your account.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabıma banka hesabımı nasıl ekleyebilirim?",
        "shortDescription": "AHL Pay > Transferler > Banka Hesabına Para Gönder > Banka Hesabı Ekle adımlarını takip ederek banka hesabını ekleyebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How do I add my bank account to AHL Pay Wallet?",
        "shortDescription": "Navigate to AHL Pay Wallet > Transfers > Send Money to Bank Account > Add Bank Account to add your bank account.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabıma para yükleme limitlerim nedir?",
        "shortDescription": "AHL Pay Hesabım > Profil Ayarları > Ücretler ve Limitler tablosundan bu bilgilere ulaşabilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "What are my limits for adding money to my AHL Pay Wallet account?",
        "shortDescription": "You can find this information in AHL Pay Wallet > My Account > Profile Settings > Fees and Limits.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabımı nasıl yükseltebilirim?",
        "shortDescription": "AHL Pay kaydını oluşturduktan sonra, 'Hesap Yükselt' adımlarından kimlik ve yüz doğrulama işlemlerini tamamladıktan sonra hesabını yükseltebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How can I upgrade my AHL Pay Wallet account?",
        "shortDescription": "After creating your AHL Pay Wallet account, follow the 'Upgrade Account' steps to complete identity and facial verification. Your account will be upgraded afterwards.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "Hesabımı doğrulayamıyorum, ne yapmalıyım?",
        "shortDescription": "444 90 66 numaralı telefon numarasından veya destek@ahlpay.com.tr üzerinden bizimle iletişime geçebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "What should I do if I cannot verify my account?",
        "shortDescription": "Contact us via phone at 444 90 66 or email at destek@ahlpay.com.tr for assistance with account verification issues.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabıma nereden para yükleyebilirim?",
        "shortDescription": "AHL Pay hesabına şu an için sadece banka hesabından 'Yükle' seçeneği ile para yükleyebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "Where can I add money to my AHL Pay Wallet?",
        "shortDescription": "Currently, you can only add money to your AHL Pay Wallet from your bank account using the 'Load' option.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "Üyelik için yaş sınırı var mıdır?",
        "shortDescription": "8 yaş ve üzerindeki Türkiye Cumhuriyeti vatandaşları AHL Pay hesabı açabilir.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "Is there an age limit for membership?",
        "shortDescription": "Individuals who are 8 years old or older and are citizens of Turkey can open an AHL Pay Wallet account.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "E-posta adresimi nasıl değiştirebilirim?",
        "shortDescription": "AHL Pay > Hesabım > Profil Ayarlarım > E-posta Güncelleme sayfasından yeni e-posta adresini yazarak değiştirebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "How can I change my email address?",
        "shortDescription": "Go to AHL Pay Wallet > My Account > Profile Settings > Update Email to change your email address.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay Hesabım ile yaptığım tüm harcamaları görebiliyor muyum?",
        "shortDescription": "AHL Pay uygulamasına giriş yaptıktan sonra anasayfadaki Hesap Hareketleri başlığı altında tüm hesap hareketlerini görüntüleyebilirsin.",
        "categoryId": 10,
        "languageCode": "tr"
    },
    {
        "title": "Can I view all my expenses made with AHL Pay Wallet?",
        "shortDescription": "After logging into the AHL Pay Wallet app, you can view all your account activities under the 'Account Activities' section on the homepage.",
        "categoryId": 10,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay uygulaması nasıl indirilir?",
        "shortDescription": "Google Play ve App Store üzerinden AHL Pay mobil uygulamasını indirebilirsin.",
        "categoryId": 11,
        "languageCode": "tr"
    },
    {
        "title": "How do I download the AHL Pay app?",
        "shortDescription": "You can download the AHL Pay mobile app from Google Play and the App Store.",
        "categoryId": 11,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay nedir?",
        "shortDescription": "AHL Pay, kullanıcıların dijital ortamda para transferi yapmalarını, ödemelerini yönetmelerini ve diğer finansal işlemleri gerçekleştirmelerini sağlayan bir elektronik cüzdan hizmetidir.",
        "categoryId": 11,
        "languageCode": "tr"
    },
    {
        "title": "What is AHL Pay Wallet?",
        "shortDescription": "AHL Pay Wallet is an electronic wallet service that allows users to perform digital money transfers, manage payments, and conduct other financial transactions.",
        "categoryId": 11,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay girmiş olduğum bilgileri saklıyor mu?",
        "shortDescription": "AHL Pay müşterisi olduğun andan itibaren 10 yıl süre ile denetime hazır bulundurulacak şekilde saklamakla yükümlüdür.",
        "categoryId": 12,
        "languageCode": "tr"
    },
    {
        "title": "Does AHL Pay Wallet store the information I enter?",
        "shortDescription": "Yes, AHL Pay Wallet is required to keep the information you provide for a period of 10 years, as it must be available for auditing purposes.",
        "categoryId": 12,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabı oluşturmak için kimlik bilgilerim neden gereklidir?",
        "shortDescription": "5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi kanunu kapsamında, hesabın gerçekten sana ait olduğunu doğrulamamız gerekiyor. Bu sebeple kimlik bilgileri gereklidir.",
        "categoryId": 12,
        "languageCode": "tr"
    },
    {
        "title": "Why is my identity information needed to create an AHL Pay Wallet account?",
        "shortDescription": "In accordance with the Law No. 5549 on Prevention of Laundering Proceeds of Crime, we need to verify that the account truly belongs to you. Therefore, your identity information is required.",
        "categoryId": 12,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay ile param güvende mi?",
        "shortDescription": "AHL Pay Merkez Bankası tarafından lisanslı bir ödeme ve elektronik para kuruluşudur. Yapacağın tüm işlemler ve paran güvendedir. Merkez Bankası nezdinde açılan koruma hesaplarında tutulur.",
        "categoryId": 12,
        "languageCode": "tr"
    },
    {
        "title": "Is my money safe with AHL Pay?",
        "shortDescription": "Yes, AHL Pay is a licensed payment and electronic money institution regulated by the Central Bank of Turkey. All transactions and your funds are secure and held in protection accounts maintained by the Central Bank.",
        "categoryId": 12,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabı ile vadeli hesap açabilir miyim?",
        "shortDescription": "6493 sayılı kanun gereğince Elektronik Para kuruluşları vadeli hesap işlemleri yapmaz.",
        "categoryId": 12,
        "languageCode": "tr"
    },
    {
        "title": "Can I open a time deposit account with AHL Pay Wallet?",
        "shortDescription": "No, under Law No. 6493, electronic money institutions are not authorized to offer time deposit accounts.",
        "categoryId": 12,
        "languageCode": "en"
    },
    {
        "title": "AHL Pay hesabı ile kredi kullanabilir miyim?",
        "shortDescription": "6493 sayılı kanun gereğince Elektronik Para kuruluşları kredi verme işlemleri yapmaz.",
        "categoryId": 12,
        "languageCode": "tr"
    },
    {
        "title": "Can I get a loan with AHL Pay Wallet?",
        "shortDescription": "No, as per Law No. 6493, electronic money institutions are not permitted to provide loans.",
        "categoryId": 12,
        "languageCode": "en"
    }
];

const FAQPage = () => {
    return (
        <MainLayout children={_render()} />
    );
}

const _render = () => {

    const { language } = useLanguage();

    const [activeCategory, setActiveCategory] = useState(categoriesJSON[0].id);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [totalItems, setTotalItems] = useState(faqJSON.filter(x => x.languageCode == language).length);

    useEffect(() => {
        setTotalItems(activeCategory == 1 ? faqJSON.filter(x => x.languageCode == language).length : faqJSON.filter(x => x.categoryId == activeCategory && x.languageCode == language).length);
        setCurrentPage(1);
    }, [activeCategory, language])

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const [openModal, setOpenModal] = useState(false);

    return (<main page="inside">
        <ContactModal openModal={openModal} setOpenModal={setOpenModal} />
        <section element="pageheader">
            <div>
                <div>
                    {baslik.baslik1.find((x) => x.languageCode === language)?.title}
                    {baslik.baslik2.find((x) => x.languageCode === language)?.title}
                </div>
                <div><img src="assets/uploads/pageheader/sss.png" alt="Sıkça Sorulan Sorular" /></div>
            </div>
        </section>
        <section element="faq">
            <div>

                <ul tabs="">
                    {_renderCategories(activeCategory, setActiveCategory, language)}
                </ul>

                <content tabdetails="">

                    {_renderTabs(activeCategory, pageSize, currentPage, language)}
                    {Math.round((activeCategory == 1 ? totalItems : faqJSON.filter(x => x.categoryId == activeCategory && x.languageCode == language).length) / pageSize) >= 2 && (<Pagination
                        totalItems={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />)}
                </content>

            </div>
        </section>
        <div element="redirect1">
            <div>
                {baslik.baslik4.find((x) => x.languageCode === language)?.title}

                <a onClick={() => setOpenModal(true)} modal-open="application" title="İletişim Formu">{baslik.baslik5.find((x) => x.languageCode === language)?.title}</a>
            </div>
        </div>

    </main>);
}

const _renderCategories = (activeCategory, setActiveCategory, langauge) => {

    return (categoriesJSON.filter(x => x.languageCode == langauge).map((item) => {
        var isActive = activeCategory == item.id ? 'active' : '';

        return <li tab-id={`tab-${item.id}`} onClick={() => { setActiveCategory(item.id) }} active={activeCategory == item.id ? 'true' : null}>{item.title}</li>
    }));                                                                                             
}

const _renderTabs = (activeCategory, pageSize, currentPage, language) => {
    const [items, setItems] = useState(faqJSON);

    useEffect(() => {

        setItems(faqJSON.filter(x => (activeCategory == 1 ? true : x.categoryId == activeCategory) && x.languageCode == language).skip((currentPage - 1) * 5).take(5));

    }, [currentPage, activeCategory, language])

    return categoriesJSON.filter(x => x.languageCode == language).map((item) => {

        return <><div tab-detail={`tab-${item.id}`} status={item.id == activeCategory ? 'on' : 'off'}>

            <grid grid="faq">

                {items.map((faqItem) => {
                    return <div grid-item="">
                        <h4>{faqItem.title}</h4>
                        <div>{faqItem.shortDescription}</div>
                    </div>
                })}

            </grid>
        </div>
        </>
    });
}

export default FAQPage;