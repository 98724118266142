// @ts-nocheck
import React from 'react';
import { PaginationProps } from '../models/PaginationProps';

const Pagination: React.FC<PaginationProps> = ({ totalItems, pageSize, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    const generatePageNumbers = () => {
        const pages: number[] = [];

        pages.push(1);

        if (currentPage > 4) {
            pages.push(-1); // Ellipsis for pages between 1 and currentPage - 2
        }

        // Generate pages around the current page
        const startPage = Math.max(2, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // If the current page is less than totalPages - 1, add ellipsis
        if (currentPage < totalPages - 2) {
            pages.push(-1); // Ellipsis for pages between currentPage + 2 and totalPages - 1
        }

        // Always show the last page if it's not already included
        if (currentPage <= totalPages - 3) {
            pages.push(totalPages);
        }

        return pages;
    };

    const pages = generatePageNumbers();

    return (
        <div grid-pages="">
            <a
                href="#"
                title="Previous"
                onClick={(e) => {
                    e.preventDefault();
                    if (currentPage > 1) onPageChange(currentPage - 1);
                }}
            >
                <i icon="mat-symbol sm"
                    onClick={(e) => {
                        e.preventDefault();
                        if (currentPage > 1) onPageChange(currentPage - 1);
                    }}>navigate_before</i>
            </a>
            <ul>
                {pages.map((page, index) => (
                    <li key={index} active={page === currentPage ? 'true' : undefined}>
                        {page === -1 ? (
                            <span>...</span>
                        ) : (
                            <a
                                href="#"
                                title={`Page ${page}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onPageChange(page);
                                }}
                            >
                                {page}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
            <a
                href="#"
                title="Next"
                onClick={(e) => {
                    e.preventDefault();
                    if (currentPage < totalPages) onPageChange(currentPage + 1);
                }}
            >
                <i icon="mat-symbol sm"
                    onClick={(e) => {
                        e.preventDefault();
                        if (currentPage < totalPages) onPageChange(currentPage + 1);
                    }}
                >navigate_next</i>
            </a>
        </div>
    );
};

export default Pagination;