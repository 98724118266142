// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'AHL Pay Kurumsal Hesap',
        headerTitle: 'Kurumsal Hesap',
        title: "AHL Pay Kurumsal Hesap",
        shortDescription: 'AHL Pay Kurumsal Hesap ile, işletme harcamalarını daha etkin bir şekilde yönetebilir, ödemelerinizi hızlı bir şekilde gerçekleştirebilir ve nakit akışını optimize edebilirsin. Ayrıca, işletmene özel raporlama ve analiz araçları sayesinde mali durumunu detaylı bir şekilde izleme fırsatı bulursun.',
        description: 'İşletmenin finansal süreçlerini etkin bir şekilde yönet. Hızlı ve kolay işlemlerle, ödemelerini zamanında gerçekleştir. Gelişmiş güvenlik önlemleri sayesinde finansal bilgilerini koru.',
        imagePath: 'assets/uploads/products/phones/kurumsalhesap.png',
        languageCode: 'en',
        title2: <><span>Kurumsal Hesap </span>Avantajları</>,
        childrens: [
            {
                title: 'Güvenli İşlemler',
                desc: 'Yüksek güvenlik standartları ile finansal bilgilerinin korunmasını sağla, dolandırıcılık riskini azalt.'
            },
            {
                title: 'Finansal Yönetim Kolaylığı',
                desc: 'Tüm ticari işlemlerini tek bir hesapta yöneterek, mali süreçlerini daha basit ve düzenli hale getir.'
            },
            {
                title: 'Raporlama',
                desc: 'Detaylı raporlama ile nakit akışını ve harcamalarını etkili bir şekilde takip et.'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
