// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'ParaIste',
        headerTitle: 'Para İste',
        title: "AHL Pay ile Para İste",
        shortDescription: 'İhtiyaç anında hızlı ve kolayca para talep et, talebin onaylanır onaylanmaz paran hesabına geçsin. AHL Pay, Para İste özelliği ile, ihtiyaç duyduğunuz anda size hızlı ve pratik bir çözüm sunar.',
        description: "Transferler ve ardından Para iste ekranına tıkla, Para İste yöntemini seç (Cüzdan Numarası, Telefon Numarası veya QR ile) ve isteyeceğin para tutarını yaz, talebi oluştur. Karşı taraf talebini onayladıktan sonra, tutar hesabına anında geçsin.",
        imagePath: 'assets/uploads/products/phones/paragonder_phone.svg',
        title2: <>Nasıl<span> Para İsterim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Hesap Numarası ile para iste',
                desc: 'Talep oluşturacağın hesap numarasını yaz, karşı tarafa hemen yansısın.'
            },
            {
                title: 'Telefon Numarası ile para iste',
                desc: ' Para isteme işlemini, kullanıcının telefon numarasını girerek veya rehberinden seçerek kolayca gerçekleştir.'
            },
            {
                title: ' Talebin onaylanır onaylanmaz tutar hesabında',
                desc: ' Karşı taraf talebini onaylar onaylamaz tutar anında hesabına geçsin.'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
