// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'ParaGonder',
        headerTitle: 'Para Gönder',
        title: "AHL Pay ile Para Gönder",
        shortDescription: 'AHL PAY hesabından diğer banka hesaplarına hızlı ve güvenle para transferi yapabilirsin. Ayrıca, AHL Pay hesabından tüm AHL Pay üyelerine telefon/cüzdan numaraları veya dilersen QR kod ile para gönderebilirsin.',
        description: "Banka hesabına para göndermek için uygulama üzerinden Transferler ve ardından Para Gönder'e tıkla. Para göndermek istediğin yöntemi seç ve göndermek istediğin tutarı belirle, tutar anında hesabına geçsin. Ayrıca AHL Pay kullanıcılarına, QR Kod, Cüzdan Numarası, Telefon numarası ve Kart Numarası ile para gönderebilirsin.",
        imagePath: 'assets/uploads/products/phones/odemeiste_phone.svg',
        title2: <>Nasıl<span> Para Gönderirim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Anında Hesabında',
                desc: 'Yapacağın para transferi anında hesabında'
            },
            {
                title: '7/24 Para Transferi',
                desc: ' Hafta içi veya hafta sonu, para transferini dilediğin zaman yap'
            },
            {
                title: 'Hızlı ve Güvenilir',
                desc: 'Hızlı ve güvenli bir şekilde para transferi gerçekleştir'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
