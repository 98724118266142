// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const campaigns = [
  {
    "id": 6,
    "title": "* 17.09.2024 GS - GFK Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – GFK Trendyol Süper Lig Maç Bileti Talihlileri:\n\nASİL \n1- EMRE DURSUN\n2- DİLFERAH AYBİR\n3- YUSUF YAPINCAK\n4- ERKAN KURT\n5- EREN NAMLI\n6- ALİ SARI\n7- ROJHAT ATA\n8- SAVAŞ KARAOĞLAN\n9- SEZER AYDIN\n10- İSMAİL ARAS\n11- CİHAN DOĞAN\n12- ÖMER TUTAR\n13- CEYHUN BAL\n\nYEDEK \n1- BURAK AKAN\n2- ŞEVKİ ATAKAN ACAR\n3- MEHMET SABAZ\n4- ESİN FATMA DAL\n5- ÖZGÜR ESENDAL\n6- TAYYİP AKBULUT\n7- KAAN MERT SEVEN\n8- ERCAN ERCİK",
    "languageCode": "tr",
    "imagePath": "assets/uploads/products/icons/gsgfk.png"
  }
]

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(6);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }

  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.imagePath} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;