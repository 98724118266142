// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        pageTitle: 'GuvenceBedeli',
        headerTitle: 'Güvence Bedeli',
        title: "Güvence Bedel İadesi Hesabınızda",
        shortDescription: 'Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin.',
        description: "Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin. Güvence Bedeli İadesi Süreci: En yakın Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz , Marmargaz Yalova ve Marmaragaz Çorlu şubesine giderek abonelik sözleşmenin feshi için başvur. Cep telefonuna AHL Pay uygulamasını indir, kolayca hesap oluştur ve hesabını Onaylı statüne getir. Onaylı müşteri olduktan sonra oluşturulan IBAN bilgilerini gişe görevlisi ile paylaş. İade işlemi tamamlandığında, AHL Pay uygulamamız üzerinden anında bildirim al, tutar hesabına yansısın.",
        imagePath: 'assets/uploads/products/phones/iban_phone.svg',
        languageCode: 'tr',
        title2: <><span>Güvence Bedel </span>İadesi</>,
        childrens: [
            {
                title: 'Hızlı ve Kolay !',
                desc: 'AHL Pay hesabına hızlı ve kolay iade'
            },
            {
                title: 'Birden Fazla Kurum',
                desc: 'Dilediğin kurumun güvence bedel iadenisini AHL Pay üzerinden alabilirsin'
            },
            {
                title: 'Bedel İaden Güvende',
                desc: 'Bedel iadeni AHL Pay aracılığıyla güvenle geri al'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
