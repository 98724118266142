// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const campaigns = [
  {
    "id": 17,
    "title": "OPEN ENGLİSH Kampanyası",
    "subTitle": "",
    "detail": "AHL Pay Müşterisi olan ya da AHL Pay Müşterisi haline gelen ve OpenEnglish Üyelik Planını satın alan, katılım esnasında reşit (18 yaşını doldurmuş) olan ve Türkiye’de ikamet eden herkes bu Promosyona katılım sağlayabilir. İndirim, https://l24.im/Jhc linki tıkladığınızda karşınıza gelen iletişim formunu doldurmanız ve Gizlilik Politikası ve Hizmet Kullanım Şartlarını onaylamanız neticesinde sizinle iletişime geçecek bir müşteri hizmetleri yetkilisi yardımı ile uygulanabilir. Promosyon 01/09/2024 tarihinde saat 00:00:01’de başlayacak olup, 31/12/2024 tarihinde saat 23:23:59’a kadar (bundan böyle 'Dönem' olarak anılacaktır) yürürlükte olacaktır. Bu Dönemin sonunda Promosyon devre dışı bırakılacak ve söz konusu indirim geçersiz olacaktır. Promosyon, işbu Hüküm ve Koşullardaki şartları yerine getiren Katılımcı, Üye Planını satın alır almaz geçerli olacaktır. Katılımcının ücretsiz deneme süresinde veya yasal cayma hakkı süresi içerisinde Üye Planını iptal etmeye karar vermesi durumunda, promosyonlu Üye Planına ilişkin ücret Kullanıcıya yansıtılmayacaktır ve Üyelik Planı iptal edilecektir. Promosyon, satılamaz, devredilemez ve başka bir teklif veya promosyonla birleştirilemez. Promosyonun nakit değeri yoktur ve nakde çevrilemez. Herhangi bir iade hakkınız varsa, iade bu indirim tutarını içeremez. İade tutarı, hiçbir şekilde satın alma fiyatına ilişkin olarak ödediğiniz tutardan fazlası olmayacaktır. İşbu Promosyon kapsamında satın alınan Üye Planının aktive edilmesi Kullanıcı tarafından ertelenemez. AHL Pay ve OpenEnglish dilediği zaman kampanyayı durdurma ve koşullarını değiştirme hakkına sahiptir.",
    "languageCode": "tr",
    "imagePath": "assets/uploads/campaigns/detail/openenglish.jpeg"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(17);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
