// @ts-nocheck
import { useLanguage } from "../../../contexts/LanguageContext";

const ProductInformationModal = ({ item, handleCloseModal }) => {

    return <>
        <div solution="" status='show'>
            <div solution-content="">
                <span solution-close="" onClick={() => handleCloseModal()}><i icon="mat-symbol xl">close</i></span>
                <picture><img src={item.imagePath} alt="" /></picture>
                <aside>
                    <h3>{item.title}</h3>
                    <content>{item.description}</content>
                </aside>
            </div>
            <div solution-backdrop=""></div>
        </div>
    </>
}

export default ProductInformationModal;