// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;
const Button = styled.button`
  background-color: #002abe;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #001b8c;
  }
`;
const pageJSON = {
    title1: [
        {
            title: 'Bize Ulaşın',
            languageCode: 'tr'
        },
        {
            title: 'Contact us',
            languageCode: 'en'
        }
    ]
}

const legalInfos = [
    {
        id: 1,
        title: 'Faliyet İzni',  
        link: 'assets/legals/teblig.pdf',
        languageCode: 'tr'
    },
    {
        id: 2,
        title: 'Bilgi Güvenliği Politikamız',
        data: <><h1>BİLGİ GÜVENLİĞİ POLİTİKAMIZ</h1>
       <a href="/assets/legals/bilgiguvenlik.pdf" download>
       <br />
          <Button>İndir</Button>
        </a>

            <article><p>ISO 27001:2013 Bilgi Güvenliği Yönetim Sistem Standardı doğrultusunda;</p>

                <ul>
                    <li>Kendisi ve paydaşlarının bilgi varlıklarına güvenli bir şekilde erişim sağlamayı,</li>
                    <li>Bilginin kullanılabilirliğini, bütünlüğünü ve gizliliğini korumayı,</li>
                    <li>Kendisinin ve paydaşlarının bilgi varlıkları üzerinde oluşabilecek riskleri değerlendirmeyi ve yönetmeyi,</li>
                    <li>Kurumun güvenilirliğini ve marka imajını korumayı,</li>
                    <li>Bilgi güvenliği ihlali durumunda gerekli görülen yaptırımları uygulamayı,</li>
                    <li>Tabi olduğu ulusal, uluslararası veya sektörel düzenlemelerden, ilgili mevzuat ve standart gereklerini yerine getirmekten, anlaşmalardan doğan yükümlülüklerini karşılamaktan, iç ve dış paydaşlara yönelik kurumsal sorumluluklardan kaynaklanan bilgi güvenliği gereksinimleri sağlamayı,</li>
                    <li>İş/Hizmet sürekliliğine bilgi güvenliği tehditlerinin etkisini azaltmayı ve işin sürekliliği ve sürdürülebilirliğini sağlamayı,</li>
                    <li>Kurulan kontrol altyapısı ile bilgi güvenliği seviyesini korumayı ve iyileştirmeyi,</li>
                    <li>Bilgi güvenliği farkındalığını arttırmak amacıyla yetkinlikleri geliştirecek eğitimleri sağlamayı,</li>
                </ul>
                <p>Taahhüt eder.</p>
            </article></>,
        languageCode: 'tr'
    },
    {
        id: 3,
        title: 'Bilgi Toplumu Hizmetleri',
        data: <><h1>Şirket Bilgileri</h1>
         <a href="/assets/legals/sirketbilgi.pdf" download>
        <br />
        <Button>İndir</Button>
        </a>

            <h2>Şirketin Ticari Ünvanı ve Merkezi : Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. </h2> 

            <h2>Vergi Dairesi : Kozyatağı</h2>
            

            <h2>Vergi Numarası : 010 139 2161</h2>
   

            <h2>Şirketin Sicil Yeri : İstanbul Ticaret Odası</h2>
           

            <h2>Şirket Türü : Anonim Şirket</h2>
          

            <h2>Taahhüt Edilen Sermaye Miktarı : 75.000.000 TL</h2>
     

            <h2>Ödenen Sermaye Miktarı : 75.000.000 TL</h2>
          

            <h2>Mersis No : 0010 1392 1610 0001</h2>
       

            <h2>Genel Müdürlük Adresi : İçerenköy Mh. Yeşilvadi Sk. No:3/4 Kat:8 Ataşehir/İstanbul</h2>


            <h2>Telefon : 444 90 66</h2>


            <h2>Fax : -</h2>
    

            <h2>E-posta : info@ahlpay.com.tr</h2>
      

            <h1>Denetim Bilgileri</h1>

            <h2>Denetim Merci : Türkiye Cumhuriyeti Merkez Bankası</h2>
         
            <h2>Adres : Hacı Bayram Mah. İstiklal Cad. No:10 06050 Ulus Altındağ/Ankara</h2>
      

            <h2>İletişim : +90 312 507 50 00</h2>
          

            <h2>Web : <a href="http://www.tcmb.gov.tr">www.tcmb.gov.tr</a> </h2>
   

            <h2>Denetçi Firma : FİNANSAL EKSEN BAĞIMSIZ DENETİM VE DANIŞMANLIK A.Ş</h2>


            <h2>Adres : BÜYÜKDERE CADDESİ ÖZSEZEN İŞ MERKEZİ C BLOK KAT 8 ESENTEPE ŞİŞLİ İSTANBUL</h2>
    

            <h2>Telefon : +90 212 280 09 09</h2>
           

            <h2>Faks : +90 212 280 09 10</h2>
     

            <h2>Web : <a href="http://www.finansaleksen.com.tr">www.finansaleksen.com.tr</a></h2>
           

            <h1>Ortaklık Yapısı ve Yönetim Kurulu</h1>

            <h2>Ortaklar : Ahlatcı Holding AŞ</h2>
            <p></p>

            <h2>Yönetim Kurulu Başkanı : Ahmet AHLATCI</h2>
            

            <h2>Yönetim Kurulu Başkan Yardımcısı : Ahmet Emin AHLATCI</h2>
            <p></p>

            <h2>Yönetim Kurulu Üyesi : Ateş AHLATCI</h2>
          

            <h2>Yönetim Kurulu Üyesi / Genel Müdür : Enver ÇETİN</h2>
        

            <h2>Sermaye TL Pay Oranı % : 75.000.000 TL %100</h2>
        </>,
        languageCode: 'tr'
    },
    {
        id: 4,
        title: 'Dolandırıcılıkla İlgili Bilgilendirme ve Sahtekarlık Türleri',
        data: <><h1>1. KULLANICI BİLGİLENDİRMESİ</h1>
           <a href="/assets/legals/kullanicibilgi.pdf" download>
           <br />
          <Button>İndir</Button>
        </a>
            <article>
                <p>Sayın Kullanıcı,</p>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş (AHL Pay) tarafından sunulan hizmetleri kullanırken;</p>
                <ul>
                    <li>İnternet sayfalarının güvenli (https) bağlantısı olduğuna ve SSL sertifikasının geçerli olmasına</li>
                    <li>AHL Pay sosyal medya adresleri olarak aşağıdakilere itibar edilmesini önemle bilgilerinize arz eder, konuya ilişkin olarak bilgilendirildiğinizi kabul ve beyan etmiş olursunuz.</li>
                </ul>
                <ul>
                    <li><a href="https://www.facebook.com/ahlpay.epara">Facebook</a></li>
                    <li><a href="https://twitter.com/ahl_pay">Twitter</a></li>
                    <li><a href="https://www.instagram.com/ahlpay.com.tr/">Instagram</a></li>
                    <li><a href="https://www.linkedin.com/company/ahlatc%C4%B1-%C3%B6deme-ve-elektronik-para-hizmetleri-a-s/">Linkedin</a></li>
                </ul>
                <p>İletişim mail: <a href="mailto:info@ahlpay.com.tr">info@ahlpay.com.tr</a></p>
                <p>İletişim tel: +90 (216) 469 05 15</p>
                <p>Şüpheli bir durumla karşılaştığınızda lütfen 0216 469 0515 numaralı çağrı merkezimizi arayınız.</p>

                <h2>2. DOLANDIRICILIKLA İLGİLİ BİLGİLENDİRME</h2>
                <h3>Kendinizi Dolandırıcılıktan Koruyun</h3>
                <p>Dolandırıcılar Önce Güveninizi Kazanır, Sonra Paranızı Çalar.</p>
                <p>AHL Pay’i sadece tanıdığınız kişilere para göndermek için kullanın. Asla şahsen tanımadığınız bir kişiye para göndermeyin. Dolandırıcılar; Telefon, geleneksel posta, e-posta ve internet gibi her türlü yolu denerler. Güveninizi kazanırlar ve sizi tuzağa düşürdüklerinde sizden para isterler; ardından da parayı alıp kaçarlar. Sizi tuzağa çekmek için kullandıkları senaryolar sürekli değişir.</p>

                <h2>Tuzağa Düşmemek İçin Aşağıdaki Yöntemleri Bilgilerinize Sunarız:</h2>
                <ul>
                    <li><strong>Telefon Dolandırıcılığı:</strong> Dolandırıcı, telefonla sizi arayarak benzer şekilde inandırıcılığı sağlamak için arka fonda polis telsizi, yazıcı sesi ya da bankadan aradığına ilişkin sesleri taklit edebilmektedir. Telefonda kendini polis, savcı veya bir kamu görevlisi olarak tanıtarak sizden para talep eden kişilere dikkat ediniz. Bir kamu görevlisi asla size telefon ederek para transferi yapmanızı istemeyecektir.</li>
                    <li><strong>Sosyal Medya Dolandırıcılığı:</strong> E-posta, SMS ya da sosyal medya aracılığıyla güven ortamı oluşturup paylaşılan bağlantının tıklanmasını sağladıktan sonra sahte internet sitesine yönlendirerek güvenlik açısından kritik bilgileri ele geçiren dolandırıcılık türüdür. Bu şekilde gerçekleştirilen dolandırıcılıklar genellikle Yemleme (Phishing) olarak da bilinmektedir.</li>
                </ul>
                <p>AHL Pay tarafından sunulan hizmetleri kullanırken:</p>
                <ul>
                    <li>https uzantılı olmasına,</li>
                    <li>SSL li olmasına,</li>
                    <li>AHL Pay Sosyal Medya adresleri olarak aşağıdakilere:</li>
                    <li><a href="https://www.facebook.com/ahlpay.epara">Facebook</a></li>
                    <li><a href="https://twitter.com/ahl_pay">Twitter</a></li>
                    <li><a href="https://www.instagram.com/ahlpay.com.tr/">Instagram</a></li>
                    <li><a href="https://www.linkedin.com/company/ahlatc%C4%B1-%C3%B6deme-ve-elektronik-para-hizmetleri-a-s/">Linkedin</a></li>
                </ul>
                <p>İletişim mail: <a href="mailto:info@ahlpay.com.tr">info@ahlpay.com.tr</a></p>
                <p>İletişim tel: +90 (216) 469 05 15</p>

                <h2>İnternet ve Mobil Cihaz Dolandırıcılıkları:</h2>
                <p>Virüsler, kötü amaçlı yazılımlar ve truva atı, vb. zararlı programları bilgisayar ya da cep telefonuna bulaştırma yoluyla kişisel ve finansal bilgileri (internet/mobil bankacılığı/AHL Pay şifresi, SMS ile iletilen mobil onay kodu, vb.) ele geçirmeyi amaçlayan dolandırıcılık türüdür.</p>

                <h3>3.1. Bilgisayar ve Mobil Cihaz Zararlı Yazılımları:</h3>
                <p>Bilgisayar ve mobil cihazlara zarar vermek, bilgi çalmak, vb. amaçlarla hazırlanmış kötü amaçlı yazılımlardır. Örnek olarak virüsler, truva atları, solucanlar verilebilir.</p>

                <h3>3.2. Site Trafiği Yönlendirme (Pharming):</h3>
                <p>Farming, adres çubuğuna doğru internet adresi yazılmasına rağmen (örneğin; www.ahlpay.com.tr), dolandırıcıların DNS (Alan Adı Sistemi) ayarlarını bozarak internet sayfasını sahte bir internet sayfasına yönlendirmesi yoluyla gerçekleştirilen dolandırıcılık türüdür.</p>

                <h2>Dolandırıcılar Bazen İnsanları Para Transfer Etmeye Teşvik Eder:</h2>
                <p>Şu amaçlarla kendisine para göndermenizi isteyen bir kişiye para transfer etmeyin:</p>

                <ul>
                    <li>Teyit etmediğiniz bir acil durum.</li>
                    <li>İnternet alışverişi.</li>
                    <li>Anti-virüs koruması.</li>
                    <li>Gayrimenkul depozitosu veya ödemesi.</li>
                    <li>Çekiliş veya ödül kazanma iddiası.</li>
                    <li>Vergi ödemesi.</li>
                    <li>Yardım bağışı.</li>
                    <li>Gizli müşteri olarak görevlendirilme.</li>
                    <li>İş fırsatı.</li>
                    <li>Kredi kartı veya kredi ücreti.</li>
                    <li>Kiralık mülk sahtekarlığı.</li>
                    <li>Sahte Çek sahtekarlığı.</li>
                    <li>İlişki Sahtekarlığı.</li>
                    <li>Göçle ilgili bir konunun çözümlenmesi.</li>
                </ul>

                <h2>Önemli Hatırlatmalar:</h2>
                <ul>
                    <li>Yapılan transfer işlemine ilişkin referans numarası, şifre, kişisel veri, bilgi, tutar, alıcı vb. bilgileri asla üçüncü kişilerle paylaşmayın.</li>
                    <li>Sizin kazandığınıza dair bir ödül olduğu söylenerek sizden ödülü almak için vergi veya komisyon ödemesi talep edilmesi durumunda bunun bir dolandırıcılık girişimi olabileceğini düşünerek dikkatli olunuz. Kazandığınız bir ödül olduğundan bahisle bunu talep etmek için para havalesi yapmanız gerektiğini iddia eden sözde çekiliş, ödül veya piyango şirketi temsilcilerine dikkat ediniz. Bu şekilde bir ödülü kazanmak için havale yapmanızı şart koşan ancak karşılığında hiçbir şey vermeyen hileli yarışmalar düzenleyen bir organizasyon olabilir.</li>
                    <li>Yurtdışında yaşayan akrabalarınızdan miras kaldığı ancak bu mirası alabilmek için sizden vergi veya komisyon, para talep edilmesi durumunda bunun bir dolandırıcılık girişimi olabileceğini düşünerek dikkatli olunuz.</li>
                    <li>Para transferi işlemlerinizle ilgili bilgileri her zaman gizli tutunuz. Birinin sizi dolandırmaya çalıştığını düşünüyorsanız emniyet yetkilileri ve AHL Pay ile iletişime geçiniz.</li>
                    <li>Para transferi yaparsanız, gönderim yaptığınız kişi parayı hızla alır. Para ödendikten sonra, AHL Pay sınırlı koşullar altında olması haricinde dolandırıcılık kurbanı olsanız dahi size geri ödeme yapamayabilir.</li>
                    <li>Dolandırıcılık kurbanı olduğunuza inanıyorsanız emniyet güçleri/polis ve 0216 469 0515 numaralı çağrı merkezimizi arayınız.</li>
                </ul>

                <p>Bilginize,</p>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş</p></article></>,
        languageCode: 'tr'
    },
    {
        id: 5,
        title: 'AHL Pay Hakem Heyeti',
        link: 'assets/legals/HakemHeyeti.pdf',
        languageCode: 'tr'
    },
    {
        id: 6,
        title: 'KVKK Aydınlatma Politikası',
        data: <>
            <h1>KVKK Aydınlatma Politikası</h1>
            <a href="/assets/legals/kvkk.pdf" download>
            <br />
          <Button>İndir</Button>
        </a>
            <article>
                <h2>1. Amaç</h2>
                <p>Bu politika Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. tarafından yürütülen her türlü faaliyette 6698 Sayılı Kişisel Verilerin Korunması Kanunu’na (KVKK) ve GDPR kapsamlarına teknik ve idari önlemlere uygun olarak kişisel veri işleme faaliyeti ve kişisel verilerin korunmasına yönelik benimsenen yöntemleri tarif etmeyi amaçlamaktadır. Kişisel Verilerin Korunması ve İşlenmesi Politikası, kişisel verilerin Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş tarafından toplanması, kullanılması, paylaşması, saklanması ve imhası süreçlerinde uygulanan prensipleri içerir. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş ile ilişiği devam eden müşteriler, kurum çalışanları, ziyaretçiler, iş birliği içinde olduğumuz kurumların çalışanları ve üçüncü kişiler başta olmak üzere kişisel verileri kurum tarafından işlenen kişileri bilgilendirmeyi amaçlanmaktadır.</p>

                <h2>2. Kapsam</h2>
                <p>Bu Politika ile kurumumuz ile ilişiği devam eden müşteriler, kurum çalışanları, ziyaretçilerimiz, iş birliği içinde olduğumuz kurumların çalışanları ve üçüncü kişilerin otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla kurumumuzun süreçlerinde işlenen tüm kişisel verileri kapsar.</p>

                <h2>3. Yetki ve Sorumluluklar</h2>
                <p>Kurum içerisinde Kanun, Yönetmelik ve Politika ile belirtilen verinin imhasına dair gereklerin yerine getirilmesinde tüm çalışanlar, danışmanlar, dış hizmet sağlayıcıları ve diğer surette kurum nezdinde kişisel veri saklayan ve işleyen herkes bu gerekleri yerine getirmekten sorumludur. Her iş birimi kendi iş süreçlerinde ürettiği veriyi saklamak ve korumakla yükümlüdür. İş süreçlerini etkileyecek ve veri bütünlüğünün bozulmasına, veri kaybına ve yasal düzenlemelere aykırı sonuçlar doğmasına neden olacak imhalara; ilgili kişisel verinin türü, içinde yer aldığı sistemler ve veri işlemeyi yapan iş birimi dikkate alınarak ilgili bilgi sistemleri bölümü karar verecektir. KVK Kurulu ile yapılan tebligat veya yazışmaları veri sorumlusu adına tebellüğ veya kabul etme ve sicile kayıt gibi işlemlerin sorumluluğu veri sorumlusu irtibat kişisindedir.</p>

                <h2>4. Tanımlar ve Kısaltmalar</h2>
                <p><strong>Açık Rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza.</p>
                <p><strong>İlgili Kullanıcı:</strong> Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişilerdir.</p>
                <p><strong>İmha:</strong> Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi.</p>
                <p><strong>Kanun:</strong> KVKK 6698 Sayılı Kişisel Verilerin Korunması Kanunu.</p>
                <p><strong>Kayıt Ortamı:</strong> Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortam.</p>
                <p><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</p>
                <p><strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem.</p>
                <p><strong>Kişisel Verilerin Anonim Hale Getirilmesi:</strong> Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesi.</p>
                <p><strong>Kişisel Verilerin Silinmesi:</strong> Kişisel verilerin silinmesi; kişisel verilerin İlgili Kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi.</p>
                <p><strong>Kişisel Verilerin Yok Edilmesi:</strong> Kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemi.</p>
                <p><strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu.</p>
                <p><strong>Özel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri.</p>
                <p><strong>Periyodik İmha:</strong> Kanun’da yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla re’sen gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.</p>
                <p><strong>Veri Sahibi/İlgili Kişi:</strong> Kişisel verisi işlenen gerçek kişi.</p>
                <p><strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişi.</p>
                <p><strong>Veri Sorumlusu:</strong> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişi.</p>
                <p><strong>Veri Sorumlusu İrtibat Kişisi ve Yardımcıları:</strong> Veri sorumlusu Türkiye'de yerleşik bir tüzel kişi olduğundan dolayı veri sorumlusu irtibat kişisi atanmıştır. Bu sebepten irtibat kişinin ve yardımcılarının temel görevi Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek kişilerdir.</p>
                <p><strong>Yönetmelik:</strong> 28 Ekim 2017 tarihinde Resmî Gazete’de yayımlanan Kişisel Kısaltma Tanım Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik.</p>

                <h2>5. Kişisel Verilerin Korunması ve İşlenmesi Politikası</h2>
                <p>Kurumumuz, kişisel verilerin korunması ve işlenmesi için gerekli tedbirleri ve uygulanan süreci politika ile somut bir şekilde ortaya koymaktadır. İlgili kanunlar ve yönetmeliklere ile bu politikanın uyumsuz olduğu durumlarda ya da güncellenen mevzuatlar doğrultusunda politikanın güncel olmaması halinde Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu yürürlükteki mevzuata uyacağını kabul etmektedir. Kanun, yönetmelik ve mevzuatlarda olan değişikliklere göre bu politika güncellenip, Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. kurumunun yasal gerekliliklerini yerine getirmesi için revize edilir.</p>

                <h2>5.1. Kişisel Verilerin Güvenliğinin Sağlanması</h2>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş., kişisel verilerin korunması için gerekli olan uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almaktadır.</p>

                <h3>5.1.1. Teknik Tedbirler</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu, veri güvenliğini sağlamak amacıyla bilgili ve deneyimli kişiler istihdam eder ve personeline gerekli KVK eğitimlerini verir. Kurulan sistemler için gerekli iç kontroller yapılır. Kurulan sistemler kapsamında risk analizi, veri sınıflandırması, Bilgi Güvenliği risk değerlendirmesi ve iş etki analizinin gerçekleştirilmesi süreçlerini işletir. Bu süreçler doğrultusunda teknolojideki gelişmelere uygun teknik önlemler alınmaktadır. Gelişen teknolojiye uygun altyapı yatırımlar yapılır. Virüs koruma sistemleri ve güvenlik duvarlarını içeren yazılımlar ve donanımların kurulmasını sağlar. Sistemlerinin güncel ve bilinen açıklıklara karşı gerekli güvenlik önlemlerinin alınmış versiyonlarını kullanır ve sızma (penetration) testleri yaptırır. IT birimlerinde çalışanların kişisel verilere erişimi yetkilerinin kontrol altında tutulmasını sağlar. İş birim bazlı belirlenen hukuksal uyum gerekliliklerine uygun olarak erişim ve yetkilendirme tanımlarını yapar. Erişimlerin yetkilendirmelere uygunluğunu kontrol eder. Sistemlerin güvenliğinin kontrol edilmesi sonucu elde edilen bilgileri ilgililere raporlar. Risk teşkil eden noktalar tespit edilerek gerekli teknik tedbirler alınır. Kişisel Verilerin güvenliğinin sürdürülebilmesi için teknik tedbirleri sürekli işleyen bir model ile kurum kültürünün bir parçası olması için farkındalığı yaygınlaştırır. Alınan tedbirlerin kontroller ile sürekli yaşatılmasını sağlar.</p>

                <h3>5.1.2. İdari Tedbirler</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu kişisel verilerin güvenliğini sağlamak amacı ile gerekli idari tedbirleri alır ve çalışanların bu tedbirlere göre çalışmalarını denetler. İş birim bazlı belirlenen hukuksal uyum gerekliliklerine uygun olarak ve iş süreçlerinin aksamasına neden olmayacak düzeyde erişim ve yetkilendirmeleri tanımlar. IT birimlerinde çalışanların kişisel verilere erişim yetkilerini ve kurallarını tanımlar. Çalışanlar, öğrendikleri kişisel verileri KVK Kanunu hükümlerine aykırı olarak başkasına açıklayamayacağı, işleme amacı dışında kullanamayacağı ve bu yükümlülüğün görevden ayrılmalarından sonra da devam edeceği konusunda bilgilendirilmektedir. Çalışanlardan bu doğrultuda gerekli taahhütler alınmaktadır. Üçüncü taraflarla kişisel verilerin paylaşılmasıyla ilgili olarak kişisel verilerin paylaşıldığı kişilerle çerçeve sözleşme imzalanır yahut sözleşmelere ekleyeceği hükümler ile veri güvenliğini sağlar. Kişisel veri paylaşılan üçüncü taraflar kişisel verilerin korunması amacıyla gerekli güvenlik tedbirlerini alacağına ve kendi kuruluşlarında bu tedbirlere uyulmasını sağlayacağına ilişkin hükümleri kabul eder. Alınan önlemlere rağmen işlenen kişisel verilerin kanuni olmayan yollarla başkaları tarafından elde edildiği tespit edilmesi halinde veri temsilcisi tarafından ilgilisine ve KVK Kuruluna bildirir. Kişisel verinin nasıl başkaları tarafından elde edildiği araştırılır. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu tespit ettiği zafiyeti gidermek için gerekli idari tedbirleri uygular, ihtiyaç halinde teknik tedbirleri alır.</p>

                <h3>5.1.3. Kişisel Verilerin Güvenli Ortamda Saklanması</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu elde ettiği kişisel verilerini güvenli ortamlarda saklamak için teknolojik imkânlar ve uygulama maliyetine göre gerekli teknik ve idari tedbirleri almaktadır. Güvenli ortamda veri saklamaya ilişkin kurallar ve yöntemimiz “Veri Saklama ve İmha Politikasında” detaylandırılmıştır.</p>

                <h3>5.1.4. Kişisel Verilerin Korunmasının Sürdürülebilirliği İçin Yapılan Denetimler</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. KVK Kanunu’nun 12. maddesine uygun olarak, gerekli denetimleri yapar ve yaptırır. Bilgi Güvenliği Yönetim Sistemi ve Veri Koruma Kişisel Bilgi Yönetimi sistemlerinin sürdürülebilirliğini sağlamak için iç ve dış denetimlerin yapılması sağlar. Sistemlerde oluşabilecek teknik açıklıklar için düzenli olarak sistemlere sızma testlerini gerçekleştirir. IT tarafından sistemler düzenli olarak izlenmektedir. Ayrıca siber saldırılara karşı güvenliğin sağlanması için sistem iz kayıtları izlenmektedir. Yönetim sistemleri denetimleri, uyarı sistemlerinin ürettiği veriler ve sistemlerin izlenmesi sonrası bulguların tespit edilmesi sonrası gerekli teknik ve idari tedbirler alınmaktadır. Yapılan denetimlerde kişisel verilerin hukuka aykırı erişilmesi ya da işlenmesi tespit edildiğinde Kişisel Verilerin Korunması Komitesine bildirilmektedir. Kurum yönetimi, komite tarafından bilgilendirilmektedir.</p>

                <h3>5.1.5. Kişisel Verilerin Yetkisiz İfşası Durumunda Alınan Tedbirler</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu, KVK Kanunu’nun 12. maddesine uygun olarak işlenen kişisel verilerin yetkisiz ifşa olması halinde ilgili kişisel veri sahibine ve KVK Kurulu’na bildirir. KVK Kurulu tarafından gerek görülmesi halinde, bu durum, KVK Kurulu’nun internet sitesinde veya başka bir yöntemle ilan edilebilir.</p>

                <h3>5.1.6. Üçüncü Tarafların Kişisel Verilerin Korunmasını Sağlaması İçin Uygulanan Tedbirler</h3>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu, üçüncü taraflar ile yaptığı sözleşmelerde; kişisel verilerin hukuka aykırı olarak işlenmesinin önlenmesi, verilere hukuka aykırı olarak erişilmesinin önlenmesi ve verilerin muhafazasını sağlamaya yönelik gerekli yaptırım maddelerini karşılıklı olarak bulundurur. Üçüncü taraflar ile bilgi paylaşımı yapılmadan önce gizlilik sözleşmeleri imzalanır. Üçüncü taraflara farkındalığın artırılması için gerekli bilgilendirmeler yapılır.</p>

                <h3>5.1.7. Özel Nitelikli Kişisel Verilerin Koruması İçin Uygulanan Tedbirler</h3>
                <p>Özel nitelikli kişisel veriler için gerek nitelikleri itibari ile gerekse kişilerin mağduriyetine veya ayrımcılığa yol açabilmesinden dolayı yeterli önlemlerin alınması gerekmektedir. KVK Kanunu’nun 6. maddesinde, hukuka aykırı olarak işlendiğinde kişilerin mağduriyetine veya ayrımcılığa sebep olma riski taşıyan bir takım kişisel veri “özel nitelikli” olarak belirlenmiştir. Bu veriler; ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik verilerdir. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu, KVK Kanunu ile “özel nitelikli” olarak belirlenen ve hukuka uygun olarak işlenen özel nitelikli kişisel verilerin korunmasında gerekli tedbirleri almaktadır. Kişisel verileri korumak için alınan teknik ve idari tedbirlerde özel nitelikli kişisel veriler için hassasiyet gösterilmektedir.</p>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu işlediği özel nitelikli kişisel verileri KVK Kurulu tarafından belirlenecek olan yeterli önlemlerin alınması kaydıyla işlemektedir. Özel nitelikli kişisel veriler işlenmeden önce veri sahibinin açık rızası alınır. Veri sahibinin açık rızası yok ise aşağıdaki kriterlere uygun olarak kanunların verdiği yetki ile kişisel veriler işlenebilmektedir.</p>
                <ul>
                    <li>Kişisel veri sahibinin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde,</li>
                    <li>Kişisel veri sahibinin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlara, aktarılabilmektedir.</li>
                </ul>

                <h3>5.1.8. Kişisel Verilerin Korunmasının Sağlanması İçin Farkındalığın Yaratılması</h3>
                <p>Kişisel verilerin hukuka aykırı olarak işlenmesini, verilere hukuka aykırı olarak erişilmesini önlemeye ve verilerin muhafazasını sağlamaya yönelik farkındalığın artırılması için iş birimlerine gerekli bilgilendirmeler yapılmakta, eğitimler düzenlenmekte ve etkinlikleri ölçülmektedir. “Kişisel Verilerin Korunması ve İşlenmesi Politikası” ile ilgili diğer politikalar, kurumumuzun web sitesinde yayınlanmıştır. Kurumumuz çalışanları bu politikadan haberdar edilmiştir. İlgili kanun, yönetmelik ya da mevzuatlarda değişiklik olması halinden politikalar revize edilmekte ve çalışanlara tekrardan duyurulmaktadır.</p>

                <h2>5.2. Kişisel Verilerin İşlenmesi İçin İlkeler</h2>
                <p>KVK Kanunun 4. maddesi 2. bendinde kişisel verilerin işlenmesi için ilkeler belirlenmiştir. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumu belirlenen ilkelere uygun şekilde kişisel verileri işlemektedir.</p>
                <p>Kişisel verilerin işlenmesi aşağıdaki ilkelere uygun yapılmaktadır;</p>
                <ul>
                    <li>Hukuka ve dürüstlük kurallarına uygun olma.</li>
                    <li>Doğru ve gerektiğinde güncel olma.</li>
                    <li>Belirli, açık ve meşru amaçlar için işlenme.</li>
                    <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma.</li>
                    <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme.</li>
                </ul>

                <h2>5.3. Kişisel Verinin İşlenmesi Şartları</h2>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. verilerin önemli bir çoğunluğunu yasal zorunluluklar nedeniyle ve kamu düzeninin korunması için kullanması zorunlu olan yetkileri kullanarak işlemektedir. Tam metnine ulaşabileceğiniz ilgili yasanın 5/2 maddesi gereği verinin işlenmesinin:</p>
                <ul>
                    <li>Kanunlarda açıkça öngörülmesi.</li>
                    <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.</li>
                    <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</li>
                    <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.</li>
                    <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması.</li>
                    <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.</li>
                    <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
                </ul>
                <p>Yukardaki çoğunluğa girmeyen durum için kurumumuz ancak veri sahiplerinin açık rızasını temin etmek suretiyle kişisel veri işlemektedir.</p>

                <h2>5.4. Kişisel Verinin İşlenmesi Amaçları</h2>
                <ul>
                    <li>Müşterilerimizden gelen taleplerin karşılanabilmesi,</li>
                    <li>Randevu sistemi süreçlerinin yürütülmesi ve hizmet süreçlerinin tanıtılmasını sağlama,</li>
                    <li>Çalışanların iş akdi mevzuatlarından doğan kanuni yükümlülükleri yerine getirme,</li>
                    <li>Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan hakları kullanabilmek,</li>
                    <li>Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek,</li>
                    <li>Müşterilerimizin memnuniyetini artırmak ve/veya fiziki ortamda anketler düzenlemek,</li>
                    <li>Müşterilerimize öneri sunabilmek, hizmetlerimizle ilgili müşterilerimizi bilgilendirebilmek,</li>
                    <li>Meşru menfaatler doğrultusunda gerekli fiziksel önlemleri almak ve uygulamak,</li>
                    <li>Sosyal medya kapsamında iş faaliyetlerin tanıtımını yapma ve potansiyel müşteri, müşterilerini yapmış olduğu çalışmalar kapsamında bilgilendirme,</li>
                    <li>Hizmetlerimiz ile ilgili şikâyet, istek ve önerilerini değerlendirebilmek,</li>
                    <li>Bu ve benzeri sistemler işlemlerin yapılabilmesi için verinizin işlenmesi gerekmektedir.</li>
                </ul>

                <h2>5.5. Kişisel Verilerin İmhası</h2>
                <p>Kurumumuz, elde ettiği kişisel verilerini kişisel veri sahiplerinin talebi doğrultusunda, yasal zorunluluklar nedeniyle ve kamu düzeninin korunması için kullanması zorunlu değilse imha eder. Veri sahiplerine ait kişisel veriler, müşteriye hizmetin devam ettirebilmesi, hukuki yükümlülüklerin yerine getirilebilmesi, çalışan haklarının ve yan haklarının planlanması gereklilikleri ortadan kalktığında kurumun alacağı karara istinaden imha edilmektedir. Kişisel verilerin imhasına ilişkin kurallar ve yöntemimiz “Veri Saklama ve İmha Politikasında” detaylandırılmıştır.</p>

                <h2>5.6. Kişisel Verilerin Yurtiçindeki Kişilere Aktarılması</h2>
                <p>Kurumumuz, kişisel verilerin üçüncü taraflarla paylaşılması hususunda, diğer kanunlarda yer alan hükümler saklı kalmak kaydıyla, KVKK’da düzenlenen şartlara özenle uymaktadır. Bu çerçevede, kişisel veriler, veri sahibinin açık rızası olmadan üçüncü kişilere aktarılmamaktadır. Ancak, KVKK tarafından düzenlenen aşağıdaki şartlardan birinin varlığı halinde kişisel veriler; veri sahibinin açık rızası temin edilmeksizin de aktarılabilecektir:</p>
                <ul>
                    <li>Kanunlarda açıkça öngörülmesi,</li>
                    <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</li>
                    <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,</li>
                    <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</li>
                    <li>Veri sahibinin kendisi tarafından alenileştirilmiş olması,</li>
                    <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</li>
                    <li>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
                </ul>
                <p>Yeterli önlemler alınmak kaydıyla; sağlık ve cinsel hayat dışındaki özel nitelikli kişisel veriler bakımından kanunlarda öngörülmesi, sağlık ve cinsel hayata ilişkin özel nitelikli kişisel veriler bakımından ise,</p>
                <ul>
                    <li>Kamu sağlığının korunması,</li>
                    <li>Koruyucu hekimlik,</li>
                    <li>Tıbbî teşhis,</li>
                    <li>Tedavi ve bakım hizmetlerinin yürütülmesi,</li>
                    <li>Sağlık hizmetleri ile finansmanının planlanması ve yönetimi gibi amaçlarla açık rıza temin edilmeksizin kişisel verileriniz aktarılabilecektir.</li>
                </ul>
                <p>Özel nitelikli kişisel verilerin aktarılmasında da bu verilerin işlenme şartlarında belirtilen koşullara uyulmaktadır</p>

                <h2>5.7. Kişisel Verilerin Yurtdışındaki Kişilere Aktarılması</h2>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kurumunda, kişisel verilerin yurtdışına aktarılmasına ilişkin olarak, KVKK’nın 9. maddesi doğrultusunda veri sahibinin açık rızası aranmaktadır. Ancak, özel nitelikli kişisel veriler dahil, kişisel verilerin veri sahibin açık rızası olmaksızın işlenmesine izin verilen şartların varlığı halinde, kişisel verinin aktarılacağı yabancı ülkede, yeterli korumanın bulunması kaydıyla veri sahibinin açık rızası aranmaksızın da şirketimiz tarafından kişisel veriler yurtdışına aktarılabilecektir. Eğer aktarım yapılacak ülke Kurul tarafından yeterli korumanın bulunduğu ülkeler arasında belirlenmemiş ise, Kurumumuz ve ilgili ülkedeki veri sorumlusu/veri işleyen yeterli korumayı yazılı olarak taahhüt edecektir. Yabancı ülkelere veri aktarılmıyorsa örnek metin: “Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. hiçbir şekilde yabancı ülkelere kişisel veri aktarmamaktadır ve yabancı ülkelerde tutulan sunucularda kişisel veri tutmamaktadır”</p>

                <h2>5.8. Kişisel Verilerin Kategorizasyonu</h2>
                <p>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. Kişisel veriler Veri Konusu Kişi Grubu ve Veri Tipi olmak üzere iki kategoriye ayrılmıştırlar.</p>
                <p><strong>Veri Konusu Kişi Grubu Kategorileri:</strong></p>
                <ul>
                    <li><strong>Şirket çalışanları:</strong> İş Kanunu, İş Güvenliği mevzuatı başta olmak üzere ilgili mevzuat gereği kişisel verileri işlenen Şirket çalışanları.</li>
                    <li><strong>Şirket eski çalışanları:</strong> İş Kanunu, İş Güvenliği mevzuatı başta olmak üzere ilgili mevzuat gereği iş akdinin sona ermesinin ardından belirli süre ile işlenmesi zorunlu olan veriler açısından, iş akdi sona ermiş olmasına rağmen kişisel verileri işlenen Şirket çalışanları.</li>
                    <li><strong>Şirket hizmet sağlayıcıları, tedarikçi, alt işveren ve bunların çalışanları:</strong> Şirketin hizmet veya ürün aldığı veya alt yüklenicisi olan gerçek kişiler veya bu kişilerin çalışanları.</li>
                    <li><strong>Şirket ile iş ilişkisi sona ermiş olsa dahi Şirket hizmet sağlayıcıları, tedarikçi, alt işveren ve bunların çalışanları:</strong> Şirket ile olan sözleşmesel ilişkileri sona ermiş olmasına rağmen mevzuattan kaynaklanan yükümlülükler gereği verisi işlenilmeye devam eden hizmet sağlayıcılar, tedarikçiler ve altyükleniciler ve bunların çalışanları. Bu kişilere ilişkin veriler mevzuattan kaynaklanan işleme zorunluluğu sona erdiğinde ilgili prosedür uyarınca silinecek veya anonim hale getirilecektir.</li>
                </ul>
                <p><strong>Veri Tipi Kategorileri:</strong></p>
                <ul>
                    <li><strong>Kimlik Bilgisi:</strong> Ehliyet, nüfus cüzdanı, ikametgâh, pasaport, avukatlık kimliği, evlilik cüzdanı gibi dokümanlarda yer alan bilgiler (örn. TCKN, pasaport no., nüfus cüzdanı seri no., Ad-Soyad, fotoğraf, doğum yeri, doğum tarihi, yaş, nüfusa kayıtlı olduğu yer, vukuatlı nüfus cüzdanı örneği)</li>
                    <li><strong>İletişim Bilgisi:</strong> Kişiyle iletişim kurulması amacıyla kullanılan bilgiler (örn. e-mail adresi, telefon numarası, cep telefonu numarası, adres)</li>
                    <li><strong>Lokasyon Verisi:</strong> Veri sahibinin konumunu tespit etmeye yarayan veriler (örn. araç kullanımı sırasında edinilen lokasyon verileri)</li>
                    <li><strong>Müşteri İşlem Bilgisi:</strong> Hizmetlerimizden faydalanan müşteri tarafından gerçekleştirilen her türlü işleme ilişkin bilgiler (örn. talep ve talimatlar, vb.)</li>
                    <li><strong>Fiziksel Mekân Güvenlik Bilgisi:</strong> Fiziksel mekâna girişte, fiziksel mekânın içerisinde kalış sırasında alınan kayıtlar ve belgelere ilişkin kişisel veriler (örn. giriş çıkış logları, ziyaret bilgileri, kamera kayıtları vb.)</li>
                    <li><strong>İşlem Güvenliği Bilgisi:</strong> Kurumumuz ve ilgili tarafların teknik, idari, hukuki ve ticari güvenliğini sağlamak amacıyla işlenen kişisel veriler (örn. kişisel veri sahibiyle ilişkilendirilen işlem ile o kişiyi eşleştirmeye ve kişinin o işlemi yapmaya yetkili olduğunu gösteren Internet sitesi şifre ve parola gibi bilgiler)</li>
                    <li><strong>Risk Yönetimi Bilgisi:</strong> Kurumumuzun, teknik ve idari risklerini yönetebilmek için işlenen kişisel veriler (örn. IP adresi, Mac ID vb. kayıtlar)</li>
                    <li><strong>Finansal Bilgi:</strong> Kişisel veri sahibi ile mevcut hukuki ilişkinin tipine göre yaratılan her türlü finansal sonucu gösteren bilgi, belge ve kayıtlar kapsamındaki kişisel veriler (Örneğin: veri sahibinin yapmış olduğu işlemlerin finansal sonucunu gösteren bilgiler, vergi borcu tutarı, kart bilgisi, vergi ödemeleri, ödenecek faiz tutarı ve oranı, borç bakiyesi, alacak bakiyesi vb.)</li>
                    <li><strong>Özlük Bilgisi:</strong> Kurumumuza ait tedarikçilerinin çalışanlarının özlük haklarının oluşmasına temel olan kişisel veriler (kanunen özlük dosyasına girmesi gereken her türlü bilgi ve belge)</li>
                    <li><strong>Çalışan Adayı Bilgisi:</strong> Kurumumuz nezdinde iş başvurusu yapmak üzere bilgilerini paylaşan veri sahiplerine ait, başvuru değerlendirme sürecinde kullanılan kişisel veriler (örn. özgeçmiş, mülakat notları, kişilik testleri sonuçları vb.)</li>
                    <li><strong>Pazarlama Bilgisi:</strong> Kurumumuz tarafından pazarlama faaliyetlerinde kullanılacak veriler (örn. pazarlama amacıyla kullanılmak üzere toplanan kişinin alışkanlıkları, beğenilerini gösteren raporlar ve değerlendirmeler, hedefleme bilgileri, cookie kayıtları, veri zenginleştirme faaliyetleri)</li>
                    <li><strong>Hukuki İşlem ve Uyum Bilgisi:</strong> Hukuki alacak ve hakların tespiti ve takibi ile borç ve kanuni yükümlülüklerin ifası amacıyla işlenen kişisel veriler (örn. mahkeme ve idari merci kararı gibi belgelerde yer alan veriler)</li>
                    <li><strong>Denetim ve Teftiş Bilgisi:</strong> Kurumumuzun kanuni yükümlülükleri ve şirket politikalarına uyumu kapsamında işlenen kişisel veriler (örn. denetim ve teftiş raporları, ilgili görüşme kayıtları ve benzeri kayıtlar)</li>
                    <li><strong>Özel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri</li>
                    <li><strong>Talep/Şikâyet Yönetimi Bilgisi:</strong> Kurumumuza yöneltilmiş olan her türlü talep veya şikâyetin alınması ve değerlendirilmesine ilişkin kişisel veriler (örn. Kurumumuza yönelik talep ve şikayetler, bunlarla ilgili kayıt ve raporlar)</li>
                    <li><strong>Görsel ve İşitsel Veri:</strong> Kişisel veri sahibiyle ilişkilendirilen görsel ve işitsel kayıtlar (örn. fotoğraflar, kamera kayıtları ve ses kayıtları)</li>
                </ul>

                <h2>5.9. Basılı Doküman, Kamera Kaydı, İnternet Sitesi Ziyaretçilerinin Kişisel Verileri,</h2>

                <h3>5.9.1. Basılı Doküman</h3>
                <p>Şirketimiz müşterilere verdiği hizmetler için bazı durumlarda basılı doküman ortamında kişisel veriler almaktadır. Bu tarz veriler KVK kanunda belirtilen şartlar doğrultusunda işlenmekte, saklanmakta ve imha edilmektedir. İnsan kaynaklarında kullanılan personel Özlük bilgileri; Çalışanlarımızın veya kurumuz çalışma ilişkisi içerisinde olan gerçek kişilerin özlük haklarının oluşmasına temel olacak bilgilerin elde edilmesine yönelik işlenen her türlü kişisel verilerdir. Sağlık Hizmetleri için alınan veriler; Şirketimizin çalışanlarına verdiği sağlık hizmetleri için kişisel verileri saklamaktadır. Başvuru yapanları tanımlayıcı bilgiler; Şirketten hizmet almak için yapılan başvurularda verilen tanımlayıcı bilgiler.</p>

                <h3>5.9.2. Kamera Kaydı</h3>
                <p>Kurumumuz tarafından güvenliğin sağlanması amacıyla, şirket binalarımızda ve tesislerimizde güvenlik kamerasıyla izleme faaliyeti ile misafir giriş çıkışlarının takibine yönelik kişisel veri işleme faaliyetinde bulunulmaktadır. Güvenlik kameraları kullanılması ile kişisel veri işleme faaliyeti yürütülmüş olmaktadır. Bu kapsamda Kurumumuz Anayasa, KVK Kanunu ve ilgili diğer mevzuata uygun olarak hareket etmektedir. Kurumumuzun bina girişlerinde kamera ile izleme sistemi vasıtasıyla ziyaretçilerimizin görüntü kayıtları alınmaktadır. Kurumumuz, güvenlik kamerası ile izleme faaliyeti kapsamında; sunulan hizmetin kalitesini artırmak, güvenilirliğini sağlamak, kurumun, müşterilerin ve diğer kişilerin güvenliğinin sağlaması amaçlanmaktadır. Kurumumuz tarafından güvenlik amacıyla kamera ile izleme faaliyeti yürütülmesinde KVK Kanunu’nda yer alan düzenlemelere uygun hareket edilmektedir. Kurumumuz tarafından yürütülen kamera ile izleme faaliyeti, Özel Güvenlik Hizmetlerine Dair Kanun ve ilgili mevzuata uygun olarak sürdürülmektedir. Dijital ortamda kaydedilen ve muhafaza edilen kayıtlara yalnızca sınırlı sayıda kurum çalışanının erişimi bulunmaktadır. Canlı kamera görüntülerini ise, dışarıdan hizmet alınan güvenlik görevleri izleyebilmektedir. Kayıtlara erişimi olan sınırlı sayıda kişi gizlilik taahhütnamesi ile eriştiği verilerin gizliliğini koruyacağını beyan etmektedir. Kurumumuz tarafından KVK Kanunu’nun 12. maddesine uygun olarak, kamera ile izleme faaliyeti sonucunda elde edilen kişisel verilerin güvenliğinin sağlanması için gerekli teknik ve idari tedbirler alınmaktadır.</p>

                <h3>5.9.3. İnternet Sitesi Ziyaretçilerinin Kişisel Verileri ve İnternete Erişim Noktası Hizmeti için Alınan Kişisel Veriler</h3>
                <p>Kurumumuz sahibi olduğu internet sitelerinde; bu siteleri ziyaret eden kişilerin sitelerdeki ziyaretlerini ziyaret amaçlarıyla uygun bir şekilde gerçekleştirmelerini temin etmek; teknik vasıtalarla (Örn. çerezler-cookie gibi) site içerisindeki internet hareketleri kaydedilmektedir. Kurumumuzun yapmış olduğu bu faaliyetlere ilişkin kişisel verilerin korunması ve işlenmesine ilişkin detaylı açıklamalar ilgili internet sitelerinin “Çerez Politikası” metinleri içerisinde yer almaktadır. Şirketimiz açık noktalarda bütün ziyaretçilerine ücretsiz internet hizmeti vermektedir. Verilen hizmetin iz kayıtlarının 5651 (İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında) kanunu gereği ve erişim bilgilerinin doğrulanması için kişisel verileri tutulmaktadır.</p>

                <h3>5.9.4. Kişisel Veri Sahibinin Hakları</h3>
                <p>Tam metnine KVKK sitesinden ulaşabileceğiniz “Kişisel Verilerin Korunması Hakkında Kanun’dan kaynaklanan veri sahibi haklarınız ilgili kanunun 11. maddesinde sayılmış olup şunlardır:</p>
                <p><strong>Madde 11- (1)</strong> Herkes, veri sorumlusuna başvurarak kendisiyle ilgili;</p>
                <ul>
                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                </ul>
                <p>Yukardaki maddelerde geçen haklarınızı “KVKK Talep Formu” nu doldurarak yapılabilir. İlgili kanun gereği veri sorumlusu, veri sorumlusu temsilcisi ve veri sorumlusu irtibat kişisi belgeleri aşağıdaki şekildedir:</p>

                <h3>5.9.5. Kurumunun Aydınlatma ve Bilgilendirme Yükümlülüğü</h3>
                <p>KVKK’nın 10. maddesi kapsamında, veri sahiplerinin, kişisel verilerin elde edilmesinden önce yahut en geç elde edilmesi sırasında aydınlatılması gerekmektedir. Söz konusu aydınlatma yükümlülüğü çerçevesinde veri sahiplerine iletilmesi gereken bilgiler şunlardır:</p>
                <ul>
                    <li>Veri sorumlusunun ve varsa temsilcisinin kimliği,</li>
                    <li>Kişisel verilerin hangi amaçla işleneceği,</li>
                    <li>İşlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği,</li>
                    <li>Kişisel veri toplamanın yöntemi ve hukuki sebebi,</li>
                    <li>KVKK’nın 11. maddesinde2 sayılan diğer haklar.</li>
                </ul>
                <p>Öte yandan, KVKK’nın 28(1). Maddesi çerçevesinde, aşağıda sayılan durumlarda aydınlatma yükümlülüğü bulunmamaktadır:</p>
                <ul>
                    <li>Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili faaliyetler kapsamında işlenmesi,</li>
                    <li>Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi,</li>
                    <li>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi,</li>
                    <li>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.</li>
                    <li>Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.</li>
                </ul>
                <p>Veri Sahiplerini aydınlatma ve açık rızalarının alınması için “Açık Rıza ve Aydınlatma Beyanı” hazırlanmıştır.</p>

                <h2>5.10. Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonimleştirilmesi Şartları</h2>
                <p>Kurumumuz, elde ettiği kişisel verileri kişisel veri sahiplerinin talebi doğrultusunda, yasal zorunluluklar nedeniyle ve kamu düzeninin korunması için kullanması zorunlu değil ise siler, yok eder ya da anonimleştirir. Kişisel verilerin silinmesi, yok edilmesi ve anonimleştirilmesine ilişkin kurallar ve yöntemimiz “Veri Saklama ve İmha Politikasında” detaylandırılmıştır.</p>

                <h2>5.11. Kişisel Verilerin Korunması Komitesi Çalışma Esasları</h2>
                <p>Kurumumuz, KVKK gerekliliklerini yerine getirmek ve uygunluğunu sürdürebilmek için “Kişisel Verilerin Korunması Komitesi’ni kurmuştur. Kişisel Verilerin Korunması Komitesi’nin en önde gelen amacı ve hedefi:</p>
                <ul>
                    <li>Özel hayatın gizliliğini korumak</li>
                    <li>Kişilerin temel hak ve özgürlüklerini korumak</li>
                    <li>Veri işleyen kişilerin görev ve yetkilerini düzenlemek</li>
                </ul>

                <h1>6. Referans Dokümanlar</h1>
                <ul>
                    <li>6698 Sayılı Kişisel Verilerin Korunması Kanunu</li>
                    <li>Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik</li>
                    <li>Açık Rıza ve Aydınlatma Beyanı</li>
                </ul>

                <h1>7. İlgili Dokümanlar</h1>
                <ul>
                    <li>Veri Saklama ve İmha Politikası</li>
                </ul>
            </article>
        </>
    },
    {
        id: 7,
        title: 'Pazarlama ve İleti Onayı Açık Rıza Beyanı',
        data: <> <h1>Pazarlama ve İleti Onayı Açık Rıza Beyanı</h1>
          <a href="/assets/legals/kvkk.pdf" download>
          <br />
          <Button>İndir</Button>
        </a>
            <article>
                <p>6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. bildirdiğim kimlik ve iletişim kişisel verilerimin işlenmesi ve aktarılmasına ilişkin yukarıda bulunan aydınlatma metni vasıtasıyla tarafıma yapılan bilgilendirmeyi okuduğumu, incelediğimi, değerlendirip anladığımı, sunulan ürün ve hizmetlerine ilişkin olarak tanıtım, reklam ve pazarlama faaliyetleri kapsamında, özel günlerde indirim, avantaj, fayda, bilgilendirme, kişiye özel indirim genel veya kişiselleştirilmiş kampanya, avantaj, promosyon, bilgilendirme vb. içerikli iletilerin gönderilmesi amacıyla işlenmesine ve söz konusu kişisel verilerimin veri sorumlusu sıfatıyla Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. ticari elektronik ileti gönderiminin gerçekleştirilmesi amacı ile sınırlı kalmak kaydıyla “İleti ve Pazarlama Onayı Aydınlatma Metni”nde belirtildiği üzere işlenmesine, aktarılmasına ticari elektronik ileti gönderilmesine onayım vardır.</p>

                <p>Kanunun bana tanımış olduğu haklarımı bildiğimi ve içeriğini okuyup anladığımı ve işlenmesine muvafakat ettiğimi kabul ve beyan ederim.</p>
                </article></>
    },
    {
        "id": 8,
        "title": "Kendi Adına Başkası Hesabına İşlem Yapanlara İlişkin Bilgilendirme",
        "data": <> 
          <h1>Kendi Adına Başkası Hesabına İşlem Yapanlara İlişkin Bilgilendirme</h1>
          <a href="/assets/legals/bilgilendirme.pdf" download>
          <br />
          <Button>İndir</Button>
        </a>
          <article>
            <p>Sayın Müşterilerimiz,</p>
            <p>
              5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanunun 27. Maddesine dayanılarak yürürlüğe giren “Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik” (Yönetmelik olarak anılacaktır) gereğince; hesap açılması, kredi veya kredi kartı verilmesi, kiralık kasa, finansman, faktöring, finansal kiralama, hayat sigortası veya bireysel hizmetler nedeniyle kurulan, niteliği itibariyle devamlılık unsuru taşıyan sürekli iş ilişkilerinde tutar gözetmeksizin kimliğe ilişkin bilgileri almak ve bu bilgilerin doğruluğunu teyit etmek suretiyle müşterilerin ve müşteri adına hareket edenlerin kimliğinin tespit edilmesi zorunludur.
            </p>
            <p>Bunun dışında, sürekli iş ilişkisi niteliği arz etmemekle birlikte;</p>
            <ul>
              <li>İşlem tutarı ya da birbirleriyle bağlantılı birden fazla işlemin toplam tutarı yüzseksenbeşbin TL veya üzeri olduğunda,</li>
              <li>Elektronik transferlerde işlem tutarı ya da birbiriyle bağlantılı birden fazla işlemin toplam tutarı onbeşbin TL veya üzeri olduğunda,</li>
              <li>Şüpheli işlem bildirimini gerektiren durumlarda tutar gözetmeksizin,</li>
              <li>Daha önce elde edilen müşteri kimlik bilgilerinin yeterliliği ve doğruluğu konusunda şüphe olduğunda tutar gözetmeksizin,</li>
            </ul>
            <p>
              Kimliğe ilişkin bilgilerin alınması ve bu bilgilerin doğruluğunu teyit etmek sureti ile müşterilerin veya müşterilerin adına veya hesabına hareket edenlerin kimliğinin tespit edilmesi zorunludur.
            </p>
            <p>
              İşlem talep eden kişiler, eğer işlemi başkası hesabına yapıyorlar ise, bunu işlemi yapmadan önce beyan etmek zorundadır. Bu beyan üzerine, işlemi talep edenin kimliği ve yetki durumu ile hesabına hareket edilen kişinin kimliği vb. Yönetmeliğin ilgili hükümlerine göre tespit edilir.
            </p>
            <p>
              Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanunun 15. Maddesi uyarınca, kimlik tespiti gereken işlemlerde, kendi adına ve fakat başkası hesabına hareket eden kimse, bu işlemleri yapmadan önce kimin hesabına hareket ettiğini yazılı olarak bildirmediği takdirde altı aydan bir yıla kadar hapis veya beşbin güne kadar adlî para cezasıyla cezalandırılır.
            </p>
            <p>
              Bu kapsamda, Şirketimiz nezdinde, 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun, ilgili yönetmelik ve tebliğler çerçevesinde kimlik tespiti gerektiren işlem yaptıran gerçek ve tüzel kişilerin başkası adına hareket etmeleri halinde, kimin hesabına hareket ettiklerini işlemi yapmadan önce Şirketimize yazılı olarak beyan etmeleri gerekmektedir.
            </p>
            <p>İşbu duyuru, Yönetmeliğin 17. Maddesi uyarınca yapılmaktadır.</p>
          </article>
        </>
      }   
]

const LegalInfos = () => {

    const { language } = useLanguage();

    const [activeAgr, setActiveAgr] = useState(2);

    const childrens = <main page="inside">

        <section element="legalinfos">

            <h2>Yasal Bilgiler</h2> 

            <div>
                <aside>

                    <ul>
                        {legalInfos.map((item) => {

                            if (item.link) {
                                return <li className={activeAgr === item.id ? 'active' : undefined}>
                                    <a
                                        href={item.link}
                                        target={'_blank'}
                                        title={item.title}
                                    >
                                        {item.title}
                                    </a>
                                </li>
                            } else {
                                return <li active={activeAgr === item.id ? 'active' : undefined}>
                                    <a
                                        href='#'
                                        onClick={() => {
                                            setActiveAgr(item.id);
                                        }}
                                        title={item.title}
                                    >
                                        {item.title}
                                    </a>
                                </li>
                            }


                        })}
                    </ul>

                </aside>
                <content>

                    {legalInfos.find(x => x.id == activeAgr)?.data}

                </content>
            </div>
        </section>

    </main>
    return <MainLayout children={childrens} />
};


export default LegalInfos;