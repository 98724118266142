// @ts-nocheck
import { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';
import { NodeBuilderFlags } from 'typescript';
import ProductApplicationModal from './modals/ProductApplication';
import ProductInformationModal from './modals/ProductInformationModal';
import { useRef } from "react";
import CardPage from './components/Cards';

const PageJson = {
    individualheader: [
        {
            title: <h1>AHL Pay ile yeni finansal özgürlüğünü keşfet</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Discover your <br /> new financial freedom</h1>,
            languageCode: 'en'
        }
    ],
    individualposter: [
        {
            title: <h2>Dilediğin gibi harca,<br /><span>yatırım yap ve para gönder.</span></h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>Spend, invest and<br />send money as you wish</h2>,
            languageCode: 'en'
        }
    ],
    productsTitle: [
        {
            title: <h2>Tüm ihtiyaçların<br />bir arada</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2> All your needs<br />in one place</h2>,
            languageCode: 'en'
        }
    ],
    word1: [
        {
            title: <h2>Tüm ihtiyaçların<br />bir arada</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2> All your needs<br />in one place</h2>,
            languageCode: 'en'
        }
    ],
    word2: [
        {
            title: <h2>Dilediğin Gibi</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>As You Wish</h2>,
            languageCode: 'en'
        }
    ],
    cardtitle1: [
        {
            title: <h3>Kıymetli Maden Alım ve Satımı</h3>,
            languageCode: 'tr'
        },
        {
            title: <h2> Precious Metals Buying and Selling with AHL Pay!</h2>,
            languageCode: 'en'
        }
    ],
    word3: [
        {
            title: <h2>Finansal özgürlüğünü şimdi keşfet</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>Discover your financial freedom now</h2>,
            languageCode: 'en'
        }
    ],
    word4: [
        {
            title: <h1>Fiziksel ya da sanal alışverişlerinizde harcama yaptığın kadar nakit iadelerden faydalan. Harcamalarınızda tasarruf edin. AHL Pay dünyasına katılın ! </h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Benefit from cashback as much as you spend on your physical or virtual shopping. Save money on your spending. Join the AHL Pay world!</h1>,
            languageCode: 'en'
        }
    ],

    cardtitle2: [
        {
            title: <h3> <h3>Bağışlarınız AHL Pay ile Güvende</h3></h3>,
            languageCode: 'tr'
        },
        {
            title: <h2>Your Donations are Secure with AHL Pay</h2>,
            languageCode: 'en'
        }
    ],
    cardtitle3: [
        {
            title: <><h3>Yatırımlarınız AHL Pay ile Çok Daha Hızlı</h3><blockquote>AHL Pay ile Yatırım yapmak artık çok kolay! Yatırımlarınızla finansal geleceğinizi güvenceye alın. Yatırımlarınız  AHL Pay ile tek tuşla yakınınızda</blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h2>Your Investments are Much Faster with AHL Pay</h2>,
            languageCode: 'en'
        }
    ],
    carddiscription1: [
        {
            title: <><h3>: AHL Pay hesabınız ile günün her saati avantajlı kur oranlarını kullanarak altın, platin ve gümüş alım-satım işlemlerinizi güven içinde yapın. Uygulamayı indirin, portföyünüzü değerli metallerle zenginleştirerek, yatırım seçeneklerinizi genişletin ve potansiyel getirelerinizi maksimize edin!</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1> With your AHL Pay account, use advantageous exchange rates 7/24 to buy and sell gold, platinum, and silver. Complete your transactions securely and in seconds with just one click.
                Download the app to enrich your precious metals portfolio, expand your investment options, and maximize your potential returns!</h1>,
            languageCode: 'en'
        }
    ],
    carddiscription2: [
        {
            title: <><h3>:Bağışlarınız AHL Pay ile  çeşitli kurum ve sivil toplum kuruluşlarının faaliyetlerine destek olmak amacıyla aracılık ediyoruz.Güvenilir bir şekilde kurumlara anında aktarılır. Hızlı, kolay ve ücretsiz bir şekilde bağışlarınızı gerçekleştirebilirsiniz. Bağışlarınızı istersen kendi adına istersen başkası adına gerçekleştirebilirsin.</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1>  AHL Pay facilitates donations to various institutions and non-governmental organizations supporting their activities. Donations are securely processed and transferred to the chosen institution instantly. The process is fast, easy, and free of charge. You can make donations either in your own name or on behalf of someone else.</h1>,
            languageCode: 'en'
        }
    ],
    carddiscription3: [
        {
            title: <><h3>AHL Pay ile Yatırım yapmak artık çok kolay! Yatırımlarınızla finansal geleceğinizi güvenceye alın. Yatırımlarınız  AHL Pay ile tek tuşla yakınınızda</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1> We’ve introduced a new approach with delivery to your address for physical gold and silver! AHL Pay users can now purchase gold and silver products through the mobile app, with insured delivery to the address specified in the order.
                Get physical gold and silver products at highly competitive prices on AHL Pay. Place your order through the AHL Pay app now for insured and prompt delivery to your doorstep.</h1>,
            languageCode: 'en'
        }
    ],
}

let productIdCounter = 1;
const products = [
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Yazar Kasa POS',
        headerTitle: 'Yazar Kasa POS',
        title: "AHL Pay Yazar Kasa POS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Kullanıcı dostu arayüzü ile Yazar Kasa POS, işletmelerin satış süreçlerini kolaylaştırır, hızlı ve güvenli ödeme seçenekleri sunar. ',
        description: 'AHL Pay Yazar Kasa POS, işletmenin ihtiyaçlarına özel çözümler sunar, finansal işlemlerini güvenli ve hızlı bir şekilde gerçekleştirmeni sağlar.',
        imagePath: 'assets/uploads/products/products/kurumsal_yazarkasapos.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Yazar Kasa POS</>,
        childrens: [
            {
                title: 'Tüm Satışlar Tek Noktada',
                desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, "haftanın 6 günü" avantajlı Altın-TL kuru ile anında altın al.'
            },
            {
                title: ' Anında Satış ve Memnuniyet',
                desc: 'Altın-TL işlemin sonucunda oluşan bakiyen ile herhangi bir kuyum ürününü avantajlı "milyem" oranlarıyla teslim al.'
            },
            {
                title: 'Kolayca Başvuru Hızlı Kurulum',
                desc: 'Başvurunu yap, seninle hemen iletişime geçelim.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Yazar Kasa POS',
        headerTitle: 'Yazar Kasa POS',
        title: "AHL Pay Yazar Kasa POS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Kullanıcı dostu arayüzü ile Yazar Kasa POS, işletmelerin satış süreçlerini kolaylaştırır, hızlı ve güvenli ödeme seçenekleri sunar. ',
        description: 'AHL Pay Yazar Kasa POS, işletmenin ihtiyaçlarına özel çözümler sunar, finansal işlemlerini güvenli ve hızlı bir şekilde gerçekleştirmeni sağlar.',
        imagePath: 'assets/uploads/products/products/kurumsal_yazarkasapos.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>Yazar Kasa POS</>,
        childrens: [
            {
                title: 'Tüm Satışlar Tek Noktada',
                desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, "haftanın 6 günü" avantajlı Altın-TL kuru ile anında altın al.'
            },
            {
                title: ' Anında Satış ve Memnuniyet',
                desc: 'Altın-TL işlemin sonucunda oluşan bakiyen ile herhangi bir kuyum ürününü avantajlı "milyem" oranlarıyla teslim al.'
            },
            {
                title: 'Kolayca Başvuru Hızlı Kurulum',
                desc: 'Başvurunu yap, seninle hemen iletişime geçelim.'
            }
        ]
    },
    {
        id: productIdCounter,
        pageTitle: 'AHL Pay AndroidPOS',
        headerTitle: 'AndroidPOS',
        title: "AHL Pay Android POS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Dijital çözümler sunarak her yerde hızlı ve güvenli ödeme al. Android POS ile işlemlerini kolaylaştır, hızlandır ve dijitalleştir. Satış, ödeme ve fatura işlemlerini birleştirerek daha etkin ve hızlı bir iş yönetim deneyimi sağla.',
        description: ' VUK 507 ve 509 (Vergi Usul Kanunu) tebliğine uygun olarak geliştirilen AHL Pay Android POS ile işletmenin satışlarını artırma ve büyüme potansiyelini maksimize et.D15:D16',
        imagePath: 'assets/uploads/products/products/kurumsal_androidpos.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Android POS</>,
        childrens: [
            {
                title: 'Güvenli ve Esnek Ödeme Seçenekleri',
                desc: 'Kıymetli Madenleriniz ile yatırımlarınız güvende'
            },
            {
                title: 'Etkili Ödeme İşlemleri',
                desc: 'Android POS ile ödemelerini anında al. Kullanıcı dostu arayüzü sayesinde, işlemleri hızlıca tamamlayarak müşterilerine kesintisiz bir deneyim sunar.'
            },
            {
                 title: 'Hızlı ve Güvenli Ödeme Alma',
                desc: 'Temassız özelliği sayesinde Android POS ile, kredi kartlarından, banka kartlarından veya ön ödemeli kartlardan güvenle ödeme al.'
            }
        ]
    },
    {
        id: productIdCounter,
        pageTitle: 'AHL Pay AndroidPOS',
        headerTitle: 'AndroidPOS',
        title: "AHL Pay Android POS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Dijital çözümler sunarak her yerde hızlı ve güvenli ödeme al. Android POS ile işlemlerini kolaylaştır, hızlandır ve dijitalleştir. Satış, ödeme ve fatura işlemlerini birleştirerek daha etkin ve hızlı bir iş yönetim deneyimi sağla.',
        description: ' VUK 507 ve 509 (Vergi Usul Kanunu) tebliğine uygun olarak geliştirilen AHL Pay Android POS ile işletmenin satışlarını artırma ve büyüme potansiyelini maksimize et.D15:D16',
        imagePath: 'assets/uploads/products/products/kurumsal_androidpos.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>Android POS</>,
        childrens: [
            {
                title: 'Güvenli ve Esnek Ödeme Seçenekleri',
                desc: 'Kıymetli Madenleriniz ile yatırımlarınız güvende'
            },
            {
                title: 'Etkili Ödeme İşlemleri',
                desc: 'Android POS ile ödemelerini anında al. Kullanıcı dostu arayüzü sayesinde, işlemleri hızlıca tamamlayarak müşterilerine kesintisiz bir deneyim sunar.'
            },
            {
                title: 'Hızlı ve Güvenli Ödeme Alma',
                desc: 'Temassız özelliği sayesinde Android POS ile, kredi kartlarından, banka kartlarından veya ön ödemeli kartlardan güvenle ödeme al.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay AltınPOS',
        headerTitle: 'AltınPOS',
        title: "AHL Pay AltınPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Tüm yabancı kartlarla ödeme al, birçok bankanın kredi kartı ile taksitli işlem gerçekleştir. AltınPos ile ödeme işlemini gerçekleştiren üye işyeri kuyumcu, oluşan altın bakiyesi ile, Altın-TL kuru üzerinden her türlü fiziksel altın ürünü temin edebilir.',
        description: ' Kuyumculara Özel POS ile, AndroidPOS üzerinden TL karşılığı Altın, Dolar, Euro alım-satım işlemlerini gerçekleştir.',
        imagePath: 'assets/uploads/products/products/kurumsal_altinpos.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>AltınPOS</>,
        childrens: [
            {
                title: 'Altın-TL Kuru İle Altın Alımı',
                desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, "haftanın 6 günü" avantajlı Altın-TL kuru ile anında altın al.'
            },
            {
                title: 'Tüm Kuyum Ürünlerinin Temini',
                desc: 'Altın-TL işlemin sonucunda oluşan bakiyen ile herhangi bir kuyum ürününü avantajlı "milyem" oranlarıyla teslim al.'
            },
            {
                title: 'Altın Transfer İşlemleri',
                desc: "Takasbank Altın Transfer Sistemi'ne üye bankalardaki altın hesaplarına altın transferi gerçekleştirebilirsin."
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay AltınPOS',
        headerTitle: 'AltınPOS',
        title: "AHL Pay AltınPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Tüm yabancı kartlarla ödeme al, birçok bankanın kredi kartı ile taksitli işlem gerçekleştir. AltınPos ile ödeme işlemini gerçekleştiren üye işyeri kuyumcu, oluşan altın bakiyesi ile, Altın-TL kuru üzerinden her türlü fiziksel altın ürünü temin edebilir.',
        description: ' Kuyumculara Özel POS ile, AndroidPOS üzerinden TL karşılığı Altın, Dolar, Euro alım-satım işlemlerini gerçekleştir.',
        imagePath: 'assets/uploads/products/products/kurumsal_altinpos.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>AltınPOS</>,
        childrens: [
            {
                title: 'Altın-TL Kuru İle Altın Alımı',
                desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, "haftanın 6 günü" avantajlı Altın-TL kuru ile anında altın al.'
            },
            {
                title: 'Tüm Kuyum Ürünlerinin Temini',
                desc: 'Altın-TL işlemin sonucunda oluşan bakiyen ile herhangi bir kuyum ürününü avantajlı "milyem" oranlarıyla teslim al.'
            },
            {
                title: 'Altın Transfer İşlemleri',
                desc: "Takasbank Altın Transfer Sistemi'ne üye bankalardaki altın hesaplarına altın transferi gerçekleştirebilirsin."
            }
        ]
    },
    
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay CepPOS',
        headerTitle: 'CepPOS',
        title: "AHL Pay CepPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Avantajlı komisyon oranları ile karını maksimize et; NFC, link, QR ve ya e-kart bilgisi ile ödeme al. AHL Pay CepPOS, yüksek güvenlik standartlarını karşılayarak müşterilerin için güvenli bir ödeme ortamı sağlar. ',
        description: ' Cep telefonundan ödeme almak artık çok daha hızlı. AHL Pay CepPOS ile dilediğin yerden Türk Lirası, Dolar ve Euro seçenekleri ile temassız ödeme al.',
        imagePath: 'assets/uploads/products/products/kurumsal_ceppos.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>CepPOS</>,
        childrens: [
            {
                title: 'Hızlı ve Kolay Ödeme Çözümleri',
                desc: 'İhtiyacın olan tüm özelliklere tek tıkla ulaşarak ödemelerini anında al. Mobil cihazını kullanarak, müşterilerine pratik ve hızlı bir ödeme deneyimi sun.'
            },
            {
                title: 'Lokasyon Bağımsız',
                desc: 'CepPOS ile dilediğin her yerde zaman kaybetmeden işlemlerini gerçekleştir.'
            },
            {
                title: 'Güvenli ve Şeffaf İşlemler',
                desc: 'CepPOS ile yapılan her işlem güvenilir ve şeffaf bir şekilde kaydedilir, müşterilerinin bilgileri her zaman korunur.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay CepPOS',
        headerTitle: 'CepPOS',
        title: "AHL Pay CepPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Avantajlı komisyon oranları ile karını maksimize et; NFC, link, QR ve ya e-kart bilgisi ile ödeme al. AHL Pay CepPOS, yüksek güvenlik standartlarını karşılayarak müşterilerin için güvenli bir ödeme ortamı sağlar. ',
        description: 'Cep telefonundan ödeme almak artık çok daha hızlı. AHL Pay CepPOS ile dilediğin yerden Türk Lirası, Dolar ve Euro seçenekleri ile temassız ödeme al.',
        imagePath: 'assets/uploads/products/products/kurumsal_ceppos.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>CepPOS</>,
        childrens: [
            {
                title: 'Hızlı ve Kolay Ödeme Çözümleri',
                desc: 'İhtiyacın olan tüm özelliklere tek tıkla ulaşarak ödemelerini anında al. Mobil cihazını kullanarak, müşterilerine pratik ve hızlı bir ödeme deneyimi sun.'
            },
            {
                title: 'Lokasyon Bağımsız',
                desc: 'CepPOS ile dilediğin her yerde zaman kaybetmeden işlemlerini gerçekleştir.'
            },
            {
                title: 'Güvenli ve Şeffaf İşlemler',
                desc: 'CepPOS ile yapılan her işlem güvenilir ve şeffaf bir şekilde kaydedilir, müşterilerinin bilgileri her zaman korunur.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay SanalPOS',
        headerTitle: 'SanalPOS',
        title: "AHL Pay SanalPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' AHL Pay Sanal Pos, işletmenin finansal işlemlerini sadeleştirirken, aynı zamanda hızlı, güvenli ve ekonomik bir çözüm sunar. Kullanıcılar, tek çekim ve taksit imkanları ile tüm işlemlerini tek bir panel üzerinden takip edebilir, Masterpass entegrasyonu ile tek bir tıkla kayıtlı kartlarını ödeme ekranında listeleyebilir.',
        description: 'Sanal POS, işletmene online ödeme imkanı sunar. Kullanıcı dostu arayüzü sayesinde, ödemelerini kolayca takip et. Güvenli ve hızlı işlemlerle müşterilerine keyifli bir alışveriş deneyimi yaşat',
        imagePath: 'assets/uploads/products/products/sanalpos_odeme_pc.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Sanal POS</>,
        childrens: [
            {
                title: 'Anında Ödeme Almanın Kolay Yolu',
                desc: 'Sanal POS ile ödemelerini dakikalar içinde al, düşük işlem ücretleri ile karlılığını artır.'
            },
            {
                title: ' Güvenli Ödeme Seçenekleri',
                desc: 'Gelişmiş güvenlik sistemleri ile her işlemde veri güvenliğini korur'
            },
            {
                title: 'Kullanıcı Dostu Arayüz',
                desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay SanalPOS',
        headerTitle: 'SanalPOS',
        title: "AHL Pay SanalPOS",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' AHL Pay Sanal POS, işletmenin finansal işlemlerini sadeleştirirken, aynı zamanda hızlı, güvenli ve ekonomik bir çözüm sunar. Kullanıcılar, tek çekim ve taksit imkanları ile tüm işlemlerini tek bir panel üzerinden takip edebilir, Masterpass entegrasyonu ile tek bir tıkla kayıtlı kartlarını ödeme ekranında listeleyebilir.',
        description: 'Sanal POS, işletmene online ödeme imkanı sunar. Kullanıcı dostu arayüzü sayesinde, ödemelerini kolayca takip et. Güvenli ve hızlı işlemlerle müşterilerine keyifli bir alışveriş deneyimi yaşat',
        imagePath: 'assets/uploads/products/products/sanalpos_odeme_pc.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>SanalPOS</>,
        childrens: [
            {
                title: 'Anında Ödeme Almanın Kolay Yolu',
                desc: 'Sanal POS ile ödemelerini dakikalar içinde al, düşük işlem ücretleri ile karlılığını artır.'
            },
            {
                title: ' Güvenli Ödeme Seçenekleri',
                desc: 'Gelişmiş güvenlik sistemleri ile her işlemde veri güvenliğini korur'
            },
            {
                title: 'Kullanıcı Dostu Arayüz',
                desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Kurumsal Hesap',
        headerTitle: 'Kurumsal Hesap',
        title: "AHL Pay Kurumsal Hesap",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL Pay Kurumsal Hesap ile, işletme harcamalarını daha etkin bir şekilde yönetebilir, ödemelerinizi hızlı bir şekilde gerçekleştirebilir ve nakit akışını optimize edebilirsin. Ayrıca, işletmene özel raporlama ve analiz araçları sayesinde mali durumunu detaylı bir şekilde izleme fırsatı bulursun.',
        description: ' İşletmenin finansal süreçlerini etkin bir şekilde yönet. Hızlı ve kolay işlemlerle, ödemelerini zamanında gerçekleştir. Gelişmiş güvenlik önlemleri sayesinde finansal bilgilerini koru.',
        imagePath: 'assets/uploads/products/phones/kurumsalhesap.png',
        languageCode: 'tr',
        title2: <><span>Kurumsal Hesap </span>Avantajları</>,
        childrens: [
            {
                title: 'Güvenli İşlemler',
                desc: 'Yüksek güvenlik standartları ile finansal bilgilerinin korunmasını sağla, dolandırıcılık riskini azalt.'
            },
            {
                title: 'Finansal Yönetim Kolaylığı',
                desc: 'Tüm ticari işlemlerini tek bir hesapta yöneterek, mali süreçlerini daha basit ve düzenli hale getir.'
            },
            {
                title: 'Raporlama',
                desc: 'Detaylı raporlama ile nakit akışını ve harcamalarını etkili bir şekilde takip et.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Kurumsal Hesap',
        headerTitle: 'Kurumsal Hesap',
        title: "AHL Pay Kurumsal Hesap",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL Pay Kurumsal Hesap ile, işletme harcamalarını daha etkin bir şekilde yönetebilir, ödemelerinizi hızlı bir şekilde gerçekleştirebilir ve nakit akışını optimize edebilirsin. Ayrıca, işletmene özel raporlama ve analiz araçları sayesinde mali durumunu detaylı bir şekilde izleme fırsatı bulursun.',
        description: ' İşletmenin finansal süreçlerini etkin bir şekilde yönet. Hızlı ve kolay işlemlerle, ödemelerini zamanında gerçekleştir. Gelişmiş güvenlik önlemleri sayesinde finansal bilgilerini koru.',
        imagePath: 'assets/uploads/products/phones/kurumsalhesap.png',
        languageCode: 'en',
        title2: <><span>Kurumsal Hesap </span>Avantajları</>,
        childrens: [
            {
                title: 'Güvenli İşlemler',
                desc: 'Yüksek güvenlik standartları ile finansal bilgilerinin korunmasını sağla, dolandırıcılık riskini azalt.'
            },
            {
                title: 'Finansal Yönetim Kolaylığı',
                desc: 'Tüm ticari işlemlerini tek bir hesapta yöneterek, mali süreçlerini daha basit ve düzenli hale getir.'
            },
            {
                title: 'Raporlama',
                desc: 'Detaylı raporlama ile nakit akışını ve harcamalarını etkili bir şekilde takip et.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Kurumsal Kart',
        headerTitle: 'Kurumsal Kart',
        title: "AHL Pay Kurumsal Kart",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Şirket içindeki finansal işlemleri daha verimli ve kontrol edilebilir hale getirin. AHL Pay Kurumsal Kart, şirket içi harcamalarında kullanabileceğin güvenli bir ödeme aracıdır.',
        description: 'İşletmenin finansal yönetimini kolaylaştır. Harcama limitleri ve detaylı raporlama imkanları sayesinde bütçe kontrolünü etkili bir şekilde sağla, mali süreçlerini planlı ve düzenli yürüt.',
        imagePath: 'assets/uploads/products/phones/Kurumsalkart.png',
        languageCode: 'tr',
        title2: <><span>AHL Pay </span>Kurumsal Kart</>,
        childrens: [
            {
                title: 'Kolaylık',
                desc: 'Harcamalarını hızlı ve pratik bir şekilde gerçekleştir.'
            },
            {
                title: 'Kontrol',
                desc: 'Harcama limitleri ve raporlama özellikleri sayesinde bütçeni etkin bir şekilde yönet.'
            },
            {
                title: 'Şeffaflık',
                desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay Kurumsal Kart',
        headerTitle: 'Kurumsal Kart',
        title: "AHL Pay Kurumsal Kart",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Şirket içindeki finansal işlemleri daha verimli ve kontrol edilebilir hale getirin. AHL Pay Kurumsal Kart, şirket içi harcamalarında kullanabileceğin güvenli bir ödeme aracıdır.',
        description: 'İşletmenin finansal yönetimini kolaylaştır. Harcama limitleri ve detaylı raporlama imkanları sayesinde bütçe kontrolünü etkili bir şekilde sağla, mali süreçlerini planlı ve düzenli yürüt.',
        imagePath: 'assets/uploads/products/phones/Kurumsalkart.png',
        languageCode: 'en',
        title2: <><span>AHL Pay </span>Kurumsal Kart</>,
        childrens: [
            {
                title: 'Kolaylık',
                desc: 'Harcamalarını hızlı ve pratik bir şekilde gerçekleştir.'
            },
            {
                title: 'Kontrol',
                desc: 'Harcama limitleri ve raporlama özellikleri sayesinde bütçeni etkin bir şekilde yönet.'
            },
            {
                title: 'Şeffaflık',
                desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
            }
        ]
    }
]

const ProductCorporateV2Page = () => {
    return <MainLayout children={_render()} />
};



const _render = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (id) => {
        setSelectedProduct(products.find(x => x.id === id));
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const [activeTab, setActiveTab] = useState("fees");
    const { language } = useLanguage();
    useEffect(() => {
        const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            const target = event.currentTarget;
            const goto = target.getAttribute('data-goto');
            if (goto) {
                document.querySelectorAll('[element=products-nav] ul li[active]').forEach(el => {
                    el.removeAttribute('active');
                });
                target.parentElement?.setAttribute('active', '');

                const scrollToElement = document.querySelector(`[element][data-goto="${goto}"]`);
                if (scrollToElement) {
                    window.scrollTo({
                        top: scrollToElement.getBoundingClientRect().top + window.scrollY - 180,
                        behavior: 'smooth'
                    });
                }
            }
        };

        document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
            el.addEventListener('click', handleNavClick);
        });

        return () => {
            document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
                el.removeEventListener('click', handleNavClick);
            });
        };
    }, []);

    const [pageUrl, setPageUrl] = useState(null);

    const handlePageUrl = (data) => {
        window.history.replaceState("", "", "products-corporate?p=" + data)
        setPageUrl(data);
    }

    const [selectedProductDetail, setSelectedProductDetail] = useState(products.filter(x => x.languageCode == language)[0]);

    useEffect(() => {

        const currentPTagValue = new URLSearchParams(window.location.search).get("p");

        if (currentPTagValue != undefined && currentPTagValue != null) {
            let tempCurrentProduct = products.find(x => x.languageCode == language && x.pageTitle == currentPTagValue);

            if (tempCurrentProduct) {
                setSelectedProductDetail(tempCurrentProduct);
            }
        }

    }, [pageUrl]);

    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen=()=>{
        setOpenModal(true);
    }


    return (
        <main page="inside"> 
            <ProductApplicationModal openModal={openModal} setOpenModal={setOpenModal}  selectedProductId={selectedProduct?.id} />
            <section element="products-nav" >
                <div>
                    <ul>
                        {products.filter(x => x.languageCode == language).map((itm) => {
                            return <li active={selectedProductDetail.id == itm.id ? 'true' : undefined}>
                                <a className="btn" onClick={() => handlePageUrl(itm.pageTitle)} title={itm.headerTitle}>
                                    {itm.headerTitle}
                                </a>
                            </li>
                        })}
                    </ul>
                </div>
            </section>

            {selectedProductDetail.pageTitle == "AHLCard" && <CardPage />}

            {selectedProductDetail.pageTitle != "AHLCard" && (<>
                <section element="individual-header">
                    <div>
                        <div>
                            <h1>{selectedProductDetail.title}</h1><br />
                            <span>{selectedProductDetail.shortDescription}</span>
                            <div>
                                <grid grid="individual-products">
                                    {selectedProductDetail.childrens?.map((itm) => {
                                        return <div
                                            grid-item=""
                                            solution-open="demo1"
                                        >
                                            <div>
                                                <h3>{itm.title}</h3><br />
                                                <blockquote>{itm.desc}</blockquote>
                                                {/* <a className="btn" onClick={() => handleModalOpen(selectedProductDetail.id)} title="Başvur">{PageJson.word5.find(x => x.languageCode === language)?.title}</a> */}
                                            </div>   
                                        </div>
                                        
                                    })}
                                    
                                </grid>
                                {/* <button onClick={handleModalOpen}>
                                              Başvuru
                                            </button> */}
                            </div>
                        </div>
                        <div>
                            <img src={selectedProductDetail.imagePath} alt="Bireysel Çözümlerimiz" />
                        </div>
                    </div>
                </section>



                <section element="individual-poster">
                    <div>
                        <article>
                            <h2>{selectedProductDetail.title2} </h2>
                            <blockquote>{selectedProductDetail.description}</blockquote>
                        </article>
                        <picture>
                            <img src="assets/uploads/products/posters/diledigin_gibi.png" alt="" />
                        </picture>
                    </div>
                </section>
            </>)}

            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>{PageJson.word3.find(x => x.languageCode === language)?.title}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>

        </main>
    );
}

export default ProductCorporateV2Page;