const PageKeys = {
    CONTACT_US: 'CONTACT_US',
    FAQ: 'FAQ',
    CAREER: 'CAREER',
    CAMPAIGNS: 'CAMPAIGNS',
    BLOGS: 'BLOGS',
    FEES_AND_LIMITS: 'FEES_AND_LIMITS',
    PRODUCTS_CORPORATE: 'PRODUCTS_CORPORATE',
    PRODUCTS_INDIVIDUAL: 'PRODUCTS_INDIVIDUAL',
    PRODUCTS_INDIVIDUAL_V2: 'PRODUCTS_INDIVIDUAL_V2'
}

export default PageKeys;