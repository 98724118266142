// @ts-nocheck
import { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';
import { NodeBuilderFlags } from 'typescript';
import ProductApplicationModal from './modals/ProductApplication';
import ProductInformationModal from './modals/ProductInformationModal';
import { useRef } from "react";
import CardPage from './components/Cards';
import { Outlet } from 'react-router-dom';



const PageJson = {
    individualheader: [
        {
            title: <h1>AHL Pay ile yeni finansal özgürlüğünü keşfet</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Discover your <br /> new financial freedom</h1>,
            languageCode: 'en'
        }
    ],
    individualposter: [
        {
            title: <h2>Dilediğin gibi harca,<br /><span>yatırım yap ve para gönder.</span></h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>Spend, invest and<br />send money as you wish</h2>,
            languageCode: 'en'
        }
    ],
    productsTitle: [
        {
            title: <h2>Tüm ihtiyaçların<br />bir arada</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2> All your needs<br />in one place</h2>,
            languageCode: 'en'
        }
    ],
    word1: [
        {
            title: <h2>Tüm ihtiyaçların<br />bir arada</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2> All your needs<br />in one place</h2>,
            languageCode: 'en'
        }
    ],
    word2: [
        {
            title: <h2>Dilediğin Gibi</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>As You Wish</h2>,
            languageCode: 'en'
        }
    ],
    cardtitle1: [
        {
            title: <h3>Kıymetli Maden Alım ve Satımı</h3>,
            languageCode: 'tr'
        },
        {
            title: <h2> Precious Metals Buying and Selling with AHL Pay!</h2>,
            languageCode: 'en'
        }
    ],
    word3: [
        {
            title: <h2>Finansal özgürlüğünü şimdi keşfet</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>Discover your financial freedom now</h2>,
            languageCode: 'en'
        }
    ],
    word4: [
        {
            title: <h1>Fiziksel ya da sanal alışverişlerinizde harcama yaptığın kadar nakit iadelerden faydalan. Harcamalarınızda tasarruf edin. AHL Pay dünyasına katılın ! </h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Benefit from cashback as much as you spend on your physical or virtual shopping. Save money on your spending. Join the AHL Pay world!</h1>,
            languageCode: 'en'
        }
    ],

    cardtitle2: [
        {
            title: <h3> <h3>Bağışlarınız AHL Pay ile Güvende</h3></h3>,
            languageCode: 'tr'
        },
        {
            title: <h2>Your Donations are Secure with AHL Pay</h2>,
            languageCode: 'en'
        }
    ],
    cardtitle3: [
        {
            title: <><h3>Yatırımlarınız AHL Pay ile Çok Daha Hızlı</h3><blockquote>AHL Pay ile Yatırım yapmak artık çok kolay! Yatırımlarınızla finansal geleceğinizi güvenceye alın. Yatırımlarınız  AHL Pay ile tek tuşla yakınınızda</blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h2>Your Investments are Much Faster with AHL Pay</h2>,
            languageCode: 'en'
        }
    ],
    carddiscription1: [
        {
            title: <><h3>: AHL Pay hesabınız ile günün her saati avantajlı kur oranlarını kullanarak altın, platin ve gümüş alım-satım işlemlerinizi güven içinde yapın. Uygulamayı indirin, portföyünüzü değerli metallerle zenginleştirerek, yatırım seçeneklerinizi genişletin ve potansiyel getirelerinizi maksimize edin!</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1> With your AHL Pay account, use advantageous exchange rates 7/24 to buy and sell gold, platinum, and silver. Complete your transactions securely and in seconds with just one click.
                Download the app to enrich your precious metals portfolio, expand your investment options, and maximize your potential returns!</h1>,
            languageCode: 'en'
        }
    ],
    carddiscription2: [
        {
            title: <><h3>:Bağışlarınız AHL Pay ile  çeşitli kurum ve sivil toplum kuruluşlarının faaliyetlerine destek olmak amacıyla aracılık ediyoruz.Güvenilir bir şekilde kurumlara anında aktarılır. Hızlı, kolay ve ücretsiz bir şekilde bağışlarınızı gerçekleştirebilirsiniz. Bağışlarınızı istersen kendi adına istersen başkası adına gerçekleştirebilirsin.</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1>  AHL Pay facilitates donations to various institutions and non-governmental organizations supporting their activities. Donations are securely processed and transferred to the chosen institution instantly. The process is fast, easy, and free of charge. You can make donations either in your own name or on behalf of someone else.</h1>,
            languageCode: 'en'
        }
    ],
    carddiscription3: [
        {
            title: <><h3>AHL Pay ile Yatırım yapmak artık çok kolay! Yatırımlarınızla finansal geleceğinizi güvenceye alın. Yatırımlarınız  AHL Pay ile tek tuşla yakınınızda</h3><blockquote></blockquote></>,
            languageCode: 'tr'
        },
        {
            title: <h1> We’ve introduced a new approach with delivery to your address for physical gold and silver! AHL Pay users can now purchase gold and silver products through the mobile app, with insured delivery to the address specified in the order.
                Get physical gold and silver products at highly competitive prices on AHL Pay. Place your order through the AHL Pay app now for insured and prompt delivery to your doorstep.</h1>,
            languageCode: 'en'
        }
    ],
}

let productIdCounter = 1;
const products = [
    {
        id: productIdCounter,
        pageTitle: 'KiymetliMaden',
        headerTitle: 'Kıymetli Maden',
        title: "Kıymetli Maden Alım Satımı AHL Pay'de !",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL Pay hesabın ile günün her saati avantajlı kur oranlarını kullanarak altın, platin ve gümüş alım-satım işlemlerini güven içinde yap.',
        description: ' AHL Pay hesabın ile günün her saati avantajlı kur oranlarını kullanarak altın, platin ve gümüş alım-satım işlemlerini güven içinde yap. Uygulamayı indir, portföyünü değerli madenlerle zenginleştir, yatırım seçeneklerini genişlet ve potansiyel getirelerini maksimize et!',
        imagePath: 'assets/uploads/products/phones/kiymetlimaden.svg',
        languageCode: 'tr',
        title2: <><span>Kıymetli Maden -</span> Alım Satım</>,
        childrens: [
            {
                title: 'Hemen Yatırıma Başla',
                desc: 'Günün her saati avantajlı oranlar'
            },
            {
                title: 'Hızlı ve Güvenli',
                desc: 'Hızlı maden al-sat işlemleri, güvenli yatırım'
            },
            {
                title: '7/24 güncel kurlarla işlem Yap',
                desc: 'Güncel kurlarla hesaplanan gram altın, gümüş ve platin fiyatlarıyla 7/24 yatırım yap'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'KiymetliMaden',
        headerTitle: 'Precious Metals',
        title: "Precious Metals Buying and Selling with AHL Pay!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'With your AHL Pay account, use advantageous exchange rates 7/24 to buy and sell gold, platinum, and silver.',
        description: 'With AHL Pay, you can make gold and silver investments with advantageous offers at live rates.',
        imagePath: 'assets/uploads/products/phones/kiymetlimaden.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHLCard',
        headerTitle: 'AHL Card',
        title: "AHL Pay ile Ödeme Deneyimi: Sanal Kartınızla Tanışın!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Dijital dünyada güvenli ve pratik ödeme yapmanın keyfini çıkarın! Sanal kartınızla, alışverişlerinizde kişisel bilgilerinizi koruyarak hızlı ve sorunsuz işlemler gerçekleştirin.',
        description: "Dijital dünyada güvenli ve pratik ödeme yapmanın keyfini çıkarın! Sanal kartınızla, alışverişlerinizde kişisel bilgilerinizi koruyarak hızlı ve sorunsuz işlemler gerçekleştirin. Anında oluşturabileceğiniz sanal kartınız ile hem internet alışverişlerinizde hem de abonelik hizmetlerinde güvenliğinizi sağlayın. AHL Pay Sanal Kart her anınızda yanınızda, kullanılmaya hazır !",
        imagePath: 'assets/uploads/products/icons/card_phone.svg',
        languageCode: 'tr',
        childrens: [
            {
                title: 'Her Şey Kontrolün Altında',
                desc: 'AHL Card ile her şey elinizde ve kontrol sizde finans harcamalarınızı güvenle şekillendirebilirsin'
            },
            {
                title: 'Dilediğin Gibi Harca Bütçeni Aşma',
                desc: 'Yurtiçi ve online harcamalarını dilediğin gibi harcayabilir, bütçeni hiç bir zaman aşmazsın'
            },
            {
                title: 'Harcadıkça Cashback Kazan',
                desc: 'AHL Card ile yaptığın harcamalarda cashback kazanma fırsatı yakalayabilirsin'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHLCard',
        headerTitle: 'AHL Card',
        title: "AHL Pay Payment Experience: Meet Your Virtual Card!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Enjoy the convenience and security of making payments in the digital world! With your virtual card, perform quick and seamless transactions while protecting your personal information',
        description: 'Enjoy the convenience and security of making payments in the digital world! With your virtual card, perform quick and seamless transactions while protecting your personal information. Ensure your security in both online purchases and subscription services with a virtual card that you can create instantly. AHL Pay Virtual Card is with you at all times, ready for use!',
        imagePath: 'assets/uploads/products/icons/card_phone.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'FizikiAltinGumus',
        headerTitle: 'Fiziki Altın/Gümüş',
        title: "Fiziki Altın ve Fiziki Gümüş Satışı İlk Kez AHL Pay'de !",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: "Bir ilke imza attık, adrese teslim fiziki altın ve gümüş satışlarına başladık AHL Pay kullanıcılarının mobil uygulama üzerinden satın aldıkları altın ve gümüş ürünler, siparişte belirtilen adrese sigortalı olarak teslim ediliyor. Fiziki altın ve gümüş ürünleri çok avantajlı fiyatlarla AHL Pay'de. Hemen AHL Pay uygulaması üzerinden siparişinizi oluşturun, teslimatınız sigortalı ve hızlı bir şekilde kapınıza gelsin.",
        description: "AHL Pay uygulaması üzerinden fiziki altın ve gümüş alımı yapabilirsin. AHL Pay ile anlık fiyatlarla altın ve gümüş alabilirsin. Aldığın fiziki altın ve gümüş adresine teslimatı ve güvenliği konusunda da gönül rahatlığıyla bilgi alabilir, ödemelerini kredi kartı veya hesap bakiyesinden işlemlerini gerçekleştirebilirsin. Bu avantaj ile, alışveriş deneyimini kolaylaştırarak  tercihine göre en uygun ödeme yöntemini seçebilirsin.",
        imagePath: 'assets/uploads/products/phones/fiziki_phone.svg',
        languageCode: 'tr',
        title2: <><span>Fiziki Altın ve Gümüş</span> Satışı</>,
        childrens: [
            {
                title: 'Adrese Teslim',
                desc: 'Fiziki Altın ve Gümüşlerin adresine teslim edilsin'
            },
            {
                title: 'Avantajlı Fiyat',
                desc: 'Avantajlı fiyatlar ile yatırım yap'
            },
            {
                title: 'Yatırımda Güvenli Liman',
                desc: 'Siparişin sana ulaşana kadar Ahlatcı Kuyumculuk sigortası altında'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'PhysicalGoldSilder',
        headerTitle: 'Physical Gold/Sliver',
        title: " Physical Gold and Silver Sales for the First Time with AHL Pay!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'We’ve introduced a new approach with delivery to your address for physical gold and silver! AHL Pay users can now purchase gold and silver products through the mobile app, with insured delivery to the address specified in the order.Get physical gold and silver products at highly competitive prices on AHL Pay. Place your order through the AHL Pay app now for insured and prompt delivery to your doorstep.',
        description: 'We’ve introduced a new approach with delivery to your address for physical gold and silver! AHL Pay users can now purchase gold and silver products through the mobile app, with insured delivery to the address specified in the order. Get physical gold and silver products at highly competitive prices on AHL Pay. Place your order through the AHL Pay app now for insured and prompt delivery to your doorstep.',
        imagePath: 'assets/uploads/products/phones/fiziki_phone.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: "AHL Pay'den bir ilk: ATOM Hesapta",
        headerTitle: 'ATOM',
        title: "AHL Pay'den bir ilk: ATOM Hesapta",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'ATOM Hesapta ile anlaşmalı kuyumcumlara teslim ettiğin altının, kuyumcuya teslim edildiği anda ilgili finans kurumundaki altın hesabına yatar.',
        description: "Altınını fiziki saklamak yerine AHL Pay uygulamasındaki altın hesabında tutarak finansal piyasaların avantajlarından yararlanabilirsin. ATOM Hesapta uygulaması milyem avantajları içerir, birikiminden kayıp yaşamanı engeller. Sadece gram altın ve cumhuriyet altını gibi standart altın türleri değil, bilezik, yüzük, küpe, kolye gibi tüm kuyum ürünlerini anlaşmalı kuyumculara getir, ATOM Hesapta ile AHL Pay uygulamandaki altın hesabına sadece birkaç saniye içerisinde aktarılsın. Ayrıca birlikte çalıştığımız kuyumcularımızı <a href='assets/images/KuyumcuListemiz.pdf' style='color: blue;' download>görmek için tıkla</a>.",
        imagePath: 'assets/uploads/products/phones/ATOM.png',
        title2: <> <span>ATOM </span>Hesapta</>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Anında Transfer',
                desc: "Tüm kuyum ürünleri saniyeler içinde altın hesabında"
            },
            {
                title: 'Avantajlı Kur',
                desc: 'Altın-TL kuru ile Türk lirasına çevirme imkanı'
            },
            {
                title: 'Güvenli İşlem',
                desc: 'İşlemleriniz Ahlatcı Metal Rafineri Güvencesi ile koruma altında'
            }
        ]
    },
    
    {
       
        id: ++productIdCounter,
        pageTitle: "AHL Pay'den bir ilk: ATOM Hesapta",
        headerTitle: 'ATOM',
        title: "AHL Pay'den bir ilk: ATOM Hesapta",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' ATOM Hesapta ile anlaşmalı kuyumcumlara teslim ettiğin altının, kuyumcuya teslim edildiği anda ilgili finans kurumundaki altın hesabına yatar.',
        description: "Altınını fiziki saklamak yerine AHL Pay uygulamasındaki altın hesabında tutarak finansal piyasaların avantajlarından yararlanabilirsin. ATOM Hesapta uygulaması milyem avantajları içerir, birikiminden kayıp yaşamanı engeller. Sadece gram altın ve cumhuriyet altını gibi standart altın türleri değil, bilezik, yüzük, küpe, kolye gibi tüm kuyum ürünlerini anlaşmalı kuyumculara getir, ATOM Hesapta ile AHL Pay uygulamandaki altın hesabına sadece birkaç saniye içerisinde aktarılsın. ",
        imagePath: 'assets/uploads/products/phones/ATOM.png',
        title2: <> <span>ATOM </span>Hesapta</>,
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'ParaGonder',
        headerTitle: 'Para Gönder',
        title: "AHL Pay ile Para Gönder",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL PAY hesabından diğer banka hesaplarına hızlı ve güvenle para transferi yapabilirsin. Ayrıca, AHL Pay hesabından tüm AHL Pay üyelerine telefon/cüzdan numaraları veya dilersen QR kod ile para gönderebilirsin.',
        description: "Banka hesabına para göndermek için uygulama üzerinden Transferler ve ardından Para Gönder'e tıkla. Para göndermek istediğin yöntemi seç ve göndermek istediğin tutarı belirle, tutar anında hesabına geçsin. Ayrıca AHL Pay kullanıcılarına, QR Kod, Cüzdan Numarası, Telefon numarası ve Kart Numarası ile para gönderebilirsin.",
        imagePath: 'assets/uploads/products/phones/odemeiste_phone.svg',
        title2: <>Nasıl<span> Para Gönderirim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Anında Hesabında',
                desc: 'Yapacağın para transferi anında hesabında'
            },
            {
                title: '7/24 Para Transferi',
                desc: ' Hafta içi veya hafta sonu, para transferini dilediğin zaman yap'
            },
            {
                title: 'Hızlı ve Güvenilir',
                desc: 'Hızlı ve güvenli bir şekilde para transferi gerçekleştir'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'AHL Pay ile Para Yükle',
        headerTitle: 'Para Yükle',
        title: "AHL Pay ile Para Yükle",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Para Yükleme işlemi ile finansal özgürlüğünü kontrol altında tut. Online bankacılık işlemlerini kolaylaştır ve paranı her zaman, her yerde kullanıma hazır hale getir.',
        description: "Transferler ekranında yer alan Para Yükle seçeneğine tıklayarak, karşına gelen bilgiler ile, şahsi banka hesabından AHL Pay hesabına para yükleme işlemini güvenle yapabilirsin. Para yükleme işlemi anında gerçekleşir, bu sayede paran hesabında hemen kullanıma hazır hale gelir. Bakiyeni mobil ödemelerinde, online alışverişlerinde veya fatura ödemelerinde rahatlıkla kullanabilirsin",
        imagePath: 'assets/uploads/products/phones/parayukle.svg',
        title2: <>Nasıl<span> Para yüklerim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Kolayca Para Yükle',
                desc: "Banka hesaplarından ve ya AHL Card ile tüm ATM'lerden para yükle"
            },
            {
                title: 'Hızlı ve Güvenli',
                desc: 'Güvenle para yatır, anında hesabına geçsin'
            },
            {
                title: 'Keyifle Harca',
                desc: 'Bakiyeni yurt içindeki alışverişlerinde ve e-ticaret sitelerinde kullan'
            }
        ]
    },
    {
       
        id: ++productIdCounter,
        pageTitle: 'AHL Pay ile Para Yükle',
        headerTitle: 'Para Yükle',
        title: "AHL Pay ile Para Yükle",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Para Yükleme işlemi ile finansal özgürlüğünü kontrol altında tut. Online bankacılık işlemlerini kolaylaştır ve paranı her zaman, her yerde kullanıma hazır hale getir.',
        description: "Transferler ekranında yer alan Para Yükle seçeneğine tıklayarak, karşına gelen bilgiler ile, şahsi banka hesabından AHL Pay hesabına para yükleme işlemini güvenle yapabilirsin. Para yükleme işlemi anında gerçekleşir, bu sayede paran hesabında hemen kullanıma hazır hale gelir. Bakiyeni mobil ödemelerinde, online alışverişlerinde veya fatura ödemelerinde rahatlıkla kullanabilirsin",
        imagePath: 'assets/uploads/products/phones/parayukle.svg',
        title2: <>Nasıl<span> Para yüklerim?</span></>,
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'SendMoney',
        headerTitle: 'Send Money',
        title: "Send Money with Retail Wallet",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' You can quickly and easily send money to your own bank account or to other AHL Pay users.',
        description: 'You can quickly and easily send money to your own bank account or to other AHL Pay users.',
        imagePath: 'assets/uploads/products/phones/odemeiste_phone.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'ParaIste',
        headerTitle: 'Para İste',
        title: "AHL Pay ile Para İste",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'İhtiyaç anında hızlı ve kolayca para talep et, talebin onaylanır onaylanmaz paran hesabına geçsin. AHL Pay, Para İste özelliği ile, ihtiyaç duyduğunuz anda size hızlı ve pratik bir çözüm sunar.',
        description: "Transferler ve ardından Para iste ekranına tıkla, Para İste yöntemini seç (Cüzdan Numarası, Telefon Numarası veya QR ile) ve isteyeceğin para tutarını yaz, talebi oluştur. Karşı taraf talebini onayladıktan sonra, tutar hesabına anında geçsin.",
        imagePath: 'assets/uploads/products/phones/paragonder_phone.svg',
        title2: <>Nasıl<span> Para İsterim?</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Hesap Numarası ile para iste',
                desc: 'Talep oluşturacağın hesap numarasını yaz, karşı tarafa hemen yansısın.'
            },
            {
                title: 'Telefon Numarası ile para iste',
                desc: ' Para isteme işlemini, kullanıcının telefon numarasını girerek veya rehberinden seçerek kolayca gerçekleştir.'
            },
            {
                title: ' Talebin onaylanır onaylanmaz tutar hesabında',
                desc: ' Karşı taraf talebini onaylar onaylamaz tutar anında hesabına geçsin.'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'RequestMoney',
        headerTitle: 'Request Money',
        title: "Request Money with Retail Wallet",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'Quickly request money from your friends when needed, and have the funds transferred to your account as soon as your request is approved.',
        description: 'Quickly request money from your friends when needed, and have the funds transferred to your account as soon as your request is approved.',
        imagePath: 'assets/uploads/products/phones/paragonder_phone.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'FaturaOde',
        headerTitle: 'Fatura Öde',
        title: "Bireysel hesabınız ile hızlı ve güvenle Faturalarını Ödeyin!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL Pay Bireysel Hesap, fatura ödemelerini hızlı, güvenli ve sorunsuz bir şekilde gerçekleştirebileceğin, mükemmel bir çözüm sunar.',
        description: "AHL Pay Bireysel Hesap, finansal işlemlerinin karmaşıklığını azaltır ve zamandan tasarruf etmeni sağlar. Ayrıca, faturalarını www.benimfaturam.com.tr üzerinden tüm banka kartları ve anlaşmalı kredi kartları ile taksitli olarak ödeyebilirsin.",
        imagePath: 'assets/uploads/products/phones/faturaode_phone.svg',
        title2: <>AHL Pay ile <span>Fatura Öde</span></>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Faturalarını Sorgula',
                desc: 'AHL Pay ile faturalarını hızlıca sorgula'
            },
            {
                title: 'Hızlı ve Güvenli',
                desc: ' Fatura işlemlerini güvenli ve sorunsuz gerçekleştir'
            },
            {
                title: 'Aynı Anda Birçok Faturayı Öde',
                desc: 'Faturalarını AHL Pay ile tek bir kanaldan anında öde'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'BillPayment',
        headerTitle: 'Bill Payment',
        title: "Pay Your Bills Quickly and Securely with the AHL Pay Personal Wallet!",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'The AHL Pay Reatail Wallet offers an excellent solution for paying your bills quickly, securely, and seamlessly. ',
        description: 'AHL Pay Reatail Wallet offers an excellent solution for paying your bills quickly, securely, and seamlessly. This service reduces the complexity of your financial transactions and allows you to save valuable time. You can pay your  bills with just a few clicks at www.benimfaturam.com.tr',
        imagePath: 'assets/uploads/products/phones/faturaode_phone.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        title: "Yatırımlarınız AHL Pay ile Çok Daha Hızlı",
        pageTitle: 'Yatirim',
        headerTitle: 'Yatırım',
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'AHL Pay ile yatırım yapmak artık çok kolay! Yatırımlarınla finansal geleceğini güvence altına al. ',
        description: " AHL Pay hesabın üzerinden yatırım yapabilir, finansal işlemleri daha pratik hale getirebilirsin. Fon alım-satım veya hisse senetlerine kolayca erişim ile, hem yeni başlayanlar hem de deneyimli yatırımcılar için kullanıcı dostu bir arayüz sunan AHL Pay ile yatırımların artık daha hızlı.",
        imagePath: 'assets/uploads/products/icons/yatirim.svg',
        languageCode: 'tr',
        title2: <><span>Yatırımın</span> AHL Pay ile  Güvende </>,
        childrens: [
            {
                title: 'Akıllı Yatırım güvenli getiri',
                desc: 'Yapacağınız akıllı yatırım ile getirilerin güvenle tek tuşla yanında'
            },
            {
                title: 'Çeşitli ve Esnek Yatırım Araçları',
                desc: 'Geniş yatırım ürünleri portföyü'
            },
            {
                title: 'Kullanıcı Dostu Arayüz, İşlem Kolaylığı',
                desc: 'Hızlı ve verimli bir yatırım deneyimi'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'Investment',
        headerTitle: 'Investments',
        title: "Your Investments are Much Faster with AHL Pay",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' Investing with AHL Pay is now effortless! With AHL Pay, your investments are easily accessible with just a tap.',
        description: 'Investing with AHL Pay is now effortless! With AHL Pay, your investments are easily accessible with just a tap.',
        imagePath: 'assets/uploads/products/icons/yatirim.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        title: "Oyun & Dijital Kod",
        pageTitle: 'OyunDijitalKod',
        headerTitle: 'Oyun & Dijital Kod',
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        description: 'Dijital Oyun Kodları, Abonelikler, Hediye Çekleri ve daha fazlası AHL Pay Bireysel Hesabında!',
        shortDescription: 'Dijital Oyun Kodları, Abonelikler, Hediye Çekleri ve daha fazlasını AHL Pay uygulamamızdan kolayca satın alabilir, anında kullanmaya başlayabilirsin.',
        imagePath: 'assets/uploads/products/phones/oyunhd.svg',
        title2: <> <span>Oyun ve Dijital</span> Kod</>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Eğlenceye Adım At',
                desc: ' PUBG, Wolfteam ve onlarca oyun için dijital kod satın al'
            },
            {
                title: 'Platform Ödemeleri',
                desc: 'Fizy, TV+ gibi birçok platform aboneliğini tek kanaldan öde'
            },
            {
                title: 'Hediye Çeki',
                desc: 'Hediyen Kart ve Milli Piyango kuponları satın al'
            }
        ]
    },
    {
        id: ++productIdCounter,
        title: "Games & Digital Codes",
        pageTitle: 'GamesDigitalCode',
        headerTitle: 'Games & Digital Code',
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: 'You can easily purchase through the app and start using your digital products instantly.',
        description: 'Digital Game Codes, Subscriptions, Gift Vouchers, and more are available in your AHL Pay Personal Wallet!',
        imagePath: 'assets/uploads/products/phones/oyunhd.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'Bagis',
        headerTitle: 'Bağış',
        title: " Güvenle Bağış Yapın",
        icon: 'assets/uploads/products/icons/icon_bagislariniz_ahlpay_ile_guvende.png',
        shortDescription: ' AHL Pay ile dilediğiniz STK ve kurumlara hızlı ve güvenli şekilde bağış yapabilirsiniz.',
        description: ' Güvenilir bir şekilde kurumlara anında aktarılır. Hızlı, kolay ve ücretsiz bir şekilde bağışlarınızı gerçekleştirebilirsiniz. Bağışlarınızı istersen kendi adına istersen başkası adına gerçekleştirebilirsin.',
        imagePath: 'assets/uploads/products/phones/bagis.svg',
        languageCode: 'tr',
        title2: <>AHL Pay ile <span>Bağış</span></>,
        childrens: [
            {
                title: 'Bir Tıkla Bağış Yap',
                desc: 'AHL Pay ile tek bir tuşla bağış yapabilir sevdiklerinizle paylaşabilirsiniz'
            },
            {
                title: 'İster Kendi İster Başkası Adına',
                desc: 'Bağışlarınızı istersen kendi adına istersen başkası adına gerçekleştirebilirsin'
            },
            {
                title: 'Dilediğin Gibi Güvenle Bağış',
                desc: 'AHL Pay ile bağışlarınız güvende'
            }
        ]
    },
    {
        id: ++productIdCounter,
        pageTitle: 'Donation',
        headerTitle: 'Donate',
        title: "Donate with Confidence",
        icon: 'assets/uploads/products/icons/icon_bagislariniz_ahlpay_ile_guvende.png',
        shortDescription: 'Your Donations are Secure with AHL Pay Donations are securely processed and transferred to the chosen institution instantly. The process is fast, easy, and free of charge.',
        description: 'With AHL Pay, you can make donations to your preferred NGOs and institutions quickly and securely.',
        imagePath: 'assets/uploads/products/phones/bagis.svg',
        languageCode: 'en'
    },
    {
        id: ++productIdCounter,
        pageTitle: 'GuvenceBedeli',
        headerTitle: 'Güvence Bedeli',
        title: " Güvence Bedel İadesi Hesabınızda",
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin.',
        description: "Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin. Güvence Bedeli İadesi Süreci: En yakın Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz , Marmargaz Yalova ve Marmaragaz Çorlu şubesine giderek abonelik sözleşmenin feshi için başvur. Cep telefonuna AHL Pay uygulamasını indir, kolayca hesap oluştur ve hesabını Onaylı statüne getir. Onaylı müşteri olduktan sonra oluşturulan IBAN bilgilerini gişe görevlisi ile paylaş. İade işlemi tamamlandığında, AHL Pay uygulamamız üzerinden anında bildirim al, tutar hesabına yansısın.",
        imagePath: 'assets/uploads/products/phones/iban_phone.svg',
        languageCode: 'tr',
        title2: <><span>Güvence Bedel </span>İadesi</>,
        childrens: [
            {
                title: 'Hızlı ve Kolay !',
                desc: ' AHL Pay hesabına hızlı ve kolay iade'
            },
            {
                title: 'Birden Fazla Kurum',
                desc: 'Dilediğin kurumun güvence bedel iadenisini AHL Pay üzerinden alabilirsin'
            },
            {
                title: 'Bedel İaden Güvende',
                desc: 'Bedel iadeni AHL Pay aracılığıyla güvenle geri al'
            }
        ]
    },
    {
        id: ++productIdCounter,
        title: "Security Deposit Refund",
        pageTitle: 'SecurityDeposit',
        headerTitle: 'Security Deposit',
        icon: 'assets/uploads/products/icons/icon-kiymetli_maden.png',
        shortDescription: ' When you terminate your subscription with Çorum Doğalgaz, Kargaz Doğalgaz, or Sürmeli Doğalgaz, you can quickly and securely receive your security deposit refund via AHL Pay ',
        description: "When you terminate your subscription with Çorum Doğalgaz, Kargaz Doğalgaz, or Sürmeli Doğalgaz, you can quickly and securely receive your security deposit refund via AHL Pay. Your refund will be processed within fifteen days from the date of your subscription cancellation request.Security Deposit Refund Process: Application: Visit the nearest Çorum Doğalgaz, Kargaz Doğalgaz, or Sürmeli Doğalgaz branch to apply for the termination of your subscription contract.Download the App: Download the AHL Pay app on your mobile phone and create an account easily.Verify Your Account: Log in to the app and complete the Verify Your Identity section by providing TC identity verification, facial recognition, and transferring 1 TL from your bank account to achieve approved customer status.Share Your IBAN Information: Once you are an approved customer, share the generated IBAN information with the branch clerk.<br>Instant Notification: When the refund process is completed, you will receive an instant notification via the AHL Pay app and see the refund amount in your AHL Pay wallet.",
        imagePath: 'assets/uploads/products/phones/iban_phone.svg',
        languageCode: 'en'
    }
]

const ProductIndividualV2Page = () => {
    return <> 
    <MainLayout children={_render()} />
    </>;
};

const _render = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (id) => {
        setSelectedProduct(products.find(x => x.id === id));
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    

    const [activeTab, setActiveTab] = useState("fees");
    const { language } = useLanguage();

    useEffect(() => {
        const handleNavClick = (event) => {
            event.preventDefault();
            const target = event.currentTarget;
            const goto = target.getAttribute('data-goto');
            if (goto) {
                document.querySelectorAll('[element=products-nav] ul li[active]').forEach(el => {
                    el.removeAttribute('active');
                });
                target.parentElement?.setAttribute('active', '');

                const scrollToElement = document.querySelector(`[element][data-goto="${goto}"]`);
                if (scrollToElement) {
                    window.scrollTo({
                        top: scrollToElement.getBoundingClientRect().top + window.scrollY - 180,
                        behavior: 'smooth'
                    });
                }
            }
        };

        document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
            el.addEventListener('click', handleNavClick);
        });

        return () => {
            document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
                el.removeEventListener('click', handleNavClick);
            });
        };
    }, []);

    const [pageUrl, setPageUrl] = useState(null);

    const handlePageUrl = (data) => {
        window.history.replaceState("", "", "products-individual?p=" + data);
        setPageUrl(data);
    };

    const [selectedProductDetail, setSelectedProductDetail] = useState(
        products.filter(x => x.languageCode === language)[0]
    );

    useEffect(() => {
        const currentPTagValue = new URLSearchParams(window.location.search).get("p");
        if (currentPTagValue !== undefined && currentPTagValue !== null) {
            let tempCurrentProduct = products.find(
                x => x.languageCode === language && x.pageTitle === currentPTagValue
            );

            if (tempCurrentProduct) {
                setSelectedProductDetail(tempCurrentProduct);
            }
        }
    }, [pageUrl, language, products]);

    return (
        <main page="inside">
            <section element="products-nav">
                <div>
                    <ul>
                        {products.filter(x => x.languageCode === language).map((itm) => {
                            return (
                                <li
                                    key={itm.id}
                                    active={selectedProductDetail.id === itm.id ? 'true' : undefined}
                                >
                                    <a
                                        className="btn"
                                        onClick={() => handlePageUrl(itm.pageTitle)}
                                        title={itm.headerTitle}
                                    >
                                        {itm.headerTitle}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>

            {selectedProductDetail.pageTitle === "AHLCard" && <CardPage />}

            {selectedProductDetail.pageTitle !== "AHLCard" && (
                <>
                    <section element="individual-header">
                        <div>
                            <div>
                                <h1>{selectedProductDetail.title}</h1><br />
                                <span>{selectedProductDetail.shortDescription}</span>
                                <br />
                                <div>
                                    <grid grid="individual-products">
                                        {selectedProductDetail.childrens?.map((itm) => (
                                            <div
                                                grid-item=""
                                                solution-open="demo1"
                                                key={itm.title}
                                            >
                                                <div>
                                                    <h3>{itm.title}</h3>
                                                    {/* Description alanını HTML olarak işleme */}
                                                    <blockquote
                                                        dangerouslySetInnerHTML={{
                                                            __html: itm.desc
                                                        }}
                                                    ></blockquote>
                                                </div>
                                            </div>
                                        ))}
                                    </grid>
                                </div>
                            </div>
                            <div>
                                <img
                                    src={selectedProductDetail.imagePath}
                                    alt="Bireysel Çözümlerimiz"
                                />
                            </div>
                        </div>
                    </section>

                    <section element="individual-poster">
                        <div>
                            <article>
                                <h2>{selectedProductDetail.title2}</h2>
                                {/* Description alanını HTML olarak işleme */}
                                <blockquote
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProductDetail.description
                                    }}
                                ></blockquote>
                            </article>
                            <picture>
                                <img
                                    src="assets/uploads/products/posters/diledigin_gibi.png"
                                    alt=""
                                />
                            </picture>
                        </div>
                    </section>
                </>
            )}

            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>{PageJson.word3.find(x => x.languageCode === language)?.title}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>

        </main>
    );
};

export default ProductIndividualV2Page;