// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const campaigns = [
  {
    "id": 19,
    "title": "* 23.10.2024 GS - ELF Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray- Elfsborg Maç Bileti Talihlileri:\n\nASİL \n1- MEHMET MUSTAFA AKGÜN\n2- HASAN SAMET ÖZDEMİR\n3- VEDAT SAĞLAM\n4- ÇETİN SÜRUR\n5- HAYALSU SEVVAL HALEFOGLU\n6- MEHMET EMİN TURAN\n7- ETHEM TEKŞEN\n8- ERKAN ULUSOY\n9- UĞUR ÖZKAN\n10- ERKAN TASIM\n11- Hasan AŞAN\n12- DENIZ KARADERELI\n13- FATMA ÜNAL\n14- ALİ SAYGI\n\nYEDEK \n1- FERHAT ASLAN\n2- MURAT ÇELİK\n3- MERT ŞENOL\n4- ALICAN YILMAZ\n5- MUSTAFA FURKAN GÜLER\n6- MEHMET EMİN BAYKARA\n7- ADNAN MALÇOK\n8- VOLKAN BATAR\n9- FATİH TÜSÜZ\n10- SAMET CANPOLAT\n11- MEVLÜT ÇİÇEK\n12- ESER KOCAKUŞ\n13- PINAR GUCUYETER\n14- VEDAt NERGiS\n15- OSMAN PEKER\n16- SAİT HORASAN",
    "languageCode": "tr",
    "imagePath": "assets/uploads/products/icons/gself.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(19);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
