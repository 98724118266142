// @ts-nocheck

import { Outlet } from "react-router-dom";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/Footer";

const MainLayout = ({ children }) => {
    return (<>
        <AppHeader />
        {children}
        <AppFooter />
    </>);
}

export default MainLayout;