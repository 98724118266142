// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const Section = styled.section``;
const campaigns = [
  {
    "id": 14,
    "title": "* 7/11/2024 Galatasaray - Tottenham Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – Tottenham UEFA Avrupa Ligi Maç Bileti Talihlileri:\n\nASİL \n1- Mustafa Akbaş\n2- Özgür Güverçin\n3- İbrahim Çelikten\n4- Özgür Koçak\n5- Ramazan Alihan\n6- Ümit Koksal Demirci\n7- Ahmet Can\n8- Veysel Murat Aygün\n9- Mahmud Seçkin\n10- Nadir Beydilli\n\nYEDEK \n1- Ahmet Zafer Tığlıoğlu\n2- İsmail Hakkı Yiğit\n3- Muhammed Talha Polat\n4- Fulya Bozduğan\n5- Emirhan Akça\n6- Ferdi Kop\n7- Abdurrahman Kara\n8- Berat Ayindi\n9- Berkay Acıkgoz\n10- Mutluhan Cörüt\n11- Erkan Doğan\n12- Gökhan Yürekli\n13- Muhammet Bahçeci\n14- Barış Balcıkoca\n15- Sefa Gedik",
    "languageCode": "tr",
    "imagePath": "https://www.ahlpay.com.tr/site_contents/assets/images/Galatasaray-Tottenham.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(14);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
}

export default CampaignsPage;
