// @ts-nocheck
import { useState } from 'react';
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';

const PageJson = {
    pageheader1: [
        {
            title: <h1>Ücretler ve Limitler</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Fees and Limits</h1>,
            languageCode: 'en'
        }
    ],
    headertitle2: [
        {
            title: <h1>Limitler</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1> Limits</h1>,
            languageCode: 'en'
        }
    ],
    headertitle1: [
        {
            title: <h1>Ücretler</h1>,
            languageCode: 'tr'
        },
        {
            title: <h1>Fees</h1>,
            languageCode: 'en'
        }
    ],
    pageheader2: [
        {
            title: <h2>Bireysel Hesap ücretleri ve limitleri bu sayfadan inceleyebilirsiniz.</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>You can review Individual Account prices and limits on this page.</h2>,
            languageCode: 'en'
        }
    ],
    pageheader2: [
        {
            title: <h2>Bireysel Hesap ücretleri ve limitleri bu sayfadan inceleyebilirsiniz.</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2>You can review Individual Account prices and limits on this page.</h2>,
            languageCode: 'en'
        }
    ],
    accounttype: [
        {
            title: <ul>
                <li></li>
                <li>Başlangıç Hesap</li>
                <li>Genç Hesap</li>
                <li>Onaylı Hesap</li>
                <li>Özel Hesap</li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li></li>
                <li>Beginner Account</li>
                <li>Teen Account</li>
                <li>Approved Account</li>
                <li>Exclusive Account</li>
            </ul>,
            languageCode: 'en'
        }
    ],
    ucretsiz: [
        {
            title: <span>Ücretsiz</span>,
            languageCode: 'tr'
        },
        {
            title: <span>Free</span>,
            languageCode: 'en'
        }
    ],
    accounttype1: [
        {
            title: <span>Başlangıç Hesap</span>,
            languageCode: 'tr'
        },
        {
            title: <span>Beginner Account</span>,
            languageCode: 'en'
        }
    ],
    accounttype2: [
        {
            title: <span>Genç Hesap</span>,
            languageCode: 'tr'
        },
        {
            title: <span>Teen Account</span>,
            languageCode: 'en'
        }
    ],
    accounttype3: [
        {
            title: <span>Onaylı Hesap</span>,
            languageCode: 'tr'
        },
        {
            title: <span>Approved Account</span>,
            languageCode: 'en'
        }
    ],
    accounttype4: [
        {
            title: <span>Özel Hesap</span>,
            languageCode: 'tr'
        },
        {
            title: <span>Exclusive Account</span>,
            languageCode: 'en'
        }
    ],
    islemturu1: [
        {
            title: <li>Hesap Açma</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Opening an Account</li>,
            languageCode: 'en'
        }
    ],
    islemturu2: [
        {
            title: <li>Havale/EFT Yoluyla Para Yükleme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Deposit via Transfer/EFT</li>,
            languageCode: 'en'
        }
    ],
    islemturuP: [
        {
            title: <li>Havale/EFT Yoluyla Para Gönerme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Transfer/EFT Sending</li>,
            languageCode: 'en'
        }
    ],
    islemturu3: [
        {
            title: <li>Cüzdandan Cüzdana Para Gönderme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Sending Money from Wallet to Wallet</li>,
            languageCode: 'en'
        }
    ],
    islemturu4: [
        {
            title: <li>IBAN Oluşturma</li>,
            languageCode: 'tr'
        },
        {
            title: <li><li>Creating IBAN</li></li>,
            languageCode: 'en'
        }
    ],
    islemturu5: [
        {
            title: <li>FAST/KOLAS ile Para Gönderme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Sending Money via FAST/KOLAS</li>,
            languageCode: 'en'
        }
    ],
    islemturu6: [
        {
            title: <li>Sanal Kart Oluşturma</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Creating a Virtual Card</li>,
            languageCode: 'en'
        }
    ],
    islemturu7: [
        {
            title: <li>
                Bill Payment
                <small>(No fee is charged for Enerya, Çorumgaz, Sürmeligaz, Marmaragaz, Kargaz payments.)</small>
            </li>,
            languageCode: 'en'
        },
        {
            title: <li>
                Fatura Ödeme
                <small>(Enerya, Çorumgaz, Sürmeligaz, Marmaragaz, Kargaz ödemelerinden ücret alınmaz.)</small>
            </li>,
            languageCode: 'tr'
        }
    ],
    islemturu8: [
        {
            title: <li>Aksa DoğalGaz Fatura Ödeme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Aksa Doğalgaz Bill Payment</li>,
            languageCode: 'en'
        }
    ],
    islemturu9: [
        {
            title: <li>Para İste</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Request Money</li>,
            languageCode: 'en'
        }
    ],
    islemturu10: [
        {
            title: <li>Torosgaz Fatura Ödeme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Torosgaz Bill Payment</li>,
            languageCode: 'en'
        }
    ],
    islemturu11: [
        {
            title: <li>Torosgaz Fatura Ödeme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Torosgaz Bill Payment</li>,
            languageCode: 'en'
        }
    ],
    islemturu12: [
        {
            title: <li>ATM’den Para Çekme</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Withdraw cash from ATM</li>,
            languageCode: 'en'
        }
    ],
    islemturu13: [
        {
            title: <li>ATM’den Para Yatırma</li>,
            languageCode: 'tr'
        },
        {
            title: <li>Deposit cash to ATM</li>,
            languageCode: 'en'
        }
    ],
    islemturu14: [
        {
            title: <li>ATM üzerinden Bakiye Sorgulama
                <small>* AHL Pay uygulaması üzerinden bakiyeni ücretsiz bir şekilde görüntüleyebilirsin.</small>
            </li>,
            languageCode: 'tr'
        },
        {
            title: <li>Balance inquiry on ATM
                <small>* AHL Pay uygulaması üzerinden bakiyeni ücretsiz bir şekilde görüntüleyebilirsin.</small>
            </li>,
            languageCode: 'en'
        }
    ],
    islemturu15: [
        {
            title: <li>AHL Card
                <small>(Fiziki kartınızı aktif ettiğinizde 200 TL nakit hediye bakiye yüklenir.)</small>
            </li>,
            languageCode: 'tr'
        },
        {
            title: <li>AHL Card
                <small>(Fiziki kartınızı aktif ettiğinizde 200 TL nakit hediye bakiye yüklenir.)</small>
            </li>,
            languageCode: 'en'
        }
    ],
    limit1: [
        {
            title: <ul>
                <li></li>
                <li>Başlangıç Hesap<small>Limit / Adet</small></li>
                <li>Genç Hesap<small>Limit / Adet</small></li>
                <li>Onaylı Hesap<small>Limit / Adet</small></li>
                <li>Özel Hesap<small>Limit / Adet</small></li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li></li>
                <li>Beginner Account<small>Limit / Quantity</small></li>
                <li>Teen Account<small>Limit / Quantity</small></li>
                <li>Approved Account<small>Limit / Quantity</small></li>
                <li>Exclusive Account<small>Limit / Quantity</small></li>
            </ul>,
            languageCode: 'en'
        }
    ],
    limitblok: [
        {
            title: <ul>
                <li>Banka Hesabından Para Gönderme</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>185.000 ₺ / 150</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Bank Account Deposit Limit</li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>2.500.000 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    limitblok1: [
        {
            title: <ul>
                <li>Kendi Banka Hesabından Para Yükleme</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>185.000 ₺ / 150</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Own Bank Account Transfer
                </li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>185.000 ₺ / 150</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    limitblok3: [
        {
            title: <ul>
                <li>Para İsteme</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>185.000 ₺ / 150</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Request Funds</li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span>2.750 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>2.500.000 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    limitblok4: [
        {
            title: <ul>
                <li>Günlük FAST ile Para Gönerme</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>100.000 ₺ / 20</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Daily FAST Transfer</li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>2.500.000 ₺ / 100</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>5.000.000 ₺ / 250</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    atmblok: [
        {
            title: <ul>
                <li>ATM üzerinden Bakiye Sorgulama</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>0.24 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>0.24 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Balance inquiry on ATM
                </li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>0.24 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>0.24 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    atmblok2: [
        {
            title: <ul>
                <li>ATM’den Para Yatırma</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Deposit cash to ATM
                </li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    atmblok3: [
        {
            title: <ul>
                <li>ATM’den Para Çekme</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>Withdraw cash from ATM
                </li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
    card: [
        {
            title: <ul>
                <li>AHL Card</li>
                <li>
                    <div>
                        <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Genç Hesap<small>Limit / Adet</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Onaylı Hesap<small>Limit / Adet</small></span>
                        <span>99.90 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Özel Hesap<small>Limit / Adet</small></span>
                        <span>99.90 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul>
                <li>AHL Card
                </li>
                <li>
                    <div>
                        <span>Beginner Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Teen Account<small>Limit / Transaction</small></span>
                        <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Approved Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Exclusive Account<small>Limit / Transaction</small></span>
                        <span>%1+0.91 ₺</span>
                    </div>
                </li>
            </ul>,
            languageCode: 'en'
        }
    ],
}

const FeesAndLimitsPage = () => {

    return <MainLayout children={_render()} />
};

const _render = () => {

    const { language } = useLanguage();


    const [activeTab, setActiveTab] = useState("fees");

    return <main page="inside">

        <section element="pageheader">
            <div>
                <div>
                    {PageJson.pageheader1.find(x => x.languageCode == language)?.title}
                    {PageJson.pageheader2.find(x => x.languageCode == language)?.title}

                </div>
                <div><img src="assets/uploads/pageheader/ucret_ve_limitler.png" alt={PageJson.pageheader1.find(x => x.languageCode == language)?.title} /></div>
            </div>
        </section>
        <section element="feesAndLimits">
            <div>
                <ul tabs="">
                    <li tab-id="fees" active={activeTab == "fees" ? 'true' : undefined} onClick={() => setActiveTab("fees")}>{PageJson.headertitle1.find(x => x.languageCode == language)?.title}</li>
                    <li tab-id="limits" active={activeTab == "limits" ? 'true' : undefined} onClick={() => setActiveTab("limits")} >{PageJson.headertitle2.find(x => x.languageCode == language)?.title}</li>
                </ul>
                <content tabdetails="">

                    <article tab-detail="fees" status={activeTab == "fees" ? 'on' : 'off'}>

                        <div table="fees">
                            <div table-header="">
                                {PageJson.accounttype.find(x => x.languageCode == language)?.title}
                            </div>
                            <div table-body="">
                                <ul>
                                    {PageJson.islemturu1.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu12.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>%1+0.91 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>%1+0.91 ₺</span>
                                        </div>
                                    </li>
                                </ul>


                                <ul>
                                    {PageJson.islemturu13.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>%1+0.91 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>%1+0.91 ₺</span>
                                        </div>
                                    </li>
                                </ul>


                                <ul>
                                    {PageJson.islemturu14.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>0.24 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>0.24 ₺</span>
                                        </div>
                                    </li>
                                </ul>


                                <ul>
                                    {PageJson.islemturu15.find(x => x.languageCode == language)?.title}

                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>99.90 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>99.90 ₺</span>
                                        </div>
                                    </li>
                                </ul>
















                                <ul>
                                    {PageJson.islemturu2.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturuP.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span>2 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>2 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>2 ₺</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu3.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu4.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu5.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span><i icon="mat-outlined xs" icon-type="not">close</i></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>2 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>2 ₺</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu9.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu6.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            {PageJson.ucretsiz.find(x => x.languageCode == language)?.title}
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu7.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span>0.50 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span>0.50 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>0.50 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>0.50 ₺</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu8.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span>5.25 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span>5.25 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>5.25 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>5.25 ₺</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul>
                                    {PageJson.islemturu11.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            {PageJson.accounttype1.find(x => x.languageCode == language)?.title}
                                            <span>0.85 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype2.find(x => x.languageCode == language)?.title}
                                            <span>0.85 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype3.find(x => x.languageCode == language)?.title}
                                            <span>0.85 ₺</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            {PageJson.accounttype4.find(x => x.languageCode == language)?.title}
                                            <span>0.85 ₺</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>



                    </article>

                    <article tab-detail="limits" status={activeTab == "limits" ? 'on' : 'off'}>

                        <div table="limits">
                            <div table-header="">
                                {PageJson.accounttype.find(x => x.languageCode == language)?.title}
                            </div>
                            <div table-body="">
                                {PageJson.limitblok.find(x => x.languageCode == language)?.title}
                                {PageJson.limitblok1.find(x => x.languageCode == language)?.title}

                                <ul>
                                    {PageJson.islemturu7.find(x => x.languageCode == language)?.title}
                                    <li>
                                        <div>
                                            <span>Başlangıç Hesap<small>Limit / Adet</small></span>
                                            <span>2.750 ₺ / 20</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Genç Hesap<small>Limit / Adet</small></span>
                                            <span>2.750 ₺ / 20</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Onaylı Hesap<small>Limit / Adet</small></span>
                                            <span>20.000 ₺ / 30</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Özel Hesap<small>Limit / Adet</small></span>
                                            <span>50.000 ₺ / 100</span>
                                        </div>
                                    </li>
                                </ul>
                                {PageJson.limitblok3.find(x => x.languageCode == language)?.title}
                                {PageJson.limitblok4.find(x => x.languageCode == language)?.title}


                            </div>
                        </div>


                    </article>

                </content>

            </div>
        </section>

    </main>;
}

export default FeesAndLimitsPage;