// @ts-nocheck
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';

const ProductPage = ({ productData }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={imagePath} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        <h5>Finansal özgürlüğünü şimdi keşfet</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
}

const App = () => {
  const productData = {
        id: 4,
        pageTitle: "AHL Pay'den bir ilk: ATOM Hesapta",
        headerTitle: 'ATOM',
        title: "AHL Pay'den bir ilk: ATOM Hesapta",
        shortDescription: 'ATOM Hesapta ile anlaşmalı kuyumcumlara teslim ettiğin altının, kuyumcuya teslim edildiği anda ilgili finans kurumundaki altın hesabına yatar.',
        description: "Altınını fiziki saklamak yerine AHL Pay uygulamasındaki altın hesabında tutarak finansal piyasaların avantajlarından yararlanabilirsin. ATOM Hesapta uygulaması milyem avantajları içerir, birikiminden kayıp yaşamanı engeller. Sadece gram altın ve cumhuriyet altını gibi standart altın türleri değil, bilezik, yüzük, küpe, kolye gibi tüm kuyum ürünlerini anlaşmalı kuyumculara getir, ATOM Hesapta ile AHL Pay uygulamandaki altın hesabına sadece birkaç saniye içerisinde aktarılsın. Ayrıca birlikte çalıştığımız kuyumcularımızı <a href='assets/images/KuyumcuListemiz.pdf' style='color: blue;' download>görmek için tıkla</a>.",
        imagePath: 'assets/uploads/products/phones/ATOM.png',
        title2: <> <span>ATOM </span>Hesapta</>,
        languageCode: 'tr',
        childrens: [
            {
                title: 'Anında Transfer',
                desc: "Tüm kuyum ürünleri saniyeler içinde altın hesabında"
            },
            {
                title: 'Avantajlı Kur',
                desc: 'Altın-TL kuru ile Türk lirasına çevirme imkanı'
            },
            {
                title: 'Güvenli İşlem',
                desc: 'İşlemleriniz Ahlatcı Metal Rafineri Güvencesi ile koruma altında'
            }
        ]
    };

  return (
    <div>
      <AppHeader />
      <br /><br /><br /><br /><br /><br />
      {/* You can include the ProductPage component below */}
      <ProductPage productData={productData} />
      <Footer />
    </div>
  );
};

export default App;
