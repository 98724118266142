// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import styles from './HomeProducts.module.css';
import { title } from 'process';

const pageJSON = [
    {
        id: 6,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'AHL Card',
        childrens: [
            "Her şey kontrolün altında",
            "Dilediğin gibi harca, bütçeni aşma",
            "Harcadıkça Cashback kazan"
        ],
        image: 'assets/uploads/products/icons/cardanasayfa.svg'
    },
    {
        id: 1,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'IBAN',
        childrens: [
            "Kolayca IBAN oluştur",
            "Kopyala, yapıştır, gönder",
            "KOLAS ile para gönder"
        ],
        image: 'assets/uploads/products/phones/iban_phone.svg'
    },
    {
        id: 3,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'Fiziki Altın',
        childrens: [
            "Adrese teslim",
            "Avantajlı fiyat",
            "Yatırımda güvenli liman"
        ],
        image: 'assets/uploads/products/phones/fiziki_phone.svg'
    },
    {
        id: 2,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'FAST',
        childrens: [
            "Anında hesabında",
            "7/24 Para transferi",
            "Kolay ve hızlı"
        ],
        image: 'assets/uploads/products/icons/fasthd.svg'
    },
    {
        id: 4,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'Kıymetli Maden',
        childrens: [
            "Hızlı ve güvenli",
            "Hemen yatırıma başla",
            "7/24 güncel kurlarla işlem yap"
        ],
        image: 'assets/uploads/products/icons/kiymetli_madenler.svg'
    },
    {
        id: 5,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'ATOM',
        childrens: [
            "Anında hesabında",
            "Tüm kuyum ürünleri",
            "Altın-TL kuru ile TL’ye çevirme"
        ],
        image: 'assets/uploads/products/icons/atom.svg'
    },
    {
        id: 8,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'BenimFaturam',
        childrens: [
            "%1 Nakit iade",
            "12 Ay taksit",
            "Güvenli ödeme"
        ],
        image: 'assets/uploads/products/icons/benimfaturam.svg'
    },
    {
        id: 7,
        languageCode: 'tr',
        groupCode: 'INDIVIDUAL',
        title: 'Yatırım',
        childrens: [
            "Akıllı Yatırım, güvenli getiri",
            "Çeşitli ve esnek yatırım araçları",
            "Kullanıcı dostu arayüz, işlem kolaylığı"
        ],
        image: 'assets/uploads/products/icons/yatirim.svg'
    },
    //
    //
    //
    {
        id: 8,
        languageCode: 'tr',
        groupCode: 'BUSINESS',
        title: 'AndroidPOS',
        childrens: [
            "Kolay ve hızlı işlemler",
            "E-Fatura ve E-Arşiv fatura",
            "Avantajlı komisyon oranları"
        ],
        image: 'assets/uploads/products/products/kurumsal_androidpos.png'
    },
    {
        id: 7,
        languageCode: 'tr',
        groupCode: 'BUSINESS',
        title: 'AltınPOS',
        childrens: [
            "Kuyumculara özel",
            "Avantajlı TL-Altın kuru",
            "Fiziki teslimat"
        ],
        image: 'assets/uploads/products/products/kurumsal_altinpos.png'
    },
    {
        id: 11,
        languageCode: 'tr',
        groupCode: 'BUSINESS',
        title: 'Yazar Kasa POS',
        childrens: [
            "Mevzuat uygunluğu ve yasal uyumluluk",
            "Güvenli ödeme",
            "Hızlı kurulum, kolay kullanım"
        ],
        image: 'assets/uploads/products/products/kurumsal_yazarkasapos.png'
    },
    {
        id: 9,
        languageCode: 'tr',
        groupCode: 'BUSINESS',
        title: 'CepPOS',
        childrens: [
            "Taşınabilir ve temassız",
            "Lokasyon bağımsız",
            "Anında ödeme alma"
        ],
        image: 'assets/uploads/products/products/kurumsal_ceppos.png'
    },
    {
        id: 10,
        languageCode: 'tr',
        groupCode: 'BUSINESS',
        title: 'SanalPOS',
        childrens: [
            "Hızlı ve pratik",
            "Tüm işlemler tek portalda",
            "Avantajlı komisyon oranları"
        ],
        image: 'assets/uploads/products/products/sanalpos_odeme_pc.png'
    }
];

export const HomeProducts = () => {

    const [activeProductType, setActiveProductType] = useState("INDIVIDUAL");
    const [activeItem, setActiveItem] = useState(pageJSON.filter(x => x.groupCode == activeProductType)[0]);

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    return <>
        <section element="homeproducts">
            <div>
                <h1 className={styles.centerTitle}>Farklı Finansal Çözümler Tek Uygulamada</h1>
            </div>
            <div>
                <ul tabs="">
                    <li tab-id="individual" active={activeProductType == "INDIVIDUAL" ? 'true' : undefined} onClick={() => {
                        setActiveProductType("INDIVIDUAL");
                        setActiveItem(pageJSON.filter(x => x.groupCode == "INDIVIDUAL")[0]);
                    }}>Bireysel</li>
                    <li tab-id="corporate" active={activeProductType == "BUSINESS" ? 'true' : undefined} onClick={() => {
                        setActiveProductType("BUSINESS");
                        setActiveItem(pageJSON.filter(x => x.groupCode == "BUSINESS")[0]);
                    }}>Kurumsal</li>
                </ul>

                <div className={styles.container}>
                    <div className={styles.leftSection}>
                        <ul>
                            {pageJSON.filter(x => x.groupCode == activeProductType).map((item, index) => {
                                return <li
                                    className={activeItem.id === item.id ? styles.active : ''}
                                    onClick={() => handleItemClick(item)}
                                    onMouseEnter={() => handleItemClick(item)}>
                                    {item.title}
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className={styles.middleSection}>
                        <div className={styles.phoneMockup}>
                            <img src={activeItem.image} alt="Phone mockup" />
                        </div>
                    </div>

                    <div className={styles.rightSection}>
                        <ul>
                            {activeItem.childrens.map((item) => {
                                return <li>{item}</li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </>;
}