// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import AppHeader from '../../components/AppHeader';

const pageJSON = {
    headerTitle: [
        {
            title: 'Başvuru formu',
            languageCode: 'tr'
        },
        {
            title: 'Application form',
            languageCode: 'en'
        }
    ],
    shortDescription: [
        {
            title: <h5>Başvuru için <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> adresinden özgeçmişinizi iletebilirsiniz.</h5>,
            languageCode: 'tr'
        },
        {
            title: <h5>You can submit your resume to <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> for the application.</h5>,
            languageCode: 'en'
        }
    ],
    firstAndLastName: [
        {
            title: 'Adınız soyadınız',
            languageCode: 'tr'
        },
        {
            title: 'Full Name',
            languageCode: 'en'
        }
    ],
    phoneNumber: [
        {
            title: 'Telefon Numaranız',
            languageCode: 'tr'
        },
        {
            title: 'Phone Number',
            languageCode: 'en'
        }
    ],
    email: [
        {
            title: 'E-posta adresiniz',
            languageCode: 'tr'
        },
        {
            title: 'Email',
            languageCode: 'en'
        }
    ],
    note: [
        {
            title: 'Başvuru Notu',
            languageCode: 'tr'
        },
        {
            title: 'Application Note',
            languageCode: 'en'
        }
    ],
    dropCV: [
        {
            title: "CV'inizi sürükleyebilir  veya seçebilirsiniz.",
            languageCode: 'tr'
        },
        {
            title: 'Drop your CV here',
            languageCode: 'en'
        }
    ],
    send: [
        {
            title: "Gönder",
            languageCode: 'tr'
        },
        {
            title: 'Send',
            languageCode: 'en'
        }
    ],
    selectedFile: [
        {
            title: "Seçilen dosya",
            languageCode: 'tr'
        },
        {
            title: 'Selected file',
            languageCode: 'en'
        }
    ],
    success: [
        {
            title: "CV'niz başvurunuzla birlikte bizlere ulaştı. Başvurunuzun uygunluk kriterlerine uygun olduğu durumda sizinle iletişime geçeceğiz.",
            languageCode: 'tr'
        },
        {
            title: 'Your resume has been received along with your application. If your application meets the eligibility criteria, we will get in touch with you.',
            languageCode: 'en'
        }
    ]
};

const ApplyPage = ({ openModal, setOpenModal, selectedProductId }) => {
    const { language } = useLanguage();
    
    const [isWorking, setIsWorking] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [formData, setFormData] = useState({
        productType: '0',
        fromEmail: '',
        fromPhoneNumber: '',
        fromFullName: '',
        fromCountyDistrict: '',
        businessType: '',
        businessTitle: '',
        businessWebAddress: '',
        businessEstimatedTurnoverPerMonth: '0',
    });
    useEffect(() => {
        if (selectedProductId) {
            setFormData((prevData) => ({
                ...prevData,
                productType: selectedProductId
            }));
        }
    }, [selectedProductId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsWorking(true);

        const requestData = {
            ...formData,
            ip: "78.135.100.149"  
        };

        try {
            const baseUrl = process.env.REACT_APP_API_ADDRESS;
            const response = await fetch(baseUrl + '/Communication/SendBusinessEmail', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(requestData),
            });

            setIsWorking(false);
            if (response.ok) {
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSuccess(false);
            setIsWorking(false);
        }
    }; 
    return (
        <div modal="product" style={{
            position:"initial !important",
            width: "100% !important",
            height: "100%",
            maxWidth: "100% !important"

        }}
            status={`${!openModal ? "hide" : "show"}`}
        >       
            <div modal-content=""
            style={{
                position:"initial !important",
                width: "100% !important",
                height: "100%",
                maxWidth: "100% !important" ,
                margin:"auto"
            }}
            >
                 <div style={{
                top: '10px',
                left:'100px',
            }}>
                
                <img 
                    src="assets/images/logo1.png"
                    alt="Logo"
                    style={{ width :'180px', height: '95px'}}
                />
            </div>
            <br />

                {/* <span modal-close="" onClick={() => setOpenModal(false)}><i icon="mat-symbol xl">close</i></span> */}
                <h4>{pageJSON.headerTitle.find(item => item.languageCode === language)?.title}</h4> 
                {isSuccess !== null && (
                    <div modal-result="">
                        <div result={isSuccess ? "success" : "fail"}>
                            <i icon="mat-round md">{isSuccess ? "check_circle_outline" : "info_outline"}</i>
                            <span>{isSuccess ? "Mesajınız gönderilmiştir." : "Mesajınız gönderilemedi, tekrar deneyiniz."}</span>
                        </div>
                    </div>
                )}
                <form
                    form="product" method="post" onSubmit={handleSubmit}>
                    <label form-item="mt-2">
                        <span>Başvurulan Ürün</span>
                        <select
                            name="productType"
                            value={formData.productType}
                            onChange={handleChange}
                        >
                            <option value="0">Seçiniz...</option>
                            <option value="AltinPOS">AltınPOS</option>
                            <option value="AndroidPOS">AndroidPOS</option>
                            <option value="CepPOS">CepPOS</option>
                            <option value="SanalPOS">SanalPOS</option>
                            <option value="KurumsalHesap">Kurumsal Hesap</option>
                            <option value="KurumsalKart">Kurumsal Kart</option>
                            <option value="OKCPOS">Yeni Nesil ÖKC Yazar Kasa POS</option>
                        </select>
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.firstAndLastName.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="fromFullName" value={formData.fromFullName} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.phoneNumber.find(item => item.languageCode === language)?.title}</span>
                        <input type="tel" name="fromPhoneNumber" value={formData.fromPhoneNumber} onChange={handleChange} placeholder="0 (500) 000 00 00" />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.email.find(item => item.languageCode === language)?.title}</span>
                        <input type="email" name="fromEmail" value={formData.fromEmail} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>İl/İlçe</span>
                        <input type="text" name="fromCountyDistrict" value={formData.fromCountyDistrict} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>İşletme türü</span>
                        <input type="text" name="businessType" value={formData.businessType} onChange={handleChange} />
                    </label>
                    <label form-item="mt-2">
                        <span>İşletme web sitesi</span>
                        <input type="text" name="businessWebAddress" value={formData.businessWebAddress} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>İşletme ünvanı</span>
                        <input type="text" name="businessTitle" value={formData.businessTitle} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>Aylık tahmini ciro</span>
                        <select name="businessEstimatedTurnoverPerMonth" value={formData.businessEstimatedTurnoverPerMonth} onChange={handleChange}>
                            <option value="0">Aylık Tahmini Ciro</option>
                            <option value="100.000 TL 'nin altı">100.000 TL 'nin altı</option>
                            <option value="100.000 - 250.000 TL">100.000 - 250.000 TL</option>
                            <option value="250.000 - 500.000 TL">250.000 - 500.000 TL</option>
                            <option value="500.000 - 1.000.000 TL">500.000 - 1.000.000 TL</option>
                            <option value="1.000.000 TL 'nin üzeri">1.000.000 TL 'nin üzeri</option>
                        </select>
                    </label>    
                    <label form-item="checkbox mt-2">
                        <input type="checkbox" name="privacyPolicy" />
                        <var>AHL Pay'in Kişisel verilerin İşlenmesi metnini okudum ve kabul ediyorum</var>
                    </label>
                    <label form-item="checkbox mt-2">
                        <input type="checkbox" name="marketingConsent" />
                        <var>AHL Pay'in kampanyalarını tanıtım, hatırlatma, bilgilendirme amaçlı ve/veya hizmet iyileştirmesi amacıyla SMS, e-posta ya da telefon aracılığıyla benimle iletişime geçmesine izin veriyorum</var>
                    </label>

                    <label form-item="recaptcha mt-3">
                        <span className="g-recaptcha" data-sitekey="6LfD3PIbAAAAAJs_eEHvoOl75_83eXSqpPSRFJ_u"></span>
                    </label>

                    <label form-button="mt-2">
                        <button type="submit" disabled={isWorking}>
                            {isWorking ? 'Gönderiliyor...' : pageJSON.send.find(item => item.languageCode === language)?.title}
                        </button>
                    </label>
                </form>
                <br />
               {/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <a
        href="https://www.ahlpay.com.tr" 
        style={{
            padding: '15px 282px',
            backgroundColor: '#4CAF50',
            color: '#fff',
            textDecoration: 'none',
            borderRadius: '8px',
            fontWeight: 'bold',
            display: 'inline-block'
        }}
    >
        Anasayfa  
    </a>
</div> */}
            </div>
            <div modal-backdrop="" style={{ background: 'rgba(var(0 0 0) / 0%)' }}></div>
        </div>
    );
};

export default ApplyPage;